.lite-vertical-align {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.lite-horizontal-align {
	display: flex;
	align-items: center;
	justify-content: center;

	&-right {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

.lite-js-only {
	display: none;
}

.js-enabled {
	.lite-js-only {
		display: inline-block;
	}
}

.lite-horizontal-separator {
	@include govuk-responsive-margin(6, "bottom");
	display: block;
	height: 2px;
	background: govuk-colour("light-grey");
	border: none;
}

.lite-buttons-row {
	margin-bottom: govuk-spacing(6);

	& > * {
		margin-left: govuk-spacing(3);
		margin-bottom: 0;

		&:first-child {
			margin-left: 0!important;
		}
	}
	@include govuk-media-query($until: tablet) {
		width: 100%;
		margin-bottom: govuk-spacing(3);

		* {
			margin-left: 0!important;
			margin-bottom: govuk-spacing(3);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.lite-mobile-only {
	@include govuk-media-query($until: tablet) {
		display: block;
	}
	display: none;
}

@include govuk-media-query($until: tablet) {
	.lite-mobile-hide {
		display: none!important;
	}
}

@media (max-width: 1100px) {
	.lite-tablet-hide {
		display: none;
	}
}

.lite-\!-text-decoration-none {
	text-decoration: none;
}

.lite-\!-sticky {
	position: sticky;
	top: govuk-spacing(6);
}

.lite-\!-text-align-right {
	text-align: right;
}

.lite-\!-position-relative {
	position: relative;
}

.lite-\!-display-inline {
	display: inline;
}

.lite-\!-no-border {
	border-bottom: 2px solid govuk-colour("white")!important;
}

@for $i from 1 through 9 {
	.lite-\!-margin-top--#{$i} {
		margin-top: - govuk-spacing($i);
	}
}

.lite-\!-word-break {
	word-break: break-all;
}
