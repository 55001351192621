.lite-tabs__container {
	position: relative;
	display: grid;
	grid-template-columns: 1fr auto;
	margin-bottom: govuk-spacing(6);

	@include govuk-media-query($until: tablet) {
		grid-template-columns: 1fr!important;
		margin-bottom: govuk-spacing(2);
	}
}

.lite-tabs__controls {
	position: relative;
	z-index: 1;

	@include govuk-media-query($until: tablet) {
		margin-left: 0!important;
	}

	@include govuk-media-query($from: tablet) {
		& > * {
			margin: 0;
			margin-left: govuk-spacing(3);
		}
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: - 20px;
		bottom: 0;
		width: 20px;
		background: linear-gradient(90deg, rgba($govuk-body-background-colour, 0%), $govuk-body-background-colour);
		pointer-events: none;
		z-index: 1;
	}
}

.lite-tabs {
	display: flex;
	flex-wrap: wrap;
	height: fit-content;
	gap: govuk-spacing(5);

	@include govuk-media-query($until: tablet) {
		flex-direction: column;
		gap: govuk-spacing(2);
	}
}

.lite-tabs__tab {
	@include govuk-font($size: 16, $weight: bold);
	display: inline-block;
	padding: govuk-spacing(3) 0;
	border-bottom: 2px solid transparent;
	color: $govuk-secondary-text-colour;
	cursor: pointer;
	white-space: nowrap;
	text-decoration: none!important;

	@include govuk-media-query($until: tablet) {
		padding: govuk-spacing(1) 0;
	}

	&:hover {
		border-bottom: 2px solid $govuk-secondary-text-colour;
	}

	&:focus {
		@include govuk-focused-text;
		border-bottom-color: currentColor;
	}

	.lite-notification-bubble {
		@include govuk-font($size: 14, $weight: bold);
		margin-left: govuk-spacing(1) * 0.5;
		width: 18px!important;
		line-height: 18px!important;
		border-bottom-width: 0;
	}

	&-notification {
		@include govuk-font($size: 16, $weight: bold);
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-left: govuk-spacing(1);
		color: govuk-colour("white");
		text-decoration: underline!important;
		text-decoration-color: transparent!important;
		z-index: 0;
		min-width: 10px;
		padding: 0 govuk-spacing(1);

		&::after {
			content: "";
			position: absolute;
			top: -2px;
			bottom: -2px;
			left: 0;
			right: 0;
			z-index: -1;
			background-color: govuk-colour("red");
			border-radius: 10px;
		}
	}
}

.lite-tabs__tab--selected {
	border-bottom: 2px solid $govuk-link-colour!important;
	color: $govuk-text-colour;
	cursor: default!important;
}
