@include govuk-exports("govuk/component/tag") {
  .govuk-tag {

    display: inline-block;

    // When a user customises their colours often the background is removed,
    // by adding a outline we ensure that the tag component still keeps it's meaning.
    // https://accessibility.blog.gov.uk/2017/03/27/how-users-change-colours-on-websites/
    outline: 2px solid transparent;
    outline-offset: -2px;

    color: govuk-colour("white");
    background-color: govuk-colour("blue");
    letter-spacing: 1px;

    text-decoration: none;
    text-transform: uppercase;

    @if $govuk-use-legacy-font {
      // Since New Transport sits slightly higher than other common fonts.
      // We use intentionally uneven padding to make it balanced, this can be
      // removed using the version of the font that has a more common vertical spacing.
      @include govuk-font($size: 16, $weight: bold, $line-height: 1.25);
      padding-top: 4px;
      padding-right: 8px;
      padding-bottom: 1px;
      padding-left: 8px;
    } @else {
      @include govuk-font($size: 16, $weight: bold, $line-height: 1);
      padding-top: 5px;
      padding-right: 8px;
      padding-bottom: 4px;
      padding-left: 8px;
    }
  }

  .govuk-tag--grey {
    color: govuk-shade(govuk-colour("dark-grey", $legacy: "grey-1"), 30%);
    background: govuk-tint(govuk-colour("dark-grey", $legacy: "grey-1"), 90%);
  }

  .govuk-tag--purple {
    color: govuk-shade(govuk-colour("purple"), 20%);
    background: govuk-tint(govuk-colour("purple"), 80%);
  }

  .govuk-tag--turquoise {
    color: govuk-shade(govuk-colour("turquoise"), 60%);
    background: govuk-tint(govuk-colour("turquoise"), 70%);
  }

  .govuk-tag--blue {
    color: govuk-shade(govuk-colour("blue"), 30%);
    background: govuk-tint(govuk-colour("blue"), 80%);
  }

  .govuk-tag--yellow {
    color: govuk-shade(govuk-colour("yellow"), 65%);
    background: govuk-tint(govuk-colour("yellow"), 75%);
  }

  .govuk-tag--orange {
    color: govuk-shade(govuk-colour("orange"), 55%);
    background: govuk-tint(govuk-colour("orange"), 70%);
  }

  .govuk-tag--red {
    color: govuk-shade(govuk-colour("red"), 30%);
    background: govuk-tint(govuk-colour("red"), 80%);
  }

  .govuk-tag--pink {
    color: govuk-shade(govuk-colour("pink"), 40%);
    background: govuk-tint(govuk-colour("pink"), 80%);
  }

  .govuk-tag--green {
    color: govuk-shade(govuk-colour("green"), 20%);
    background: govuk-tint(govuk-colour("green"), 80%);
  }
}

/*# sourceMappingURL=_index.scss.map */