.selected-options {
    @include govuk-font($size: 19);
    padding: govuk-spacing(2) 0;

    &--empty {
        padding: 0;
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
        gap: govuk-spacing(2);
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__option {
        align-items: center;
        background-color: govuk-colour("light-grey");
        border-bottom: 2px solid govuk-colour("mid-grey");
        display: flex;
        padding: 0;
    }

    &__option-text {
        margin: govuk-spacing(1) govuk-spacing(2);
        margin-right: govuk-spacing(1);
        white-space: nowrap;
    }

    &__option-remove {
        background-color: inherit;
        border: 0;
        cursor: pointer;
        height: 35px;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 25px;

        &:focus {
            background-color: govuk-colour("yellow");
            box-shadow: 0 2px govuk-colour("black");
            outline: 3px solid rgba(0, 0, 0, 0);
        }

        &:hover {
            background-color: govuk-colour("mid-grey");
            box-shadow: 0 2px govuk-colour("black");
            outline: 3px solid rgba(0, 0, 0, 0);
        }
    }

    &__option-remove-icon {
        font-size: 18px;
        margin-bottom: 50px;
        position: relative;
        top: 8px;
    }
}
