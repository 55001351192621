// stylelint-disable declaration-no-important
@include govuk-exports("govuk/overrides/width") {
  .govuk-\!-width-full {
    width: 100% !important;
  }

  .govuk-\!-width-three-quarters {
    width: 100% !important;

    @include govuk-media-query($from: tablet) {
      width: 75% !important;
    }
  }

  .govuk-\!-width-two-thirds {
    width: 100% !important;

    @include govuk-media-query($from: tablet) {
      width: 66.66% !important;
    }
  }

  .govuk-\!-width-one-half {
    width: 100% !important;

    @include govuk-media-query($from: tablet) {
      width: 50% !important;
    }
  }

  .govuk-\!-width-one-third {
    width: 100% !important;

    @include govuk-media-query($from: tablet) {
      width: 33.33% !important;
    }
  }

  .govuk-\!-width-one-quarter {
    width: 100% !important;

    @include govuk-media-query($from: tablet) {
      width: 25% !important;
    }
  }
}

/*# sourceMappingURL=_width.scss.map */