.lite-button--link {
	@extend .govuk-link;
	@include govuk-font($size: 19);
	border: 0;
	outline: 0;
	background: 0;
	color: $govuk-link-colour;
	cursor: pointer;
	text-decoration: underline;
	padding: 0;
	margin: 0;
}

.lite-link--definition {
	@extend .govuk-link;
	@extend .govuk-link--no-visited-state;
	@include govuk-font($size: 19);
	margin: 0;
	padding: 0;
	border-bottom: 1px dashed currentColor;
	text-decoration: none;
}

.govuk-link--disabled {
	color: govuk-tint($govuk-link-colour, 50%)!important;
	pointer-events: none;
	text-decoration: none;
}
