$lite-transition-speed: 0s !default;
$govuk-page-width: 1200px !default;

@function get_govuk_image_asset_url($filename) {
  @return url("../../../node_modules/govuk-frontend/govuk/assets/images/" + $filename);
}

@function get_govuk_font_asset_url($filename) {
  @return url("../../../node_modules/govuk-frontend/govuk/assets/fonts/" + $filename);
}

$govuk-image-url-function: 'get_govuk_image_asset_url';
$govuk-font-url-function: 'get_govuk_font_asset_url';

@import "node_modules/govuk-frontend/govuk/all"; // GOV.UK Design System
@import "node_modules/accessible-autocomplete/src/autocomplete";
@import "core/assets/styles/all"; // LITE Design System
@import "components/all"; // Local assets
@import "overrides/all";

.hidden .js-enabled {
	display: none;
}

.govuk-summary-list__key--highlight {
	border-left: govuk-spacing(1) solid govuk-colour("orange");
	padding-left: govuk-spacing(3);
}

.lite-warning-summary {
	@include govuk-responsive-margin(9, "bottom");
	border: govuk-spacing(1) solid govuk-colour("orange");
	padding: govuk-spacing(4);
}

.lite-error-summary__list {
	margin: 0;

	li:last-of-type {
		margin: 0;
	}
}

.lite-error-summary__list a {
	color: govuk-colour("orange");
	font-weight: bold;
}

.app-check-your-answers-separator {
	display: block;
	height: 1px;
	background: transparent;
	margin: govuk-spacing(8) 0;
	border: none;
}

.app-icon-label {
	display: inline-block;
	p {
		margin: 0;
		padding: 0;
	}
}

.app-verified__symbol {
	display: inline-block;
	margin-top: 1px;
	margin-right: govuk-spacing(1);

	svg {
		width: 16px;
		height: 16px;
	}

	* {
		color: inherit;
		fill: currentColor;
	}
}

.app-info-bar--verified {
	svg {
		position: absolute;
		top: govuk-spacing(4);
		left: govuk-spacing(4);
		width: 30px;
		height: 30px;

		* {
			fill: govuk-colour("white");
		}
	}

	&::before {
		display: none;
	}
}

.strike {
	text-decoration: line-through;
}
