﻿:root {
  --govuk-frontend-version: "4.8.0";
}

.govuk-link, .app-tile .app-tile__link, .app-tile .app-tile__heading, .lite-pagination__link, .lite-pagination__navigation-link, .lite-modal-back-link, .lite-expand__base-controls .lite-expand__base-control, .lite-link--definition, .lite-button--link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  text-decoration: underline;
}

@font-face {
  font-family: GDS Transport;
  font-style: normal;
  font-weight: normal;
  src: url("light-94a07e06a1-v2.1f1f734e.woff2") format("woff2"), url("light-f591b13f7d-v2.7ed54498.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: GDS Transport;
  font-style: normal;
  font-weight: bold;
  src: url("bold-b542beb274-v2.33232e5d.woff2") format("woff2"), url("bold-affa96571d-v2.16363b71.woff") format("woff");
  font-display: fallback;
}

@media print {
  .govuk-link, .app-tile .app-tile__link, .app-tile .app-tile__heading, .lite-pagination__link, .lite-pagination__navigation-link, .lite-modal-back-link, .lite-expand__base-controls .lite-expand__base-control, .lite-link--definition, .lite-button--link {
    font-family: sans-serif;
  }
}

.govuk-link:focus, .app-tile .app-tile__link:focus, .app-tile .app-tile__heading:focus, .lite-pagination__link:focus, .lite-pagination__navigation-link:focus, .lite-modal-back-link:focus, .lite-expand__base-controls .lite-expand__base-control:focus, .lite-link--definition:focus, .lite-button--link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-link:link, .app-tile .app-tile__link:link, .app-tile .app-tile__heading:link, .lite-pagination__link:link, .lite-pagination__navigation-link:link, .lite-modal-back-link:link, .lite-expand__base-controls .lite-expand__base-control:link, .lite-link--definition:link, .lite-button--link:link {
  color: #1d70b8;
}

.govuk-link:visited, .app-tile .app-tile__link:visited, .app-tile .app-tile__heading:visited, .lite-pagination__link:visited, .lite-pagination__navigation-link:visited, .lite-modal-back-link:visited, .lite-expand__base-controls .lite-expand__base-control:visited, .lite-link--definition:visited, .lite-button--link:visited {
  color: #4c2c92;
}

.govuk-link:hover, .app-tile .app-tile__link:hover, .app-tile .app-tile__heading:hover, .lite-pagination__link:hover, .lite-pagination__navigation-link:hover, .lite-modal-back-link:hover, .lite-expand__base-controls .lite-expand__base-control:hover, .lite-link--definition:hover, .lite-button--link:hover {
  color: #003078;
}

.govuk-link:active, .app-tile .app-tile__link:active, .app-tile .app-tile__heading:active, .lite-pagination__link:active, .lite-pagination__navigation-link:active, .lite-modal-back-link:active, .lite-expand__base-controls .lite-expand__base-control:active, .lite-link--definition:active, .lite-button--link:active, .govuk-link:focus, .app-tile .app-tile__link:focus, .app-tile .app-tile__heading:focus, .lite-pagination__link:focus, .lite-pagination__navigation-link:focus, .lite-modal-back-link:focus, .lite-expand__base-controls .lite-expand__base-control:focus, .lite-link--definition:focus, .lite-button--link:focus {
  color: #0b0c0c;
}

@media print {
  [href^="/"].govuk-link:after, .app-tile [href^="/"].app-tile__link:after, .app-tile [href^="/"].app-tile__heading:after, [href^="/"].lite-pagination__link:after, [href^="/"].lite-pagination__navigation-link:after, [href^="/"].lite-modal-back-link:after, .lite-expand__base-controls [href^="/"].lite-expand__base-control:after, [href^="/"].lite-link--definition:after, [href^="/"].lite-button--link:after, [href^="http://"].govuk-link:after, .app-tile [href^="http://"].app-tile__link:after, .app-tile [href^="http://"].app-tile__heading:after, [href^="http://"].lite-pagination__link:after, [href^="http://"].lite-pagination__navigation-link:after, [href^="http://"].lite-modal-back-link:after, .lite-expand__base-controls [href^="http://"].lite-expand__base-control:after, [href^="http://"].lite-link--definition:after, [href^="http://"].lite-button--link:after, [href^="https://"].govuk-link:after, .app-tile [href^="https://"].app-tile__link:after, .app-tile [href^="https://"].app-tile__heading:after, [href^="https://"].lite-pagination__link:after, [href^="https://"].lite-pagination__navigation-link:after, [href^="https://"].lite-modal-back-link:after, .lite-expand__base-controls [href^="https://"].lite-expand__base-control:after, [href^="https://"].lite-link--definition:after, [href^="https://"].lite-button--link:after {
    content: " (" attr(href) ")";
    word-wrap: break-word;
    font-size: 90%;
  }
}

.govuk-link--muted:link, .govuk-link--muted:visited {
  color: #505a5f;
}

.govuk-link--muted:hover, .govuk-link--muted:active, .govuk-link--muted:focus, .govuk-link--text-colour:link, .govuk-link--text-colour:visited {
  color: #0b0c0c;
}

@media print {
  .govuk-link--text-colour:link, .govuk-link--text-colour:visited {
    color: #000;
  }
}

.govuk-link--text-colour:hover {
  color: rgba(11, 12, 12, .99);
}

.govuk-link--text-colour:active, .govuk-link--text-colour:focus {
  color: #0b0c0c;
}

@media print {
  .govuk-link--text-colour:active, .govuk-link--text-colour:focus {
    color: #000;
  }
}

.govuk-link--inverse:link, .govuk-link--inverse:visited {
  color: #fff;
}

.govuk-link--inverse:hover, .govuk-link--inverse:active {
  color: rgba(255, 255, 255, .99);
}

.govuk-link--inverse:focus {
  color: #0b0c0c;
}

.govuk-link--no-underline:not(:hover):not(:active) {
  text-decoration: none;
}

.govuk-link--no-visited-state:link, .app-tile .app-tile__link:link, .app-tile .app-tile__heading:link, .lite-pagination__link:link, .lite-pagination__navigation-link:link, .lite-modal-back-link:link, .lite-expand__base-controls .lite-expand__base-control:link, .lite-link--definition:link, .govuk-link--no-visited-state:visited, .app-tile .app-tile__link:visited, .app-tile .app-tile__heading:visited, .lite-pagination__link:visited, .lite-pagination__navigation-link:visited, .lite-modal-back-link:visited, .lite-expand__base-controls .lite-expand__base-control:visited, .lite-link--definition:visited {
  color: #1d70b8;
}

.govuk-link--no-visited-state:hover, .app-tile .app-tile__link:hover, .app-tile .app-tile__heading:hover, .lite-pagination__link:hover, .lite-pagination__navigation-link:hover, .lite-modal-back-link:hover, .lite-expand__base-controls .lite-expand__base-control:hover, .lite-link--definition:hover {
  color: #003078;
}

.govuk-link--no-visited-state:active, .app-tile .app-tile__link:active, .app-tile .app-tile__heading:active, .lite-pagination__link:active, .lite-pagination__navigation-link:active, .lite-modal-back-link:active, .lite-expand__base-controls .lite-expand__base-control:active, .lite-link--definition:active, .govuk-link--no-visited-state:focus, .app-tile .app-tile__link:focus, .app-tile .app-tile__heading:focus, .lite-pagination__link:focus, .lite-pagination__navigation-link:focus, .lite-modal-back-link:focus, .lite-expand__base-controls .lite-expand__base-control:focus, .lite-link--definition:focus {
  color: #0b0c0c;
}

.govuk-list {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-top: 0;
  margin-bottom: 15px;
  padding-left: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  list-style-type: none;
}

@media print {
  .govuk-list {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-list {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-list {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-list {
    margin-bottom: 20px;
  }
}

.govuk-list .govuk-list {
  margin-top: 10px;
}

.govuk-list > li {
  margin-bottom: 5px;
}

.govuk-list--bullet {
  padding-left: 20px;
  list-style-type: disc;
}

.govuk-list--number {
  padding-left: 20px;
  list-style-type: decimal;
}

.govuk-list--bullet > li, .govuk-list--number > li {
  margin-bottom: 0;
}

@media (min-width: 40.0625em) {
  .govuk-list--bullet > li, .govuk-list--number > li {
    margin-bottom: 5px;
  }
}

.govuk-list--spaced > li {
  margin-bottom: 10px;
}

@media (min-width: 40.0625em) {
  .govuk-list--spaced > li {
    margin-bottom: 15px;
  }
}

.govuk-heading-xl {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 30px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.09375;
  display: block;
}

@media print {
  .govuk-heading-xl {
    color: #000;
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-heading-xl {
    font-size: 3rem;
    line-height: 1.04167;
  }
}

@media print {
  .govuk-heading-xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-heading-xl {
    margin-bottom: 50px;
  }
}

.govuk-heading-l {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.04167;
  display: block;
}

@media print {
  .govuk-heading-l {
    color: #000;
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-heading-l {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-heading-l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

@media (min-width: 40.0625em) {
  .govuk-heading-l {
    margin-bottom: 30px;
  }
}

.govuk-heading-m {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11111;
  display: block;
}

@media print {
  .govuk-heading-m {
    color: #000;
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-heading-m {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-heading-m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-heading-m {
    margin-bottom: 20px;
  }
}

.govuk-heading-s {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-heading-s {
    color: #000;
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-heading-s {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-heading-s {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-heading-s {
    margin-bottom: 20px;
  }
}

.govuk-caption-xl {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  margin-bottom: 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.11111;
  display: block;
}

@media print {
  .govuk-caption-xl {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-caption-xl {
    font-size: 1.6875rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-caption-xl {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-caption-l {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  margin-bottom: 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.11111;
  display: block;
}

@media print {
  .govuk-caption-l {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-caption-l {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-caption-l {
    font-size: 18pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-caption-l {
    margin-bottom: 0;
  }
}

.govuk-caption-m {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-caption-m {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-caption-m {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-caption-m {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-body-lead, .govuk-body-l {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.11111;
}

@media print {
  .govuk-body-lead, .govuk-body-l {
    color: #000;
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-body-lead, .govuk-body-l {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-body-lead, .govuk-body-l {
    font-size: 18pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-body-lead, .govuk-body-l {
    margin-bottom: 30px;
  }
}

.lite-application-note, .app-activity__additional-text, .govuk-body, .govuk-body-m {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .lite-application-note, .app-activity__additional-text, .govuk-body, .govuk-body-m {
    color: #000;
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-application-note, .app-activity__additional-text, .govuk-body, .govuk-body-m {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-application-note, .app-activity__additional-text, .govuk-body, .govuk-body-m {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .lite-application-note, .app-activity__additional-text, .govuk-body, .govuk-body-m {
    margin-bottom: 20px;
  }
}

.govuk-body-s {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .govuk-body-s {
    color: #000;
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-body-s {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-body-s {
    font-size: 14pt;
    line-height: 1.2;
  }
}

@media (min-width: 40.0625em) {
  .govuk-body-s {
    margin-bottom: 20px;
  }
}

.govuk-body-xs {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-body-xs {
    color: #000;
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-body-xs {
    font-size: .875rem;
    line-height: 1.42857;
  }
}

@media print {
  .govuk-body-xs {
    font-size: 12pt;
    line-height: 1.2;
  }
}

@media (min-width: 40.0625em) {
  .govuk-body-xs {
    margin-bottom: 20px;
  }
}

.govuk-body-l + .govuk-heading-l, .govuk-body-lead + .govuk-heading-l {
  padding-top: 5px;
}

@media (min-width: 40.0625em) {
  .govuk-body-l + .govuk-heading-l, .govuk-body-lead + .govuk-heading-l {
    padding-top: 10px;
  }
}

.lite-application-note + .govuk-heading-l, .app-activity__additional-text + .govuk-heading-l, .govuk-body-m + .govuk-heading-l, .govuk-body + .govuk-heading-l, .govuk-body-s + .govuk-heading-l, .govuk-list + .govuk-heading-l {
  padding-top: 15px;
}

@media (min-width: 40.0625em) {
  .lite-application-note + .govuk-heading-l, .app-activity__additional-text + .govuk-heading-l, .govuk-body-m + .govuk-heading-l, .govuk-body + .govuk-heading-l, .govuk-body-s + .govuk-heading-l, .govuk-list + .govuk-heading-l {
    padding-top: 20px;
  }
}

.lite-application-note + .govuk-heading-m, .app-activity__additional-text + .govuk-heading-m, .govuk-body-m + .govuk-heading-m, .govuk-body + .govuk-heading-m, .govuk-body-s + .govuk-heading-m, .govuk-list + .govuk-heading-m, .lite-application-note + .govuk-heading-s, .app-activity__additional-text + .govuk-heading-s, .govuk-body-m + .govuk-heading-s, .govuk-body + .govuk-heading-s, .govuk-body-s + .govuk-heading-s, .govuk-list + .govuk-heading-s {
  padding-top: 5px;
}

@media (min-width: 40.0625em) {
  .lite-application-note + .govuk-heading-m, .app-activity__additional-text + .govuk-heading-m, .govuk-body-m + .govuk-heading-m, .govuk-body + .govuk-heading-m, .govuk-body-s + .govuk-heading-m, .govuk-list + .govuk-heading-m, .lite-application-note + .govuk-heading-s, .app-activity__additional-text + .govuk-heading-s, .govuk-body-m + .govuk-heading-s, .govuk-body + .govuk-heading-s, .govuk-body-s + .govuk-heading-s, .govuk-list + .govuk-heading-s {
    padding-top: 10px;
  }
}

.govuk-section-break {
  border: 0;
  margin: 0;
}

.govuk-section-break--xl {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 40.0625em) {
  .govuk-section-break--xl {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.govuk-section-break--l {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 40.0625em) {
  .govuk-section-break--l {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.govuk-section-break--m {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 40.0625em) {
  .govuk-section-break--m {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.govuk-section-break--visible {
  border-bottom: 1px solid #b1b4b6;
}

.govuk-button-group {
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

@media (min-width: 40.0625em) {
  .govuk-button-group {
    margin-bottom: 15px;
  }
}

.govuk-button-group .govuk-link, .govuk-button-group .app-tile .app-tile__link, .app-tile .govuk-button-group .app-tile__link, .govuk-button-group .app-tile .app-tile__heading, .app-tile .govuk-button-group .app-tile__heading, .govuk-button-group .lite-pagination__link, .govuk-button-group .lite-pagination__navigation-link, .govuk-button-group .lite-modal-back-link, .govuk-button-group .lite-expand__base-controls .lite-expand__base-control, .lite-expand__base-controls .govuk-button-group .lite-expand__base-control, .govuk-button-group .lite-link--definition, .govuk-button-group .lite-button--link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875;
  display: inline-block;
}

@media print {
  .govuk-button-group .govuk-link, .govuk-button-group .app-tile .app-tile__link, .app-tile .govuk-button-group .app-tile__link, .govuk-button-group .app-tile .app-tile__heading, .app-tile .govuk-button-group .app-tile__heading, .govuk-button-group .lite-pagination__link, .govuk-button-group .lite-pagination__navigation-link, .govuk-button-group .lite-modal-back-link, .govuk-button-group .lite-expand__base-controls .lite-expand__base-control, .lite-expand__base-controls .govuk-button-group .lite-expand__base-control, .govuk-button-group .lite-link--definition, .govuk-button-group .lite-button--link {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-button-group .govuk-link, .govuk-button-group .app-tile .app-tile__link, .app-tile .govuk-button-group .app-tile__link, .govuk-button-group .app-tile .app-tile__heading, .app-tile .govuk-button-group .app-tile__heading, .govuk-button-group .lite-pagination__link, .govuk-button-group .lite-pagination__navigation-link, .govuk-button-group .lite-modal-back-link, .govuk-button-group .lite-expand__base-controls .lite-expand__base-control, .lite-expand__base-controls .govuk-button-group .lite-expand__base-control, .govuk-button-group .lite-link--definition, .govuk-button-group .lite-button--link {
    font-size: 1.1875rem;
    line-height: 1;
  }
}

@media print {
  .govuk-button-group .govuk-link, .govuk-button-group .app-tile .app-tile__link, .app-tile .govuk-button-group .app-tile__link, .govuk-button-group .app-tile .app-tile__heading, .app-tile .govuk-button-group .app-tile__heading, .govuk-button-group .lite-pagination__link, .govuk-button-group .lite-pagination__navigation-link, .govuk-button-group .lite-modal-back-link, .govuk-button-group .lite-expand__base-controls .lite-expand__base-control, .lite-expand__base-controls .govuk-button-group .lite-expand__base-control, .govuk-button-group .lite-link--definition, .govuk-button-group .lite-button--link {
    font-size: 14pt;
    line-height: 19px;
  }
}

.govuk-button-group .govuk-button {
  margin-bottom: 17px;
}

@media (min-width: 40.0625em) {
  .govuk-button-group {
    flex-flow: wrap;
    align-items: baseline;
    margin-right: -15px;
  }

  .govuk-button-group .govuk-button, .govuk-button-group .govuk-link, .govuk-button-group .app-tile .app-tile__link, .app-tile .govuk-button-group .app-tile__link, .govuk-button-group .app-tile .app-tile__heading, .app-tile .govuk-button-group .app-tile__heading, .govuk-button-group .lite-pagination__link, .govuk-button-group .lite-pagination__navigation-link, .govuk-button-group .lite-modal-back-link, .govuk-button-group .lite-expand__base-controls .lite-expand__base-control, .lite-expand__base-controls .govuk-button-group .lite-expand__base-control, .govuk-button-group .lite-link--definition, .govuk-button-group .lite-button--link {
    margin-right: 15px;
  }

  .govuk-button-group .govuk-link, .govuk-button-group .app-tile .app-tile__link, .app-tile .govuk-button-group .app-tile__link, .govuk-button-group .app-tile .app-tile__heading, .app-tile .govuk-button-group .app-tile__heading, .govuk-button-group .lite-pagination__link, .govuk-button-group .lite-pagination__navigation-link, .govuk-button-group .lite-modal-back-link, .govuk-button-group .lite-expand__base-controls .lite-expand__base-control, .lite-expand__base-controls .govuk-button-group .lite-expand__base-control, .govuk-button-group .lite-link--definition, .govuk-button-group .lite-button--link {
    text-align: left;
  }
}

.govuk-form-group {
  margin-bottom: 20px;
}

.govuk-form-group:after {
  content: "";
  clear: both;
  display: block;
}

@media (min-width: 40.0625em) {
  .govuk-form-group {
    margin-bottom: 30px;
  }
}

.govuk-form-group .govuk-form-group:last-of-type {
  margin-bottom: 0;
}

.govuk-form-group--error {
  border-left: 5px solid #d4351c;
  padding-left: 15px;
}

.govuk-form-group--error .govuk-form-group {
  border: 0;
  padding: 0;
}

.govuk-grid-row {
  margin-left: -15px;
  margin-right: -15px;
}

.govuk-grid-row:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-grid-column-one-quarter {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 40.0625em) {
  .govuk-grid-column-one-quarter {
    float: left;
    width: 25%;
  }
}

.govuk-grid-column-one-third {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 40.0625em) {
  .govuk-grid-column-one-third {
    float: left;
    width: 33.3333%;
  }
}

.govuk-grid-column-one-half {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 40.0625em) {
  .govuk-grid-column-one-half {
    float: left;
    width: 50%;
  }
}

.govuk-grid-column-two-thirds {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 40.0625em) {
  .govuk-grid-column-two-thirds {
    float: left;
    width: 66.6667%;
  }
}

.govuk-grid-column-three-quarters {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 40.0625em) {
  .govuk-grid-column-three-quarters {
    float: left;
    width: 75%;
  }
}

.govuk-grid-column-full {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 40.0625em) {
  .govuk-grid-column-full {
    float: left;
    width: 100%;
  }
}

.govuk-grid-column-one-quarter-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (min-width: 48.0625em) {
  .govuk-grid-column-one-quarter-from-desktop {
    float: left;
    width: 25%;
  }
}

.govuk-grid-column-one-third-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (min-width: 48.0625em) {
  .govuk-grid-column-one-third-from-desktop {
    float: left;
    width: 33.3333%;
  }
}

.govuk-grid-column-one-half-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (min-width: 48.0625em) {
  .govuk-grid-column-one-half-from-desktop {
    float: left;
    width: 50%;
  }
}

.govuk-grid-column-two-thirds-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (min-width: 48.0625em) {
  .govuk-grid-column-two-thirds-from-desktop {
    float: left;
    width: 66.6667%;
  }
}

.govuk-grid-column-three-quarters-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (min-width: 48.0625em) {
  .govuk-grid-column-three-quarters-from-desktop {
    float: left;
    width: 75%;
  }
}

.govuk-grid-column-full-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (min-width: 48.0625em) {
  .govuk-grid-column-full-from-desktop {
    float: left;
    width: 100%;
  }
}

.govuk-main-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
}

@media (min-width: 40.0625em) {
  .govuk-main-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.govuk-main-wrapper--auto-spacing:first-child, .govuk-main-wrapper--l {
  padding-top: 30px;
}

@media (min-width: 40.0625em) {
  .govuk-main-wrapper--auto-spacing:first-child, .govuk-main-wrapper--l {
    padding-top: 50px;
  }
}

.govuk-template {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #f3f2f1;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .govuk-template {
    scroll-padding-top: 60px;
  }

  .govuk-template:not(:has(.govuk-exit-this-page)) {
    scroll-padding-top: 0;
  }
}

@media screen {
  .govuk-template {
    overflow-y: scroll;
  }
}

.govuk-template__body {
  background-color: #fff;
  margin: 0;
}

.govuk-width-container {
  max-width: 1200px;
  margin-left: 15px;
  margin-right: 15px;
}

@supports (margin: max(calc(0px))) {
  .govuk-width-container {
    margin-right: max(15px, calc(15px + env(safe-area-inset-right)));
    margin-left: max(15px, calc(15px + env(safe-area-inset-left)));
  }
}

@media (min-width: 40.0625em) {
  .govuk-width-container {
    margin-left: 30px;
    margin-right: 30px;
  }

  @supports (margin: max(calc(0px))) {
    .govuk-width-container {
      margin-right: max(30px, calc(15px + env(safe-area-inset-right)));
      margin-left: max(30px, calc(15px + env(safe-area-inset-left)));
    }
  }
}

@media (min-width: 1260px) {
  .govuk-width-container {
    margin-left: auto;
    margin-right: auto;
  }

  @supports (margin: max(calc(0px))) {
    .govuk-width-container {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.govuk-accordion {
  margin-bottom: 20px;
}

@media (min-width: 40.0625em) {
  .govuk-accordion {
    margin-bottom: 30px;
  }
}

.govuk-accordion__section {
  padding-top: 15px;
}

.govuk-accordion__section-heading {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}

.govuk-accordion__section-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-bottom: 0;
  padding-top: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11111;
  display: block;
}

@media print {
  .govuk-accordion__section-button {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-accordion__section-button {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-accordion__section-button {
    color: #000;
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-accordion__section-content > :last-child {
  margin-bottom: 0;
}

.js-enabled .govuk-accordion {
  border-bottom: 1px solid #b1b4b6;
}

.js-enabled .govuk-accordion__section {
  padding-top: 0;
}

.js-enabled .govuk-accordion__section-content {
  padding-top: 15px;
  padding-bottom: 30px;
  display: none;
}

@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__section-content {
    padding-bottom: 50px;
  }
}

.js-enabled .govuk-accordion__section-content[hidden] {
  padding-top: 0;
  padding-bottom: 0;
}

@supports (content-visibility: hidden) {
  .js-enabled .govuk-accordion__section-content[hidden] {
    content-visibility: hidden;
    display: inherit;
  }
}

.js-enabled .govuk-accordion__section--expanded .govuk-accordion__section-content {
  display: block;
}

.js-enabled .govuk-accordion__show-all {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
  color: #1d70b8;
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  border-width: 0;
  margin-bottom: 9px;
  padding: 5px 2px 5px 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  position: relative;
}

@media print {
  .js-enabled .govuk-accordion__show-all {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__show-all {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .js-enabled .govuk-accordion__show-all {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__show-all {
    margin-bottom: 14px;
  }
}

.js-enabled .govuk-accordion__show-all::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.js-enabled .govuk-accordion__show-all:hover {
  color: #0b0c0c;
  background: #f3f2f1;
  box-shadow: 0 -2px #f3f2f1, 0 4px #f3f2f1;
}

.js-enabled .govuk-accordion__show-all:hover .govuk-accordion__section-toggle-text {
  color: #0b0c0c;
}

.js-enabled .govuk-accordion__show-all:hover .govuk-accordion-nav__chevron {
  color: #0b0c0c;
  background: #0b0c0c;
}

.js-enabled .govuk-accordion__show-all:hover .govuk-accordion-nav__chevron:after {
  color: #f3f2f1;
}

.js-enabled .govuk-accordion__show-all:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.js-enabled .govuk-accordion__show-all:focus .govuk-accordion-nav__chevron {
  background: #0b0c0c;
}

.js-enabled .govuk-accordion__show-all:focus .govuk-accordion-nav__chevron:after {
  color: #fd0;
}

.js-enabled .govuk-accordion__section-heading {
  padding: 0;
}

.js-enabled .govuk-accordion-nav__chevron {
  box-sizing: border-box;
  vertical-align: middle;
  border: .0625rem solid;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
}

.js-enabled .govuk-accordion-nav__chevron:after {
  content: "";
  box-sizing: border-box;
  border-top: .125rem solid;
  border-right: .125rem solid;
  width: .375rem;
  height: .375rem;
  display: block;
  position: absolute;
  bottom: .3125rem;
  left: .375rem;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.js-enabled .govuk-accordion-nav__chevron--down {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.js-enabled .govuk-accordion__section-button {
  color: #0b0c0c;
  text-align: left;
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  border: 0;
  border-top: 1px solid #b1b4b6;
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  width: 100%;
  padding: 10px 0 0;
}

@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__section-button {
    padding-bottom: 10px;
  }
}

.js-enabled .govuk-accordion__section-button:active {
  color: #0b0c0c;
  background: none;
}

.js-enabled .govuk-accordion__section-button:hover {
  color: #0b0c0c;
  background: #f3f2f1;
}

.js-enabled .govuk-accordion__section-button:hover .govuk-accordion__section-toggle-text {
  color: #0b0c0c;
}

.js-enabled .govuk-accordion__section-button:hover .govuk-accordion-nav__chevron {
  color: #0b0c0c;
  background: #0b0c0c;
}

.js-enabled .govuk-accordion__section-button:hover .govuk-accordion-nav__chevron:after {
  color: #f3f2f1;
}

.js-enabled .govuk-accordion__section-button:focus {
  outline: 0;
}

.js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-heading-text-focus, .js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-summary-focus, .js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.js-enabled .govuk-accordion__section-button:focus .govuk-accordion-nav__chevron {
  color: #0b0c0c;
  background: #0b0c0c;
}

.js-enabled .govuk-accordion__section-button:focus .govuk-accordion-nav__chevron:after {
  color: #fd0;
}

.js-enabled .govuk-accordion__section-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.js-enabled .govuk-accordion__section--expanded .govuk-accordion__section-button {
  border-bottom: 0;
  padding-bottom: 15px;
}

@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__section--expanded .govuk-accordion__section-button {
    padding-bottom: 20px;
  }
}

.js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus {
  padding-bottom: 3px;
}

@media (min-width: 48.0625em) {
  .js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus {
    padding-bottom: 2px;
  }
}

.js-enabled .govuk-accordion__section-toggle, .js-enabled .govuk-accordion__section-heading-text, .js-enabled .govuk-accordion__section-summary {
  margin-bottom: 13px;
  display: block;
}

.js-enabled .govuk-accordion__section-toggle .govuk-accordion__section-heading-text-focus, .js-enabled .govuk-accordion__section-toggle .govuk-accordion__section-summary-focus, .js-enabled .govuk-accordion__section-toggle .govuk-accordion__section-toggle-focus, .js-enabled .govuk-accordion__section-heading-text .govuk-accordion__section-heading-text-focus, .js-enabled .govuk-accordion__section-heading-text .govuk-accordion__section-summary-focus, .js-enabled .govuk-accordion__section-heading-text .govuk-accordion__section-toggle-focus, .js-enabled .govuk-accordion__section-summary .govuk-accordion__section-heading-text-focus, .js-enabled .govuk-accordion__section-summary .govuk-accordion__section-summary-focus, .js-enabled .govuk-accordion__section-summary .govuk-accordion__section-toggle-focus {
  display: inline;
}

.js-enabled .govuk-accordion__section-toggle {
  color: #1d70b8;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media (min-width: 40.0625em) {
  .js-enabled .govuk-accordion__section-toggle {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .js-enabled .govuk-accordion__section-toggle {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.js-enabled .govuk-accordion__show-all-text, .js-enabled .govuk-accordion__section-toggle-text {
  vertical-align: middle;
  margin-left: 5px;
}

@media screen and (forced-colors: active) {
  .js-enabled .govuk-accordion__show-all:hover .govuk-accordion-nav__chevron, .js-enabled .govuk-accordion__section-button:hover .govuk-accordion-nav__chevron {
    background-color: rgba(0, 0, 0, 0);
  }

  .js-enabled .govuk-accordion__show-all:focus .govuk-accordion__section-heading-text-focus, .js-enabled .govuk-accordion__show-all:focus .govuk-accordion__section-summary-focus, .js-enabled .govuk-accordion__show-all:focus .govuk-accordion__section-toggle-focus, .js-enabled .govuk-accordion__show-all:focus .govuk-accordion-nav__chevron, .js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-heading-text-focus, .js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-summary-focus, .js-enabled .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus, .js-enabled .govuk-accordion__section-button:focus .govuk-accordion-nav__chevron {
    background: none;
  }
}

@media (hover: none) {
  .js-enabled .govuk-accordion__section-header:hover {
    border-top-color: #b1b4b6;
    box-shadow: inset 0 3px #1d70b8;
  }

  .js-enabled .govuk-accordion__section-header:hover .govuk-accordion__section-button {
    border-top-color: #b1b4b6;
  }
}

.govuk-back-link, .lite-back-link-button, button.lite-back-link-button, button.govuk-back-link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: .875em;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  line-height: 1.14286;
  text-decoration: underline;
  display: inline-block;
  position: relative;
}

@media (min-width: 40.0625em) {
  .govuk-back-link, .lite-back-link-button, button.lite-back-link-button, button.govuk-back-link {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-back-link, .lite-back-link-button, button.lite-back-link-button, button.govuk-back-link {
    font-family: sans-serif;
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-back-link:focus, .lite-back-link-button:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-back-link:link, .lite-back-link-button:link, .govuk-back-link:visited, .lite-back-link-button:visited {
  color: #0b0c0c;
}

@media print {
  .govuk-back-link:link, .lite-back-link-button:link, .govuk-back-link:visited, .lite-back-link-button:visited {
    color: #000;
  }
}

.govuk-back-link:hover, .lite-back-link-button:hover {
  color: rgba(11, 12, 12, .99);
}

.govuk-back-link:active, .lite-back-link-button:active, .govuk-back-link:focus, .lite-back-link-button:focus {
  color: #0b0c0c;
}

@media print {
  .govuk-back-link:active, .lite-back-link-button:active, .govuk-back-link:focus, .lite-back-link-button:focus {
    color: #000;
  }
}

.govuk-back-link:before, .lite-back-link-button:before {
  content: "";
  border: 1px solid #505a5f;
  border-width: 1px 1px 0 0;
  width: .4375em;
  height: .4375em;
  margin: auto 0;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: .1875em;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

@supports (border-width: max(0px)) {
  .govuk-back-link:before, .lite-back-link-button:before {
    border-width: max(1px, .0625em) max(1px, .0625em) 0 0;
    font-size: max(16px, 1em);
  }
}

.govuk-back-link:focus:before, .lite-back-link-button:focus:before {
  border-color: #0b0c0c;
}

.govuk-back-link:after, .lite-back-link-button:after {
  content: "";
  position: absolute;
  top: -14px;
  bottom: -14px;
  left: 0;
  right: 0;
}

.govuk-back-link--inverse:link, .govuk-back-link--inverse:visited {
  color: #fff;
}

.govuk-back-link--inverse:hover, .govuk-back-link--inverse:active {
  color: rgba(255, 255, 255, .99);
}

.govuk-back-link--inverse:focus {
  color: #0b0c0c;
}

.govuk-back-link--inverse:before {
  border-color: currentColor;
}

.govuk-breadcrumbs {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .govuk-breadcrumbs {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-breadcrumbs {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-breadcrumbs {
    color: #000;
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-breadcrumbs__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.govuk-breadcrumbs__list:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-breadcrumbs__list-item {
  float: left;
  margin-bottom: 5px;
  margin-left: .625em;
  padding-left: .978438em;
  display: inline-block;
  position: relative;
}

.govuk-breadcrumbs__list-item:before {
  content: "";
  border: 1px solid #505a5f;
  border-width: 1px 1px 0 0;
  width: .4375em;
  height: .4375em;
  margin: auto 0;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -.206875em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@supports (border-width: max(0px)) {
  .govuk-breadcrumbs__list-item:before {
    border-width: max(1px, .0625em) max(1px, .0625em) 0 0;
    font-size: max(16px, 1em);
  }
}

.govuk-breadcrumbs__list-item:first-child {
  margin-left: 0;
  padding-left: 0;
}

.govuk-breadcrumbs__list-item:first-child:before {
  content: none;
  display: none;
}

.govuk-breadcrumbs__link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  text-decoration: underline;
}

@media print {
  .govuk-breadcrumbs__link {
    font-family: sans-serif;
  }
}

.govuk-breadcrumbs__link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-breadcrumbs__link:link, .govuk-breadcrumbs__link:visited {
  color: #0b0c0c;
}

@media print {
  .govuk-breadcrumbs__link:link, .govuk-breadcrumbs__link:visited {
    color: #000;
  }
}

.govuk-breadcrumbs__link:hover {
  color: rgba(11, 12, 12, .99);
}

.govuk-breadcrumbs__link:active, .govuk-breadcrumbs__link:focus {
  color: #0b0c0c;
}

@media print {
  .govuk-breadcrumbs__link:active, .govuk-breadcrumbs__link:focus {
    color: #000;
  }
}

@media (max-width: 40.0525em) {
  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item {
    display: none;
  }

  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item:first-child, .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item:last-child {
    display: inline-block;
  }

  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item:before {
    margin: 0;
    top: .375em;
  }

  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list {
    display: flex;
  }
}

.govuk-breadcrumbs--inverse, .govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:link, .govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:visited {
  color: #fff;
}

.govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:hover, .govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:active {
  color: rgba(255, 255, 255, .99);
}

.govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:focus {
  color: #0b0c0c;
}

.govuk-breadcrumbs--inverse .govuk-breadcrumbs__list-item:before {
  border-color: currentColor;
}

.govuk-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #00703c;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  width: 100%;
  margin: 0 0 22px;
  padding: 8px 10px 7px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875;
  display: inline-block;
  position: relative;
  box-shadow: 0 2px #002d18;
}

@media print {
  .govuk-button {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-button {
    font-size: 1.1875rem;
    line-height: 1;
  }
}

@media print {
  .govuk-button {
    font-size: 14pt;
    line-height: 19px;
  }
}

@media (min-width: 40.0625em) {
  .govuk-button {
    width: auto;
    margin-bottom: 32px;
  }
}

.govuk-button:link, .govuk-button:visited, .govuk-button:active, .govuk-button:hover {
  color: #fff;
  text-decoration: none;
}

.govuk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.govuk-button:hover {
  background-color: #005a30;
}

.govuk-button:active {
  top: 2px;
}

.govuk-button:focus {
  border-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 1px #fd0;
}

.govuk-button:focus:not(:active):not(:hover) {
  color: #0b0c0c;
  background-color: #fd0;
  border-color: #fd0;
  box-shadow: 0 2px #0b0c0c;
}

.govuk-button:before {
  content: "";
  background: none;
  display: block;
  position: absolute;
  top: -2px;
  bottom: -4px;
  left: -2px;
  right: -2px;
}

.govuk-button:active:before {
  top: -4px;
}

.govuk-button--disabled, .govuk-button[disabled] {
  opacity: .5;
}

.govuk-button[disabled="disabled"] {
  opacity: .5;
}

.govuk-button--disabled:hover, .govuk-button[disabled]:hover {
  cursor: not-allowed;
  background-color: #00703c;
}

.govuk-button[disabled="disabled"]:hover {
  cursor: not-allowed;
  background-color: #00703c;
}

.govuk-button--disabled:active, .govuk-button[disabled]:active {
  top: 0;
  box-shadow: 0 2px #002d18;
}

.govuk-button[disabled="disabled"]:active {
  top: 0;
  box-shadow: 0 2px #002d18;
}

.govuk-button--secondary {
  background-color: #f3f2f1;
  box-shadow: 0 2px #929191;
}

.govuk-button--secondary, .govuk-button--secondary:link, .govuk-button--secondary:visited, .govuk-button--secondary:active, .govuk-button--secondary:hover {
  color: #0b0c0c;
}

.govuk-button--secondary:hover {
  background-color: #dbdad9;
}

.govuk-button--secondary:hover[disabled] {
  background-color: #f3f2f1;
}

.govuk-button--warning {
  background-color: #d4351c;
  box-shadow: 0 2px #55150b;
}

.govuk-button--warning, .govuk-button--warning:link, .govuk-button--warning:visited, .govuk-button--warning:active, .govuk-button--warning:hover {
  color: #fff;
}

.govuk-button--warning:hover {
  background-color: #aa2a16;
}

.govuk-button--warning:hover[disabled] {
  background-color: #d4351c;
}

.govuk-button--inverse {
  background-color: #fff;
  box-shadow: 0 2px #144e81;
}

.govuk-button--inverse, .govuk-button--inverse:link, .govuk-button--inverse:visited, .govuk-button--inverse:active, .govuk-button--inverse:hover {
  color: #1d70b8;
}

.govuk-button--inverse:hover {
  background-color: #e8f1f8;
}

.govuk-button--inverse:hover[disabled] {
  background-color: #fff;
}

.govuk-button--start {
  justify-content: center;
  min-height: auto;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
  display: inline-flex;
}

@media (min-width: 40.0625em) {
  .govuk-button--start {
    font-size: 1.5rem;
    line-height: 1;
  }
}

@media print {
  .govuk-button--start {
    font-size: 18pt;
    line-height: 1;
  }
}

.govuk-button__start-icon {
  vertical-align: middle;
  forced-color-adjust: auto;
  flex-shrink: 0;
  align-self: center;
  margin-left: 5px;
}

@media (min-width: 48.0625em) {
  .govuk-button__start-icon {
    margin-left: 10px;
  }
}

.govuk-error-message {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  clear: both;
  color: #d4351c;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-error-message {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-error-message {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-error-message {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

.govuk-fieldset:after {
  content: "";
  clear: both;
  display: block;
}

@supports not (caret-color: auto) {
  .govuk-fieldset {
    display: table-cell;
  }

  x:-moz-any-link {
    display: table-cell;
  }
}

.govuk-fieldset__legend {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  box-sizing: border-box;
  white-space: normal;
  max-width: 100%;
  margin-bottom: 10px;
  padding: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: table;
}

@media print {
  .govuk-fieldset__legend {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-fieldset__legend {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-fieldset__legend {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-fieldset__legend--xl {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.09375;
}

@media print {
  .govuk-fieldset__legend--xl {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-fieldset__legend--xl {
    font-size: 3rem;
    line-height: 1.04167;
  }
}

@media print {
  .govuk-fieldset__legend--xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-fieldset__legend--l {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.04167;
}

@media print {
  .govuk-fieldset__legend--l {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-fieldset__legend--l {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-fieldset__legend--l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.govuk-fieldset__legend--m {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11111;
}

@media print {
  .govuk-fieldset__legend--m {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-fieldset__legend--m {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-fieldset__legend--m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-fieldset__legend--s {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
}

@media print {
  .govuk-fieldset__legend--s {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-fieldset__legend--s {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-fieldset__legend--s {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-fieldset__heading {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.govuk-hint, .lite-filters__hint-text, .lite-file-upload__or-label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-hint, .lite-filters__hint-text, .lite-file-upload__or-label {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-hint, .lite-filters__hint-text, .lite-file-upload__or-label {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-hint, .lite-filters__hint-text, .lite-file-upload__or-label {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-label:not(.govuk-label--m):not(.govuk-label--l):not(.govuk-label--xl) + .govuk-hint, .govuk-label:not(.govuk-label--m):not(.govuk-label--l):not(.govuk-label--xl) + .lite-filters__hint-text, .govuk-label:not(.govuk-label--m):not(.govuk-label--l):not(.govuk-label--xl) + .lite-file-upload__or-label, .govuk-fieldset__legend:not(.govuk-fieldset__legend--m):not(.govuk-fieldset__legend--l):not(.govuk-fieldset__legend--xl) + .govuk-hint, .govuk-fieldset__legend:not(.govuk-fieldset__legend--m):not(.govuk-fieldset__legend--l):not(.govuk-fieldset__legend--xl) + .lite-filters__hint-text, .govuk-fieldset__legend:not(.govuk-fieldset__legend--m):not(.govuk-fieldset__legend--l):not(.govuk-fieldset__legend--xl) + .lite-file-upload__or-label {
  margin-bottom: 10px;
}

.govuk-fieldset__legend + .govuk-hint, .govuk-fieldset__legend + .lite-filters__hint-text, .govuk-fieldset__legend + .lite-file-upload__or-label {
  margin-top: -5px;
}

.govuk-label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-bottom: 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-label {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-label {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-label {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-label--xl {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.09375;
}

@media print {
  .govuk-label--xl {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-label--xl {
    font-size: 3rem;
    line-height: 1.04167;
  }
}

@media print {
  .govuk-label--xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-label--l {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.04167;
}

@media print {
  .govuk-label--l {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-label--l {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-label--l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.govuk-label--m {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11111;
}

@media print {
  .govuk-label--m {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-label--m {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-label--m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-label--s {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
}

@media print {
  .govuk-label--s {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-label--s {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-label--s {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-label-wrapper {
  margin: 0;
}

.govuk-checkboxes__item {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  clear: left;
  min-height: 40px;
  margin-bottom: 10px;
  padding-left: 40px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
  position: relative;
}

@media print {
  .govuk-checkboxes__item {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-checkboxes__item {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-checkboxes__item {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-checkboxes__item:last-child, .govuk-checkboxes__item:last-of-type {
  margin-bottom: 0;
}

.govuk-checkboxes__input {
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  width: 44px;
  height: 44px;
  margin: 0;
  position: absolute;
  top: -2px;
  left: -2px;
}

.govuk-checkboxes__label {
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  margin-bottom: 0;
  padding: 8px 15px 5px;
  display: inline-block;
}

.govuk-checkboxes__label:before {
  content: "";
  box-sizing: border-box;
  background: none;
  border: 2px solid;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.govuk-checkboxes__label:after {
  content: "";
  box-sizing: border-box;
  opacity: 0;
  background: none;
  border-top: 0 solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid;
  border-left: 5px solid;
  border-right: 0 solid;
  width: 23px;
  height: 12px;
  position: absolute;
  top: 11px;
  left: 9px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.govuk-checkboxes__hint {
  padding-left: 15px;
  padding-right: 15px;
  display: block;
}

.govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
  outline-offset: 1px;
  border-width: 4px;
  outline: 3px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 3px #fd0;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
    outline-color: Highlight;
  }
}

.govuk-checkboxes__input:checked + .govuk-checkboxes__label:after {
  opacity: 1;
}

.govuk-checkboxes__input:disabled, .govuk-checkboxes__input:disabled + .govuk-checkboxes__label {
  cursor: not-allowed;
}

.govuk-checkboxes__input:disabled + .govuk-checkboxes__label, .govuk-checkboxes__input:disabled ~ .govuk-hint, .govuk-checkboxes__input:disabled ~ .lite-filters__hint-text, .govuk-checkboxes__input:disabled ~ .lite-file-upload__or-label {
  opacity: .5;
}

.govuk-checkboxes__divider {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  text-align: center;
  width: 40px;
  margin-bottom: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-checkboxes__divider {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-checkboxes__divider {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-checkboxes__divider {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-checkboxes__conditional {
  border-left: 4px solid #b1b4b6;
  margin-bottom: 15px;
  margin-left: 18px;
  padding-left: 33px;
}

@media (min-width: 40.0625em) {
  .govuk-checkboxes__conditional {
    margin-bottom: 20px;
  }
}

.js-enabled .govuk-checkboxes__conditional--hidden {
  display: none;
}

.govuk-checkboxes__conditional > :last-child {
  margin-bottom: 0;
}

.govuk-checkboxes--small .govuk-checkboxes__item, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__item, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__item, .govuk-table__cell--checkbox .govuk-checkboxes__item {
  float: left;
  min-height: 0;
  margin-bottom: 0;
  padding-left: 34px;
}

.govuk-checkboxes--small .govuk-checkboxes__item:after, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__item:after, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__item:after, .govuk-table__cell--checkbox .govuk-checkboxes__item:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-checkboxes--small .govuk-checkboxes__input, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__input, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__input, .govuk-table__cell--checkbox .govuk-checkboxes__input {
  left: -10px;
}

.govuk-checkboxes--small .govuk-checkboxes__label, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__label, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__label, .govuk-table__cell--checkbox .govuk-checkboxes__label {
  float: left;
  margin-top: -2px;
  padding: 13px 15px 13px 1px;
}

@media (min-width: 40.0625em) {
  .govuk-checkboxes--small .govuk-checkboxes__label, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__label, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__label, .govuk-table__cell--checkbox .govuk-checkboxes__label {
    padding: 11px 15px 10px 1px;
  }
}

.govuk-checkboxes--small .govuk-checkboxes__label:before, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__label:before, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__label:before, .govuk-table__cell--checkbox .govuk-checkboxes__label:before {
  width: 24px;
  height: 24px;
  top: 8px;
}

.govuk-checkboxes--small .govuk-checkboxes__label:after, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__label:after, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__label:after, .govuk-table__cell--checkbox .govuk-checkboxes__label:after {
  border-width: 0 0 3px 3px;
  width: 12px;
  height: 6.5px;
  top: 15px;
  left: 6px;
}

.govuk-checkboxes--small .govuk-checkboxes__hint, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__hint, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__hint, .govuk-table__cell--checkbox .govuk-checkboxes__hint {
  clear: both;
  padding: 0;
}

.govuk-checkboxes--small .govuk-checkboxes__conditional, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__conditional, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__conditional, .govuk-table__cell--checkbox .govuk-checkboxes__conditional {
  clear: both;
  margin-left: 10px;
  padding-left: 20px;
}

.govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before, .govuk-table__cell--checkbox .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before {
  box-shadow: 0 0 0 10px #b1b4b6;
}

.govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before, .govuk-table__cell--checkbox .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
  box-shadow: 0 0 0 3px #fd0, 0 0 0 10px #b1b4b6;
}

@media (hover: none), (pointer: coarse) {
  .govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before, .govuk-table__cell--checkbox .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before {
    box-shadow: initial;
  }

  .govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before, .lite-tree-view__children .govuk-checkboxes__item .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before, .lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before, .govuk-table__cell--checkbox .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
    box-shadow: 0 0 0 3px #fd0;
  }
}

.govuk-textarea, .lite-case-note__textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  resize: vertical;
  -webkit-appearance: none;
  border: 2px solid #0b0c0c;
  border-radius: 0;
  width: 100%;
  min-height: 40px;
  margin-bottom: 20px;
  padding: 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-textarea, .lite-case-note__textarea {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-textarea, .lite-case-note__textarea {
    font-size: 1.1875rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-textarea, .lite-case-note__textarea {
    font-size: 14pt;
    line-height: 1.25;
  }
}

@media (min-width: 40.0625em) {
  .govuk-textarea, .lite-case-note__textarea {
    margin-bottom: 30px;
  }
}

.govuk-textarea:focus, .lite-case-note__textarea:focus {
  outline-offset: 0;
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 2px;
}

.govuk-textarea:disabled, .lite-case-note__textarea:disabled {
  opacity: .5;
  color: inherit;
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0);
}

.govuk-textarea--error, .lite-case-note__container--error .lite-case-note__textarea {
  border-color: #d4351c;
}

.govuk-textarea--error:focus, .lite-case-note__container--error .lite-case-note__textarea:focus {
  border-color: #0b0c0c;
}

.govuk-character-count {
  margin-bottom: 20px;
}

@media (min-width: 40.0625em) {
  .govuk-character-count {
    margin-bottom: 30px;
  }
}

.govuk-character-count .govuk-form-group, .govuk-character-count .govuk-textarea, .govuk-character-count .lite-case-note__textarea {
  margin-bottom: 5px;
}

.govuk-character-count__message {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "tnum" 1;
  font-feature-settings: "tnum" 1;
  margin-top: 0;
  margin-bottom: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-weight: 400;
}

@media print {
  .govuk-character-count__message {
    font-family: sans-serif;
  }
}

@supports (font-variant-numeric: tabular-nums) {
  .govuk-character-count__message {
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums;
  }
}

.govuk-character-count__message:after {
  content: "​";
}

.govuk-character-count__message--disabled {
  visibility: hidden;
}

.govuk-cookie-banner {
  background-color: #f3f2f1;
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  padding-top: 20px;
}

.govuk-cookie-banner[hidden] {
  display: none;
}

.govuk-cookie-banner__message {
  margin-bottom: -10px;
}

.govuk-cookie-banner__message[hidden] {
  display: none;
}

.govuk-cookie-banner__message:focus {
  outline: none;
}

.govuk-input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #0b0c0c;
  border-radius: 0;
  width: 100%;
  height: 2.5rem;
  margin-top: 0;
  padding: 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-input {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-input {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-input {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-input:focus {
  outline-offset: 0;
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 2px;
}

.govuk-input:disabled {
  opacity: .5;
  color: inherit;
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0);
}

.govuk-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.govuk-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.govuk-input[type="number"] {
  -moz-appearance: textfield;
}

.govuk-input--error {
  border-color: #d4351c;
}

.govuk-input--error:focus {
  border-color: #0b0c0c;
}

.govuk-input--extra-letter-spacing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "tnum" 1;
  font-feature-settings: "tnum" 1;
  letter-spacing: .05em;
  font-family: GDS Transport, arial, sans-serif;
  font-weight: 400;
}

@media print {
  .govuk-input--extra-letter-spacing {
    font-family: sans-serif;
  }
}

@supports (font-variant-numeric: tabular-nums) {
  .govuk-input--extra-letter-spacing {
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums;
  }
}

.govuk-input--width-30 {
  max-width: 29.5em;
}

.govuk-input--width-20 {
  max-width: 20.5em;
}

.govuk-input[type="text"]:not(.govuk-date-input__input):not(.input-force-default-width) {
  max-width: 20.5em;
}

.govuk-input--width-10 {
  max-width: 11.5em;
}

.govuk-input--width-5 {
  max-width: 5.5em;
}

.govuk-input--width-4 {
  max-width: 4.5em;
}

.govuk-input--width-3 {
  max-width: 3.75em;
}

.govuk-input--width-2 {
  max-width: 2.75em;
}

.govuk-input__wrapper {
  display: flex;
}

.govuk-input__wrapper .govuk-input {
  flex: 0 auto;
}

.govuk-input__wrapper .govuk-input:focus {
  z-index: 1;
}

@media (max-width: 19.99em) {
  .govuk-input__wrapper {
    display: block;
  }

  .govuk-input__wrapper .govuk-input {
    max-width: 100%;
  }
}

.govuk-input__prefix, .govuk-input__suffix {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  cursor: default;
  background-color: #f3f2f1;
  border: 2px solid #0b0c0c;
  flex: none;
  min-width: 2.5rem;
  height: 2.5rem;
  padding: 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: inline-block;
}

@media print {
  .govuk-input__prefix, .govuk-input__suffix {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-input__prefix, .govuk-input__suffix {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-input__prefix, .govuk-input__suffix {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (max-width: 40.0525em) {
  .govuk-input__prefix, .govuk-input__suffix {
    line-height: 1.6;
  }
}

@media (max-width: 19.99em) {
  .govuk-input__prefix, .govuk-input__suffix {
    white-space: normal;
    height: 100%;
    display: block;
  }

  .govuk-input__prefix {
    border-bottom: 0;
  }
}

@media (min-width: 20em) {
  .govuk-input__prefix {
    border-right: 0;
  }
}

@media (max-width: 19.99em) {
  .govuk-input__suffix {
    border-top: 0;
  }
}

@media (min-width: 20em) {
  .govuk-input__suffix {
    border-left: 0;
  }
}

.govuk-date-input {
  font-size: 0;
}

.govuk-date-input:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-date-input__item {
  margin-bottom: 0;
  margin-right: 20px;
  display: inline-block;
}

.govuk-date-input__label {
  display: block;
}

.govuk-date-input__input {
  margin-bottom: 0;
}

.govuk-details {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-bottom: 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-details {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-details {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-details {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-details {
    margin-bottom: 30px;
  }
}

.govuk-details__summary {
  color: #1d70b8;
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 25px;
  display: inline-block;
  position: relative;
}

.govuk-details__summary:hover {
  color: #003078;
}

.govuk-details__summary:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-details__summary-text {
  text-decoration: underline;
}

.govuk-details__summary:focus .govuk-details__summary-text {
  text-decoration: none;
}

.govuk-details__summary::-webkit-details-marker {
  display: none;
}

.govuk-details__summary:before {
  content: "";
  -webkit-clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  border: 7px solid rgba(0, 0, 0, 0);
  border-width: 7px 0 7px 12.124px;
  border-left-color: inherit;
  width: 0;
  height: 0;
  margin: auto;
  display: block;
  position: absolute;
  top: -1px;
  bottom: 0;
  left: 0;
}

.govuk-details[open] > .govuk-details__summary:before {
  -webkit-clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  border: 7px solid rgba(0, 0, 0, 0);
  border-width: 12.124px 7px 0;
  border-top-color: inherit;
  width: 0;
  height: 0;
  display: block;
}

.govuk-details__text {
  border-left: 5px solid #b1b4b6;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
}

.govuk-details__text p {
  margin-top: 0;
  margin-bottom: 20px;
}

.govuk-details__text > :last-child {
  margin-bottom: 0;
}

.govuk-error-summary {
  color: #0b0c0c;
  border: 5px solid #d4351c;
  margin-bottom: 30px;
  padding: 15px;
}

@media print {
  .govuk-error-summary {
    color: #000;
  }
}

@media (min-width: 40.0625em) {
  .govuk-error-summary {
    margin-bottom: 50px;
    padding: 20px;
  }
}

.govuk-error-summary:focus {
  outline: 3px solid #fd0;
}

.govuk-error-summary__title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11111;
}

@media print {
  .govuk-error-summary__title {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-error-summary__title {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-error-summary__title {
    font-size: 18pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-error-summary__title {
    margin-bottom: 20px;
  }
}

.govuk-error-summary__body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-error-summary__body {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-error-summary__body {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-error-summary__body {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-error-summary__body p {
  margin-top: 0;
  margin-bottom: 15px;
}

@media (min-width: 40.0625em) {
  .govuk-error-summary__body p {
    margin-bottom: 20px;
  }
}

.govuk-error-summary__list {
  margin-top: 0;
  margin-bottom: 0;
}

.govuk-error-summary__list a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-weight: 700;
  text-decoration: underline;
}

@media print {
  .govuk-error-summary__list a {
    font-family: sans-serif;
  }
}

.govuk-error-summary__list a:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-error-summary__list a:link, .govuk-error-summary__list a:visited {
  color: #d4351c;
}

.govuk-error-summary__list a:hover {
  color: #942514;
}

.govuk-error-summary__list a:active {
  color: #d4351c;
}

.govuk-error-summary__list a:focus {
  color: #0b0c0c;
}

.govuk-exit-this-page {
  z-index: 1000;
  width: 100%;
  margin-bottom: 30px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
}

@media (min-width: 40.0625em) {
  .govuk-exit-this-page {
    float: right;
    width: auto;
    margin-bottom: 50px;
    display: inline-block;
    left: auto;
    right: 0;
  }
}

.govuk-exit-this-page__button {
  margin-bottom: 0;
}

.govuk-exit-this-page__indicator {
  color: inherit;
  text-align: center;
  pointer-events: none;
  padding: 10px 10px 0;
  line-height: 0;
  display: none;
}

.govuk-exit-this-page__indicator--visible {
  display: block;
}

.govuk-exit-this-page__indicator-light {
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 50%;
  width: .75em;
  height: .75em;
  margin: 0 .125em;
  display: inline-block;
}

.govuk-exit-this-page__indicator-light--on {
  border-width: .375em;
}

@media only print {
  .govuk-exit-this-page {
    display: none;
  }
}

.govuk-exit-this-page-overlay {
  z-index: 9999;
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.govuk-exit-this-page-hide-content * {
  display: none !important;
}

.govuk-exit-this-page-hide-content .govuk-exit-this-page-overlay {
  display: block !important;
}

.govuk-file-upload {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  max-width: 100%;
  margin-left: -5px;
  padding: 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-file-upload {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-file-upload {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-file-upload {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-file-upload::-webkit-file-upload-button {
  -webkit-appearance: button;
  color: inherit;
  font: inherit;
}

.govuk-file-upload:focus {
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 4px #0b0c0c;
}

.govuk-file-upload:focus-within {
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 4px #0b0c0c;
}

.govuk-file-upload:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.govuk-footer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  background: #f3f2f1;
  border-top: 1px solid #b1b4b6;
  padding-top: 25px;
  padding-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .govuk-footer {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-footer {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-footer {
    font-size: 14pt;
    line-height: 1.2;
  }
}

@media (min-width: 40.0625em) {
  .govuk-footer {
    padding-top: 40px;
    padding-bottom: 25px;
  }
}

.govuk-footer__link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  text-decoration: underline;
}

@media print {
  .govuk-footer__link {
    font-family: sans-serif;
  }
}

.govuk-footer__link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-footer__link:link, .govuk-footer__link:visited {
  color: #0b0c0c;
}

@media print {
  .govuk-footer__link:link, .govuk-footer__link:visited {
    color: #000;
  }
}

.govuk-footer__link:hover {
  color: rgba(11, 12, 12, .99);
}

.govuk-footer__link:active, .govuk-footer__link:focus {
  color: #0b0c0c;
}

@media print {
  .govuk-footer__link:active, .govuk-footer__link:focus {
    color: #000;
  }
}

.govuk-footer__section-break {
  border: 0;
  border-bottom: 1px solid #b1b4b6;
  margin: 0 0 30px;
}

@media (min-width: 40.0625em) {
  .govuk-footer__section-break {
    margin-bottom: 50px;
  }
}

.govuk-footer__meta {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.govuk-footer__meta-item {
  margin-bottom: 25px;
  margin-left: 15px;
  margin-right: 15px;
}

.govuk-footer__meta-item--grow {
  flex: 1;
}

@media (max-width: 40.0525em) {
  .govuk-footer__meta-item--grow {
    flex-basis: 320px;
  }
}

.govuk-footer__licence-logo {
  vertical-align: top;
  forced-color-adjust: auto;
  margin-right: 10px;
  display: inline-block;
}

@media (max-width: 48.0525em) {
  .govuk-footer__licence-logo {
    margin-bottom: 15px;
  }
}

.govuk-footer__licence-description {
  display: inline-block;
}

.govuk-footer__copyright-logo {
  text-align: center;
  white-space: nowrap;
  background-image: url("govuk-crest.3b1e5430.png");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 125px 102px;
  min-width: 125px;
  padding-top: 112px;
  display: inline-block;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .govuk-footer__copyright-logo {
    background-image: url("govuk-crest-2x.81d2e56a.png");
  }
}

.govuk-footer__inline-list {
  margin-top: 0;
  margin-bottom: 15px;
  padding: 0;
}

.govuk-footer__meta-custom {
  margin-bottom: 20px;
}

.govuk-footer__inline-list-item {
  margin-bottom: 5px;
  margin-right: 15px;
  display: inline-block;
}

.govuk-footer__heading {
  border-bottom: 1px solid #b1b4b6;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

@media (max-width: 40.0525em) {
  .govuk-footer__heading {
    padding-bottom: 10px;
  }
}

.govuk-footer__navigation {
  margin-left: -15px;
  margin-right: -15px;
}

.govuk-footer__navigation:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-footer__section {
  vertical-align: top;
  margin-bottom: 30px;
  display: inline-block;
}

.govuk-footer__list {
  -webkit-column-gap: 30px;
  column-gap: 30px;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 48.0625em) {
  .govuk-footer__list--columns-2 {
    -webkit-column-count: 2;
    column-count: 2;
  }

  .govuk-footer__list--columns-3 {
    -webkit-column-count: 3;
    column-count: 3;
  }
}

.govuk-footer__list-item {
  margin-bottom: 15px;
}

@media (min-width: 40.0625em) {
  .govuk-footer__list-item {
    margin-bottom: 20px;
  }
}

.govuk-footer__list-item:last-child {
  margin-bottom: 0;
}

.govuk-header {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #0b0c0c;
  border-bottom: 10px solid #fff;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .govuk-header {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-header {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-header {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-header__container--full-width {
  border-color: #1d70b8;
  padding: 0 15px;
}

.govuk-header__container--full-width .govuk-header__menu-button {
  right: 15px;
}

.govuk-header__container {
  border-bottom: 10px solid #1d70b8;
  margin-bottom: -10px;
  padding-top: 10px;
  position: relative;
}

.govuk-header__container:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-header__logotype {
  margin-right: 5px;
  display: inline-block;
}

@media (forced-colors: active) {
  .govuk-header__logotype {
    forced-color-adjust: none;
    color: linktext;
  }
}

.govuk-header__logotype:last-child {
  margin-right: 0;
}

.govuk-header__logotype-crown {
  fill: currentColor;
  vertical-align: top;
  margin-right: 1px;
  position: relative;
  top: -1px;
}

.govuk-header__logotype-crown[width="32"] {
  margin-right: 2px;
  top: -3px;
}

.govuk-header__logotype-crown-fallback-image {
  vertical-align: bottom;
  border: 0;
  width: 36px;
  height: 32px;
}

.govuk-header__logotype-crown-fallback-image[width="32"] {
  width: 32px;
  height: 30px;
}

.govuk-header__product-name {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1;
  display: inline-table;
}

@media print {
  .govuk-header__product-name {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-header__product-name {
    font-size: 1.5rem;
    line-height: 1;
  }
}

@media print {
  .govuk-header__product-name {
    font-size: 18pt;
    line-height: 1;
  }
}

.govuk-header__link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  text-decoration: none;
}

@media print {
  .govuk-header__link {
    font-family: sans-serif;
  }
}

.govuk-header__link:link, .govuk-header__link:visited {
  color: #fff;
}

.govuk-header__link:hover, .govuk-header__link:active {
  color: rgba(255, 255, 255, .99);
}

.govuk-header__link:focus {
  color: #0b0c0c;
}

.govuk-header__link:hover {
  text-underline-offset: .1578em;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}

.govuk-header__link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-header__link--homepage {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

@media print {
  .govuk-header__link--homepage {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-header__link--homepage {
    display: inline;
  }

  .govuk-header__link--homepage:focus {
    box-shadow: 0 0 #fd0;
  }
}

.govuk-header__link--homepage:link, .govuk-header__link--homepage:visited {
  text-decoration: none;
}

.govuk-header__link--homepage:hover, .govuk-header__link--homepage:active {
  border-bottom: 3px solid;
  margin-bottom: -3px;
}

.govuk-header__link--homepage:focus {
  border-bottom: 0;
  margin-bottom: 0;
}

.govuk-header__service-name, .govuk-header__link--service-name {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11111;
  display: inline-block;
}

@media print {
  .govuk-header__service-name, .govuk-header__link--service-name {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-header__service-name, .govuk-header__link--service-name {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-header__service-name, .govuk-header__link--service-name {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-header__logo, .govuk-header__content {
  box-sizing: border-box;
}

.govuk-header__logo {
  margin-bottom: 10px;
  padding-right: 50px;
}

@media (min-width: 48.0625em) {
  .govuk-header__logo {
    float: left;
    vertical-align: top;
    width: 33.33%;
    padding-right: 15px;
  }

  .govuk-header__content {
    float: left;
    width: 66.66%;
    padding-left: 15px;
  }
}

.govuk-header__menu-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
  display: none;
  position: absolute;
  top: 20px;
  right: 0;
}

@media print {
  .govuk-header__menu-button {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-header__menu-button {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-header__menu-button {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-header__menu-button:hover {
  text-underline-offset: .1578em;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}

.govuk-header__menu-button:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-header__menu-button:after {
  -webkit-clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  border: 5px solid rgba(0, 0, 0, 0);
  border-width: 8.66px 5px 0;
  border-top-color: inherit;
  content: "";
  width: 0;
  height: 0;
  margin-left: 5px;
  display: inline-block;
}

.govuk-header__menu-button[aria-expanded="true"]:after {
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  border: 5px solid rgba(0, 0, 0, 0);
  border-width: 0 5px 8.66px;
  border-bottom-color: inherit;
  width: 0;
  height: 0;
  display: inline-block;
}

@media (min-width: 40.0625em) {
  .govuk-header__menu-button {
    top: 15px;
  }
}

.js-enabled .govuk-header__menu-button {
  display: block;
}

.govuk-header__menu-button[hidden], .js-enabled .govuk-header__menu-button[hidden] {
  display: none;
}

@media (min-width: 48.0625em) {
  .govuk-header__navigation {
    margin-bottom: 10px;
  }
}

.govuk-header__navigation-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.govuk-header__navigation-list[hidden] {
  display: none;
}

@media (min-width: 48.0625em) {
  .govuk-header__navigation--end {
    text-align: right;
    margin: 0;
    padding: 5px 0;
  }
}

.govuk-header__navigation--no-service-name {
  padding-top: 40px;
}

.govuk-header__navigation-item {
  border-bottom: 1px solid #2e3133;
  padding: 10px 0;
}

@media (min-width: 48.0625em) {
  .govuk-header__navigation-item {
    border: 0;
    margin-right: 15px;
    padding: 5px 0;
    display: inline-block;
  }
}

.govuk-header__navigation-item a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.14286;
}

@media print {
  .govuk-header__navigation-item a {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-header__navigation-item a {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-header__navigation-item a {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-header__navigation-item--active a:link, .govuk-header__navigation-item--active a:hover, .govuk-header__navigation-item--active a:visited {
  color: #1d8feb;
}

@media print {
  .govuk-header__navigation-item--active a {
    color: #1d70b8;
  }
}

.govuk-header__navigation-item--active a:focus {
  color: #0b0c0c;
}

.govuk-header__navigation-item:last-child {
  border-bottom: 0;
  margin-right: 0;
}

@media print {
  .govuk-header {
    color: #0b0c0c;
    background: none;
    border-bottom-width: 0;
  }

  .govuk-header__logotype-crown-fallback-image {
    display: none;
  }

  .govuk-header__link:link, .govuk-header__link:visited {
    color: #0b0c0c;
  }

  .govuk-header__link:after {
    display: none;
  }
}

.govuk-inset-text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  clear: both;
  border-left: 10px solid #b1b4b6;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-inset-text {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-inset-text {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-inset-text {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-inset-text {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.govuk-inset-text > :first-child {
  margin-top: 0;
}

.govuk-inset-text > :only-child, .govuk-inset-text > :last-child {
  margin-bottom: 0;
}

.govuk-notification-banner {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d70b8;
  border: 5px solid #1d70b8;
  margin-bottom: 30px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-notification-banner {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-notification-banner {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-notification-banner {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-notification-banner {
    margin-bottom: 50px;
  }
}

.govuk-notification-banner:focus {
  outline: 3px solid #fd0;
}

.govuk-notification-banner__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  padding: 2px 15px 5px;
}

@media (min-width: 40.0625em) {
  .govuk-notification-banner__header {
    padding: 2px 20px 5px;
  }
}

.govuk-notification-banner__title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
}

@media print {
  .govuk-notification-banner__title {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-notification-banner__title {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-notification-banner__title {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-notification-banner__content {
  color: #0b0c0c;
  background-color: #fff;
  padding: 15px;
}

@media print {
  .govuk-notification-banner__content {
    color: #000;
  }
}

@media (min-width: 40.0625em) {
  .govuk-notification-banner__content {
    padding: 20px;
  }
}

.govuk-notification-banner__content > * {
  box-sizing: border-box;
  max-width: 765px;
}

.govuk-notification-banner__content > :last-child {
  margin-bottom: 0;
}

.govuk-notification-banner__heading {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 15px;
  padding: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11111;
}

@media print {
  .govuk-notification-banner__heading {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-notification-banner__heading {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-notification-banner__heading {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-notification-banner__link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  text-decoration: underline;
}

@media print {
  .govuk-notification-banner__link {
    font-family: sans-serif;
  }
}

.govuk-notification-banner__link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-notification-banner__link:link, .govuk-notification-banner__link:visited {
  color: #1d70b8;
}

.govuk-notification-banner__link:hover {
  color: #003078;
}

.govuk-notification-banner__link:active, .govuk-notification-banner__link:focus {
  color: #0b0c0c;
}

.govuk-notification-banner--success {
  background-color: #00703c;
  border-color: #00703c;
}

.govuk-notification-banner--success .govuk-notification-banner__link:link, .govuk-notification-banner--success .govuk-notification-banner__link:visited {
  color: #00703c;
}

.govuk-notification-banner--success .govuk-notification-banner__link:hover {
  color: #004e2a;
}

.govuk-notification-banner--success .govuk-notification-banner__link:active {
  color: #00703c;
}

.govuk-notification-banner--success .govuk-notification-banner__link:focus {
  color: #0b0c0c;
}

.govuk-pagination {
  flex-flow: column wrap;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

@media (min-width: 40.0625em) {
  .govuk-pagination {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 30px;
  }
}

.govuk-pagination__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.govuk-pagination__item, .govuk-pagination__next, .govuk-pagination__prev {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  float: left;
  min-width: 45px;
  min-height: 45px;
  padding: 10px 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  position: relative;
}

@media print {
  .govuk-pagination__item, .govuk-pagination__next, .govuk-pagination__prev {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-pagination__item, .govuk-pagination__next, .govuk-pagination__prev {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-pagination__item, .govuk-pagination__next, .govuk-pagination__prev {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-pagination__item:hover, .govuk-pagination__next:hover, .govuk-pagination__prev:hover {
  background-color: #f3f2f1;
}

.govuk-pagination__item {
  text-align: center;
  display: none;
}

@media (min-width: 40.0625em) {
  .govuk-pagination__item {
    display: block;
  }
}

.govuk-pagination__prev, .govuk-pagination__next {
  font-weight: 700;
}

.govuk-pagination__prev .govuk-pagination__link, .govuk-pagination__next .govuk-pagination__link {
  align-items: center;
  display: flex;
}

.govuk-pagination__prev {
  padding-left: 0;
}

.govuk-pagination__next {
  padding-right: 0;
}

.govuk-pagination__item--current, .govuk-pagination__item--ellipses, .govuk-pagination__item:first-child, .govuk-pagination__item:last-child {
  display: block;
}

.govuk-pagination__item--current {
  background-color: #1d70b8;
  outline: 1px solid rgba(0, 0, 0, 0);
  font-weight: 700;
}

.govuk-pagination__item--current:hover {
  background-color: #1d70b8;
}

.govuk-pagination__item--current .govuk-pagination__link:link, .govuk-pagination__item--current .govuk-pagination__link:visited {
  color: #fff;
}

.govuk-pagination__item--current .govuk-pagination__link:hover, .govuk-pagination__item--current .govuk-pagination__link:active {
  color: rgba(255, 255, 255, .99);
}

.govuk-pagination__item--current .govuk-pagination__link:focus {
  color: #0b0c0c;
}

.govuk-pagination__item--ellipses {
  color: #505a5f;
  font-weight: 700;
}

.govuk-pagination__item--ellipses:hover {
  background-color: rgba(0, 0, 0, 0);
}

.govuk-pagination__link {
  min-width: 15px;
  display: block;
}

@media screen {
  .govuk-pagination__link:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.govuk-pagination__link:hover .govuk-pagination__link-title--decorated, .govuk-pagination__link:active .govuk-pagination__link-title--decorated {
  text-decoration: underline;
}

.govuk-pagination__link:focus .govuk-pagination__icon {
  color: #0b0c0c;
}

.govuk-pagination__link:focus .govuk-pagination__link-label, .govuk-pagination__link:focus .govuk-pagination__link-title--decorated {
  text-decoration: none;
}

.govuk-pagination__link-label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 30px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: underline;
  display: inline-block;
}

@media print {
  .govuk-pagination__link-label {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-pagination__link-label {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-pagination__link-label {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-pagination__icon {
  color: #505a5f;
  fill: currentColor;
  forced-color-adjust: auto;
  width: .9375rem;
  height: .8125rem;
}

.govuk-pagination__icon--prev {
  margin-right: 15px;
}

.govuk-pagination__icon--next {
  margin-left: 15px;
}

.govuk-pagination--block {
  display: block;
}

.govuk-pagination--block .govuk-pagination__item {
  float: none;
  padding: 15px;
}

.govuk-pagination--block .govuk-pagination__next, .govuk-pagination--block .govuk-pagination__prev {
  float: none;
  padding-left: 0;
}

.govuk-pagination--block .govuk-pagination__next {
  padding-right: 15px;
}

.govuk-pagination--block .govuk-pagination__next .govuk-pagination__icon {
  margin-left: 0;
}

.govuk-pagination--block .govuk-pagination__prev + .govuk-pagination__next {
  border-top: 1px solid #b1b4b6;
}

.govuk-pagination--block .govuk-pagination__link, .govuk-pagination--block .govuk-pagination__link-title {
  display: inline;
}

.govuk-pagination--block .govuk-pagination__link-title:after {
  content: "";
  display: block;
}

.govuk-pagination--block .govuk-pagination__link {
  text-align: left;
}

.govuk-pagination--block .govuk-pagination__link:focus .govuk-pagination__link-label {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-pagination--block .govuk-pagination__link:not(:focus) {
  text-decoration: none;
}

.govuk-pagination--block .govuk-pagination__icon {
  margin-right: 10px;
}

.govuk-panel {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-align: center;
  border: 5px solid rgba(0, 0, 0, 0);
  margin-bottom: 15px;
  padding: 35px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-panel {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-panel {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-panel {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (max-width: 40.0525em) {
  .govuk-panel {
    overflow-wrap: break-word;
    word-wrap: break-word;
    padding: 10px;
  }
}

.govuk-panel--confirmation {
  color: #fff;
  background: #00703c;
}

@media print {
  .govuk-panel--confirmation {
    color: #000;
    background: none;
    border-color: currentColor;
  }
}

.govuk-panel__title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 30px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.09375;
}

@media print {
  .govuk-panel__title {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-panel__title {
    font-size: 3rem;
    line-height: 1.04167;
  }
}

@media print {
  .govuk-panel__title {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-panel__title:last-child {
  margin-bottom: 0;
}

.govuk-panel__body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.04167;
}

@media print {
  .govuk-panel__body {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-panel__body {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-panel__body {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.govuk-tag, .lite-mobile-tag {
  outline-offset: -2px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d70b8;
  outline: 2px solid rgba(0, 0, 0, 0);
  padding: 5px 8px 4px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

@media print {
  .govuk-tag, .lite-mobile-tag {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-tag, .lite-mobile-tag {
    font-size: 1rem;
    line-height: 1;
  }
}

@media print {
  .govuk-tag, .lite-mobile-tag {
    font-size: 14pt;
    line-height: 1;
  }
}

.govuk-tag--grey {
  color: #383f43;
  background: #eeefef;
}

.govuk-tag--purple {
  color: #3d2375;
  background: #dbd5e9;
}

.govuk-tag--turquoise {
  color: #10403c;
  background: #bfe3e0;
}

.govuk-tag--blue {
  color: #144e81;
  background: #d2e2f1;
}

.govuk-tag--yellow {
  color: #594d00;
  background: #fff7bf;
}

.govuk-tag--orange {
  color: #6e3619;
  background: #fcd6c3;
}

.govuk-tag--red {
  color: #942514;
  background: #f6d7d2;
}

.govuk-tag--pink {
  color: #80224d;
  background: #f7d7e6;
}

.govuk-tag--green {
  color: #005a30;
  background: #cce2d8;
}

.govuk-phase-banner {
  border-bottom: 1px solid #b1b4b6;
  padding-top: 10px;
  padding-bottom: 10px;
}

.govuk-phase-banner__content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
  display: table;
}

@media print {
  .govuk-phase-banner__content {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-phase-banner__content {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-phase-banner__content {
    color: #000;
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-phase-banner__content__tag {
  margin-right: 10px;
}

.govuk-phase-banner__text {
  vertical-align: middle;
  display: table-cell;
}

.govuk-tabs {
  margin-top: 5px;
  margin-bottom: 20px;
}

@media (min-width: 40.0625em) {
  .govuk-tabs {
    margin-bottom: 30px;
  }
}

.govuk-tabs__title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-bottom: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-tabs__title {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-tabs__title {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-tabs__title {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-tabs__list {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

@media (min-width: 40.0625em) {
  .govuk-tabs__list {
    margin-bottom: 30px;
  }
}

.govuk-tabs__list-item {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 25px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-tabs__list-item {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-tabs__list-item {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-tabs__list-item {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-tabs__list-item:before {
  color: #0b0c0c;
  content: "—";
  margin-left: -25px;
  padding-right: 5px;
}

@media print {
  .govuk-tabs__list-item:before {
    color: #000;
  }
}

.govuk-tabs__tab {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 10px;
  font-family: GDS Transport, arial, sans-serif;
  text-decoration: underline;
  display: inline-block;
}

@media print {
  .govuk-tabs__tab {
    font-family: sans-serif;
  }
}

.govuk-tabs__tab:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-tabs__tab:link {
  color: #1d70b8;
}

.govuk-tabs__tab:visited {
  color: #4c2c92;
}

.govuk-tabs__tab:hover {
  color: #003078;
}

.govuk-tabs__tab:active, .govuk-tabs__tab:focus {
  color: #0b0c0c;
}

.govuk-tabs__panel {
  margin-bottom: 30px;
}

@media (min-width: 40.0625em) {
  .govuk-tabs__panel {
    margin-bottom: 50px;
  }

  .js-enabled .govuk-tabs__list {
    border-bottom: 1px solid #b1b4b6;
    margin-bottom: 0;
  }

  .js-enabled .govuk-tabs__list:after {
    content: "";
    clear: both;
    display: block;
  }

  .js-enabled .govuk-tabs__title {
    display: none;
  }

  .js-enabled .govuk-tabs__list-item {
    float: left;
    text-align: center;
    background-color: #f3f2f1;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 5px;
    padding: 10px 20px;
    position: relative;
  }

  .js-enabled .govuk-tabs__list-item:before {
    content: none;
  }

  .js-enabled .govuk-tabs__list-item--selected {
    background-color: #fff;
    border: 1px solid #b1b4b6;
    border-bottom: 0;
    margin-top: -5px;
    margin-bottom: -1px;
    padding: 14px 19px 16px;
    position: relative;
  }

  .js-enabled .govuk-tabs__list-item--selected .govuk-tabs__tab {
    text-decoration: none;
  }

  .js-enabled .govuk-tabs__tab {
    margin-bottom: 0;
  }

  .js-enabled .govuk-tabs__tab:link, .js-enabled .govuk-tabs__tab:visited {
    color: #0b0c0c;
  }
}

@media print and (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__tab:link, .js-enabled .govuk-tabs__tab:visited {
    color: #000;
  }
}

@media (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__tab:hover {
    color: rgba(11, 12, 12, .99);
  }

  .js-enabled .govuk-tabs__tab:active, .js-enabled .govuk-tabs__tab:focus {
    color: #0b0c0c;
  }
}

@media print and (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__tab:active, .js-enabled .govuk-tabs__tab:focus {
    color: #000;
  }
}

@media (min-width: 40.0625em) {
  .js-enabled .govuk-tabs__tab:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .js-enabled .govuk-tabs__panel {
    border: 1px solid #b1b4b6;
    border-top: 0;
    margin-bottom: 0;
    padding: 30px 20px;
  }

  .js-enabled .govuk-tabs__panel > :last-child {
    margin-bottom: 0;
  }

  .js-enabled .govuk-tabs__panel--hidden {
    display: none;
  }
}

.govuk-radios__item {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  clear: left;
  min-height: 40px;
  margin-bottom: 10px;
  padding-left: 40px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
  position: relative;
}

@media print {
  .govuk-radios__item {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-radios__item {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-radios__item {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-radios__item:last-child, .govuk-radios__item:last-of-type {
  margin-bottom: 0;
}

.govuk-radios__input {
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  width: 44px;
  height: 44px;
  margin: 0;
  position: absolute;
  top: -2px;
  left: -2px;
}

.govuk-radios__label {
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  margin-bottom: 0;
  padding: 8px 15px 5px;
  display: inline-block;
}

.govuk-radios__label:before {
  content: "";
  box-sizing: border-box;
  background: none;
  border: 2px solid;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.govuk-radios__label:after {
  content: "";
  opacity: 0;
  background: currentColor;
  border: 10px solid;
  border-radius: 50%;
  width: 0;
  height: 0;
  position: absolute;
  top: 10px;
  left: 10px;
}

.govuk-radios__hint {
  padding-left: 15px;
  padding-right: 15px;
  display: block;
}

.govuk-radios__input:focus + .govuk-radios__label:before {
  outline-offset: 1px;
  border-width: 4px;
  outline: 3px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 4px #fd0;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .govuk-radios__input:focus + .govuk-radios__label:before {
    outline-color: Highlight;
  }
}

.govuk-radios__input:checked + .govuk-radios__label:after {
  opacity: 1;
}

.govuk-radios__input:disabled, .govuk-radios__input:disabled + .govuk-radios__label {
  cursor: not-allowed;
}

.govuk-radios__input:disabled + .govuk-radios__label, .govuk-radios__input:disabled ~ .govuk-hint, .govuk-radios__input:disabled ~ .lite-filters__hint-text, .govuk-radios__input:disabled ~ .lite-file-upload__or-label {
  opacity: .5;
}

@media (min-width: 40.0625em) {
  .govuk-radios--inline:after {
    content: "";
    clear: both;
    display: block;
  }

  .govuk-radios--inline .govuk-radios__item {
    float: left;
    clear: none;
    margin-right: 20px;
  }
}

.govuk-radios__divider {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  text-align: center;
  width: 40px;
  margin-bottom: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-radios__divider {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-radios__divider {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-radios__divider {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-radios__conditional {
  border-left: 4px solid #b1b4b6;
  margin-bottom: 15px;
  margin-left: 18px;
  padding-left: 33px;
}

@media (min-width: 40.0625em) {
  .govuk-radios__conditional {
    margin-bottom: 20px;
  }
}

.js-enabled .govuk-radios__conditional--hidden {
  display: none;
}

.govuk-radios__conditional > :last-child {
  margin-bottom: 0;
}

.govuk-radios--small .govuk-radios__item, .govuk-table__cell--radio .govuk-radios__item {
  float: left;
  min-height: 0;
  margin-bottom: 0;
  padding-left: 34px;
}

.govuk-radios--small .govuk-radios__item:after, .govuk-table__cell--radio .govuk-radios__item:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-radios--small .govuk-radios__input, .govuk-table__cell--radio .govuk-radios__input {
  left: -10px;
}

.govuk-radios--small .govuk-radios__label, .govuk-table__cell--radio .govuk-radios__label {
  float: left;
  margin-top: -2px;
  padding: 13px 15px 13px 1px;
}

@media (min-width: 40.0625em) {
  .govuk-radios--small .govuk-radios__label, .govuk-table__cell--radio .govuk-radios__label {
    padding: 11px 15px 10px 1px;
  }
}

.govuk-radios--small .govuk-radios__label:before, .govuk-table__cell--radio .govuk-radios__label:before {
  width: 24px;
  height: 24px;
  top: 8px;
}

.govuk-radios--small .govuk-radios__label:after, .govuk-table__cell--radio .govuk-radios__label:after {
  border-width: 5px;
  top: 15px;
  left: 7px;
}

.govuk-radios--small .govuk-radios__hint, .govuk-table__cell--radio .govuk-radios__hint {
  clear: both;
  pointer-events: none;
  padding: 0;
}

.govuk-radios--small .govuk-radios__conditional, .govuk-table__cell--radio .govuk-radios__conditional {
  clear: both;
  margin-left: 10px;
  padding-left: 20px;
}

.govuk-radios--small .govuk-radios__divider, .govuk-table__cell--radio .govuk-radios__divider {
  width: 24px;
  margin-bottom: 5px;
}

.govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:not(:disabled) + .govuk-radios__label:before, .govuk-table__cell--radio .govuk-radios__item:hover .govuk-radios__input:not(:disabled) + .govuk-radios__label:before {
  box-shadow: 0 0 0 10px #b1b4b6;
}

.govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before, .govuk-table__cell--radio .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before {
  box-shadow: 0 0 0 4px #fd0, 0 0 0 10px #b1b4b6;
}

@media (hover: none), (pointer: coarse) {
  .govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:not(:disabled) + .govuk-radios__label:before, .govuk-table__cell--radio .govuk-radios__item:hover .govuk-radios__input:not(:disabled) + .govuk-radios__label:before {
    box-shadow: initial;
  }

  .govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before, .govuk-table__cell--radio .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before {
    box-shadow: 0 0 0 4px #fd0;
  }
}

.govuk-select {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #0b0c0c;
  background-color: #fff;
  border: 2px solid #0b0c0c;
  min-width: 11.5em;
  max-width: 100%;
  height: 2.5rem;
  padding: 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-select {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-select {
    font-size: 1.1875rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-select {
    font-size: 14pt;
    line-height: 1.25;
  }
}

.govuk-select:focus {
  outline-offset: 0;
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 2px;
}

.govuk-select:disabled {
  opacity: .5;
  color: inherit;
  cursor: not-allowed;
}

.govuk-select option:active, .govuk-select option:checked {
  color: #fff;
  background-color: #1d70b8;
}

.govuk-select:focus::-ms-value {
  color: #fff;
  background-color: #1d70b8;
}

.govuk-select--error {
  border-color: #d4351c;
}

.govuk-select--error:focus {
  border-color: #0b0c0c;
}

.govuk-skip-link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  line-height: 1.14286;
  text-decoration: underline;
  display: block;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.govuk-skip-link:active, .govuk-skip-link:focus {
  margin: inherit !important;
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  white-space: inherit !important;
  width: auto !important;
  height: auto !important;
  position: static !important;
  overflow: visible !important;
}

@media print {
  .govuk-skip-link {
    font-family: sans-serif;
  }
}

.govuk-skip-link:link, .govuk-skip-link:visited {
  color: #0b0c0c;
}

@media print {
  .govuk-skip-link:link, .govuk-skip-link:visited {
    color: #000;
  }
}

.govuk-skip-link:hover {
  color: rgba(11, 12, 12, .99);
}

.govuk-skip-link:active, .govuk-skip-link:focus {
  color: #0b0c0c;
}

@media print {
  .govuk-skip-link:active, .govuk-skip-link:focus {
    color: #000;
  }
}

@media (min-width: 40.0625em) {
  .govuk-skip-link {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-skip-link {
    font-size: 14pt;
    line-height: 1.2;
  }
}

@supports (padding: max(calc(0px))) {
  .govuk-skip-link {
    padding-right: max(15px, calc(15px + env(safe-area-inset-right)));
    padding-left: max(15px, calc(15px + env(safe-area-inset-left)));
  }
}

.govuk-skip-link:focus {
  outline-offset: 0;
  background-color: #fd0;
  outline: 3px solid #fd0;
}

.govuk-skip-link-focused-element:focus {
  outline: none;
}

.govuk-summary-list {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin: 0 0 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-summary-list {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-summary-list {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-summary-list {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-summary-list {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 30px;
    display: table;
  }
}

.govuk-summary-list__row {
  border-bottom: 1px solid #b1b4b6;
}

@media (max-width: 40.0525em) {
  .govuk-summary-list__row {
    margin-bottom: 15px;
  }
}

@media (min-width: 40.0625em) {
  .govuk-summary-list__row {
    display: table-row;
  }
}

.govuk-summary-list__row:not(.govuk-summary-list__row--no-actions) > :last-child {
  padding-right: 0;
}

@media (min-width: 40.0625em) {
  .govuk-summary-list__row--no-actions:after {
    content: "";
    width: 20%;
    display: table-cell;
  }
}

.govuk-summary-list__key, .govuk-summary-list__value, .govuk-summary-list__actions {
  margin: 0;
}

@media (min-width: 40.0625em) {
  .govuk-summary-list__key, .govuk-summary-list__value, .govuk-summary-list__actions {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    display: table-cell;
  }
}

.govuk-summary-list__actions {
  margin-bottom: 15px;
}

@media (min-width: 40.0625em) {
  .govuk-summary-list__actions {
    text-align: right;
    width: 20%;
    padding-right: 0;
  }
}

.govuk-summary-list__key, .govuk-summary-list__value {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.govuk-summary-list__key {
  margin-bottom: 5px;
  font-weight: 700;
}

@media (min-width: 40.0625em) {
  .govuk-summary-list__key {
    width: 30%;
  }
}

@media (max-width: 40.0525em) {
  .govuk-summary-list__value {
    margin-bottom: 15px;
  }
}

.govuk-summary-list__value > p {
  margin-bottom: 10px;
}

.govuk-summary-list__value > :last-child {
  margin-bottom: 0;
}

.govuk-summary-list__actions-list {
  width: 100%;
  margin: 0;
  padding: 0;
}

.govuk-summary-list__actions-list-item {
  display: inline-block;
}

@media (max-width: 40.0525em) {
  .govuk-summary-list__actions-list-item {
    margin-right: 10px;
    padding-right: 10px;
  }

  .govuk-summary-list__actions-list-item:not(:last-child) {
    border-right: 1px solid #b1b4b6;
  }

  .govuk-summary-list__actions-list-item:last-child {
    border: 0;
    margin-right: 0;
    padding-right: 0;
  }
}

@media (min-width: 40.0625em) {
  .govuk-summary-list__actions-list-item {
    margin-left: 10px;
    padding-left: 10px;
  }

  .govuk-summary-list__actions-list-item:not(:first-child) {
    border-left: 1px solid #b1b4b6;
  }

  .govuk-summary-list__actions-list-item:first-child {
    border: 0;
    margin-left: 0;
    padding-left: 0;
  }
}

.govuk-summary-list--no-border .govuk-summary-list__row {
  border: 0;
}

@media (min-width: 40.0625em) {
  .govuk-summary-list--no-border .govuk-summary-list__key, .govuk-summary-list--no-border .govuk-summary-list__value, .govuk-summary-list--no-border .govuk-summary-list__actions {
    padding-bottom: 11px;
  }
}

.govuk-summary-list__row--no-border {
  border: 0;
}

@media (min-width: 40.0625em) {
  .govuk-summary-list__row--no-border .govuk-summary-list__key, .govuk-summary-list__row--no-border .govuk-summary-list__value, .govuk-summary-list__row--no-border .govuk-summary-list__actions {
    padding-bottom: 11px;
  }
}

.govuk-summary-card {
  border: 1px solid #b1b4b6;
  margin-bottom: 20px;
}

@media (min-width: 40.0625em) {
  .govuk-summary-card {
    margin-bottom: 30px;
  }
}

.govuk-summary-card__title-wrapper {
  background-color: #f3f2f1;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  padding: 15px;
}

@media (min-width: 40.0625em) {
  .govuk-summary-card__title-wrapper {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 15px 20px;
    display: flex;
  }
}

.govuk-summary-card__title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 5px 20px 10px 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
}

@media print {
  .govuk-summary-card__title {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-summary-card__title {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-summary-card__title {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-summary-card__title {
    margin-bottom: 5px;
  }
}

.govuk-summary-card__actions {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex-wrap: wrap;
  row-gap: 10px;
  margin: 5px 0;
  padding: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  list-style: none;
  display: flex;
}

@media print {
  .govuk-summary-card__actions {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-summary-card__actions {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-summary-card__actions {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-summary-card__actions {
    -ms-flex-pack: right;
    text-align: right;
    justify-content: right;
  }
}

.govuk-summary-card__action {
  border-right: 1px solid #b1b4b6;
  margin: 0 10px 0 0;
  padding-right: 10px;
  display: inline;
}

@media (min-width: 40.0625em) {
  .govuk-summary-card__action {
    margin-right: 0;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .govuk-summary-card__action {
    margin-bottom: 5px;
  }
}

.govuk-summary-card__action:last-child {
  border-right: none;
  margin: 0;
  padding-right: 0;
}

@media (min-width: 40.0625em) {
  .govuk-summary-card__action:last-child {
    padding-left: 10px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .govuk-summary-card__action:last-child {
    margin-bottom: 0;
  }
}

.govuk-summary-card__content {
  padding: 15px 15px 0;
}

@media (min-width: 40.0625em) {
  .govuk-summary-card__content {
    padding: 15px 20px;
  }
}

.govuk-summary-card__content .govuk-summary-list {
  margin-bottom: 0;
}

.govuk-summary-card__content .govuk-summary-list__row:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
}

.govuk-table {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-table {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-table {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-table {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .govuk-table {
    margin-bottom: 30px;
  }
}

.govuk-table__header {
  font-weight: 700;
}

.govuk-table__header, .govuk-table__cell {
  text-align: left;
  vertical-align: top;
  border-bottom: 1px solid #b1b4b6;
  padding: 10px 20px 10px 0;
}

.govuk-table__cell--numeric {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "tnum" 1;
  font-feature-settings: "tnum" 1;
  font-family: GDS Transport, arial, sans-serif;
  font-weight: 400;
}

@media print {
  .govuk-table__cell--numeric {
    font-family: sans-serif;
  }
}

@supports (font-variant-numeric: tabular-nums) {
  .govuk-table__cell--numeric {
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums;
  }
}

.govuk-table__header--numeric, .govuk-table__cell--numeric {
  text-align: right;
}

.govuk-table__header:last-child, .govuk-table__cell:last-child {
  padding-right: 0;
}

.govuk-table__caption {
  text-align: left;
  font-weight: 700;
  display: table-caption;
}

.govuk-table__caption--xl {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.09375;
}

@media print {
  .govuk-table__caption--xl {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-table__caption--xl {
    font-size: 3rem;
    line-height: 1.04167;
  }
}

@media print {
  .govuk-table__caption--xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-table__caption--l {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.04167;
}

@media print {
  .govuk-table__caption--l {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-table__caption--l {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-table__caption--l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.govuk-table__caption--m {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11111;
}

@media print {
  .govuk-table__caption--m {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-table__caption--m {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-table__caption--m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-table__caption--s {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
}

@media print {
  .govuk-table__caption--s {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-table__caption--s {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-table__caption--s {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-warning-text {
  margin-bottom: 20px;
  padding: 10px 0;
  position: relative;
}

@media (min-width: 40.0625em) {
  .govuk-warning-text {
    margin-bottom: 30px;
  }
}

.govuk-warning-text__assistive {
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.govuk-warning-text__assistive:before, .govuk-warning-text__assistive:after {
  content: " ";
}

.govuk-warning-text__icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  forced-color-adjust: none;
  background: #0b0c0c;
  border: 3px solid #0b0c0c;
  border-radius: 50%;
  min-width: 35px;
  min-height: 35px;
  margin-top: -7px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 29px;
  display: inline-block;
  position: absolute;
  left: 0;
}

@media print {
  .govuk-warning-text__icon {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-warning-text__icon {
    margin-top: -5px;
  }
}

@media screen and (forced-colors: active) {
  .govuk-warning-text__icon {
    border-color: windowText;
    color: windowText;
    background: none;
  }
}

.govuk-warning-text__text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  padding-left: 45px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-warning-text__text {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-warning-text__text {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-warning-text__text {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-visually-hidden {
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.govuk-visually-hidden:before, .govuk-visually-hidden:after {
  content: " ";
}

.govuk-visually-hidden-focusable {
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.govuk-visually-hidden-focusable:active, .govuk-visually-hidden-focusable:focus {
  margin: inherit !important;
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  white-space: inherit !important;
  width: auto !important;
  height: auto !important;
  position: static !important;
  overflow: visible !important;
}

.govuk-\!-display-inline {
  display: inline !important;
}

.govuk-\!-display-inline-block {
  display: inline-block !important;
}

.govuk-\!-display-block {
  display: block !important;
}

.govuk-\!-display-none {
  display: none !important;
}

@media print {
  .govuk-\!-display-none-print {
    display: none !important;
  }
}

.govuk-\!-margin-0 {
  margin: 0 !important;
}

.govuk-\!-margin-top-0 {
  margin-top: 0 !important;
}

.govuk-\!-margin-right-0 {
  margin-right: 0 !important;
}

.govuk-\!-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.govuk-\!-margin-left-0 {
  margin-left: 0 !important;
}

.govuk-\!-margin-1 {
  margin: 5px !important;
}

.govuk-\!-margin-top-1 {
  margin-top: 5px !important;
}

.govuk-\!-margin-right-1 {
  margin-right: 5px !important;
}

.govuk-\!-margin-bottom-1 {
  margin-bottom: 5px !important;
}

.govuk-\!-margin-left-1 {
  margin-left: 5px !important;
}

.govuk-\!-margin-2 {
  margin: 10px !important;
}

.govuk-\!-margin-top-2 {
  margin-top: 10px !important;
}

.govuk-\!-margin-right-2 {
  margin-right: 10px !important;
}

.govuk-\!-margin-bottom-2 {
  margin-bottom: 10px !important;
}

.govuk-\!-margin-left-2 {
  margin-left: 10px !important;
}

.govuk-\!-margin-3 {
  margin: 15px !important;
}

.govuk-\!-margin-top-3 {
  margin-top: 15px !important;
}

.govuk-\!-margin-right-3 {
  margin-right: 15px !important;
}

.govuk-\!-margin-bottom-3 {
  margin-bottom: 15px !important;
}

.govuk-\!-margin-left-3 {
  margin-left: 15px !important;
}

.govuk-\!-margin-4 {
  margin: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-4 {
    margin: 20px !important;
  }
}

.govuk-\!-margin-top-4 {
  margin-top: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-4 {
    margin-top: 20px !important;
  }
}

.govuk-\!-margin-right-4 {
  margin-right: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-4 {
    margin-right: 20px !important;
  }
}

.govuk-\!-margin-bottom-4 {
  margin-bottom: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-4 {
    margin-bottom: 20px !important;
  }
}

.govuk-\!-margin-left-4 {
  margin-left: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-4 {
    margin-left: 20px !important;
  }
}

.govuk-\!-margin-5 {
  margin: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-5 {
    margin: 25px !important;
  }
}

.govuk-\!-margin-top-5 {
  margin-top: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-5 {
    margin-top: 25px !important;
  }
}

.govuk-\!-margin-right-5 {
  margin-right: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-5 {
    margin-right: 25px !important;
  }
}

.govuk-\!-margin-bottom-5 {
  margin-bottom: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-5 {
    margin-bottom: 25px !important;
  }
}

.govuk-\!-margin-left-5 {
  margin-left: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-5 {
    margin-left: 25px !important;
  }
}

.govuk-\!-margin-6 {
  margin: 20px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-6 {
    margin: 30px !important;
  }
}

.govuk-\!-margin-top-6 {
  margin-top: 20px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-6 {
    margin-top: 30px !important;
  }
}

.govuk-\!-margin-right-6 {
  margin-right: 20px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-6 {
    margin-right: 30px !important;
  }
}

.govuk-\!-margin-bottom-6 {
  margin-bottom: 20px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-6 {
    margin-bottom: 30px !important;
  }
}

.govuk-\!-margin-left-6 {
  margin-left: 20px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-6 {
    margin-left: 30px !important;
  }
}

.govuk-\!-margin-7 {
  margin: 25px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-7 {
    margin: 40px !important;
  }
}

.govuk-\!-margin-top-7 {
  margin-top: 25px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-7 {
    margin-top: 40px !important;
  }
}

.govuk-\!-margin-right-7 {
  margin-right: 25px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-7 {
    margin-right: 40px !important;
  }
}

.govuk-\!-margin-bottom-7 {
  margin-bottom: 25px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-7 {
    margin-bottom: 40px !important;
  }
}

.govuk-\!-margin-left-7 {
  margin-left: 25px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-7 {
    margin-left: 40px !important;
  }
}

.govuk-\!-margin-8 {
  margin: 30px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-8 {
    margin: 50px !important;
  }
}

.govuk-\!-margin-top-8 {
  margin-top: 30px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-8 {
    margin-top: 50px !important;
  }
}

.govuk-\!-margin-right-8 {
  margin-right: 30px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-8 {
    margin-right: 50px !important;
  }
}

.govuk-\!-margin-bottom-8 {
  margin-bottom: 30px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-8 {
    margin-bottom: 50px !important;
  }
}

.govuk-\!-margin-left-8 {
  margin-left: 30px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-8 {
    margin-left: 50px !important;
  }
}

.govuk-\!-margin-9 {
  margin: 40px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-9 {
    margin: 60px !important;
  }
}

.govuk-\!-margin-top-9 {
  margin-top: 40px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-top-9 {
    margin-top: 60px !important;
  }
}

.govuk-\!-margin-right-9 {
  margin-right: 40px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-right-9 {
    margin-right: 60px !important;
  }
}

.govuk-\!-margin-bottom-9 {
  margin-bottom: 40px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-bottom-9 {
    margin-bottom: 60px !important;
  }
}

.govuk-\!-margin-left-9 {
  margin-left: 40px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-margin-left-9 {
    margin-left: 60px !important;
  }
}

.govuk-\!-padding-0 {
  padding: 0 !important;
}

.govuk-\!-padding-top-0 {
  padding-top: 0 !important;
}

.govuk-\!-padding-right-0 {
  padding-right: 0 !important;
}

.govuk-\!-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.govuk-\!-padding-left-0 {
  padding-left: 0 !important;
}

.govuk-\!-padding-1 {
  padding: 5px !important;
}

.govuk-\!-padding-top-1 {
  padding-top: 5px !important;
}

.govuk-\!-padding-right-1 {
  padding-right: 5px !important;
}

.govuk-\!-padding-bottom-1 {
  padding-bottom: 5px !important;
}

.govuk-\!-padding-left-1 {
  padding-left: 5px !important;
}

.govuk-\!-padding-2 {
  padding: 10px !important;
}

.govuk-\!-padding-top-2 {
  padding-top: 10px !important;
}

.govuk-\!-padding-right-2 {
  padding-right: 10px !important;
}

.govuk-\!-padding-bottom-2 {
  padding-bottom: 10px !important;
}

.govuk-\!-padding-left-2 {
  padding-left: 10px !important;
}

.govuk-\!-padding-3 {
  padding: 15px !important;
}

.govuk-\!-padding-top-3 {
  padding-top: 15px !important;
}

.govuk-\!-padding-right-3 {
  padding-right: 15px !important;
}

.govuk-\!-padding-bottom-3 {
  padding-bottom: 15px !important;
}

.govuk-\!-padding-left-3 {
  padding-left: 15px !important;
}

.govuk-\!-padding-4 {
  padding: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-4 {
    padding: 20px !important;
  }
}

.govuk-\!-padding-top-4 {
  padding-top: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-4 {
    padding-top: 20px !important;
  }
}

.govuk-\!-padding-right-4 {
  padding-right: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-4 {
    padding-right: 20px !important;
  }
}

.govuk-\!-padding-bottom-4 {
  padding-bottom: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-4 {
    padding-bottom: 20px !important;
  }
}

.govuk-\!-padding-left-4 {
  padding-left: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-4 {
    padding-left: 20px !important;
  }
}

.govuk-\!-padding-5 {
  padding: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-5 {
    padding: 25px !important;
  }
}

.govuk-\!-padding-top-5 {
  padding-top: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-5 {
    padding-top: 25px !important;
  }
}

.govuk-\!-padding-right-5 {
  padding-right: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-5 {
    padding-right: 25px !important;
  }
}

.govuk-\!-padding-bottom-5 {
  padding-bottom: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-5 {
    padding-bottom: 25px !important;
  }
}

.govuk-\!-padding-left-5 {
  padding-left: 15px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-5 {
    padding-left: 25px !important;
  }
}

.govuk-\!-padding-6 {
  padding: 20px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-6 {
    padding: 30px !important;
  }
}

.govuk-\!-padding-top-6 {
  padding-top: 20px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-6 {
    padding-top: 30px !important;
  }
}

.govuk-\!-padding-right-6 {
  padding-right: 20px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-6 {
    padding-right: 30px !important;
  }
}

.govuk-\!-padding-bottom-6 {
  padding-bottom: 20px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-6 {
    padding-bottom: 30px !important;
  }
}

.govuk-\!-padding-left-6 {
  padding-left: 20px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-6 {
    padding-left: 30px !important;
  }
}

.govuk-\!-padding-7 {
  padding: 25px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-7 {
    padding: 40px !important;
  }
}

.govuk-\!-padding-top-7 {
  padding-top: 25px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-7 {
    padding-top: 40px !important;
  }
}

.govuk-\!-padding-right-7 {
  padding-right: 25px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-7 {
    padding-right: 40px !important;
  }
}

.govuk-\!-padding-bottom-7 {
  padding-bottom: 25px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-7 {
    padding-bottom: 40px !important;
  }
}

.govuk-\!-padding-left-7 {
  padding-left: 25px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-7 {
    padding-left: 40px !important;
  }
}

.govuk-\!-padding-8 {
  padding: 30px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-8 {
    padding: 50px !important;
  }
}

.govuk-\!-padding-top-8 {
  padding-top: 30px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-8 {
    padding-top: 50px !important;
  }
}

.govuk-\!-padding-right-8 {
  padding-right: 30px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-8 {
    padding-right: 50px !important;
  }
}

.govuk-\!-padding-bottom-8 {
  padding-bottom: 30px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-8 {
    padding-bottom: 50px !important;
  }
}

.govuk-\!-padding-left-8 {
  padding-left: 30px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-8 {
    padding-left: 50px !important;
  }
}

.govuk-\!-padding-9 {
  padding: 40px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-9 {
    padding: 60px !important;
  }
}

.govuk-\!-padding-top-9 {
  padding-top: 40px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-top-9 {
    padding-top: 60px !important;
  }
}

.govuk-\!-padding-right-9 {
  padding-right: 40px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-right-9 {
    padding-right: 60px !important;
  }
}

.govuk-\!-padding-bottom-9 {
  padding-bottom: 40px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-bottom-9 {
    padding-bottom: 60px !important;
  }
}

.govuk-\!-padding-left-9 {
  padding-left: 40px !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-padding-left-9 {
    padding-left: 60px !important;
  }
}

.govuk-\!-margin-static-0, .govuk-\!-static-margin-0 {
  margin: 0 !important;
}

.govuk-\!-margin-top-static-0, .govuk-\!-static-margin-top-0 {
  margin-top: 0 !important;
}

.govuk-\!-margin-right-static-0, .govuk-\!-static-margin-right-0 {
  margin-right: 0 !important;
}

.govuk-\!-margin-bottom-static-0, .govuk-\!-static-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.govuk-\!-margin-left-static-0, .govuk-\!-static-margin-left-0 {
  margin-left: 0 !important;
}

.govuk-\!-margin-static-1, .govuk-\!-static-margin-1 {
  margin: 5px !important;
}

.govuk-\!-margin-top-static-1, .govuk-\!-static-margin-top-1 {
  margin-top: 5px !important;
}

.govuk-\!-margin-right-static-1, .govuk-\!-static-margin-right-1 {
  margin-right: 5px !important;
}

.govuk-\!-margin-bottom-static-1, .govuk-\!-static-margin-bottom-1 {
  margin-bottom: 5px !important;
}

.govuk-\!-margin-left-static-1, .govuk-\!-static-margin-left-1 {
  margin-left: 5px !important;
}

.govuk-\!-margin-static-2, .govuk-\!-static-margin-2 {
  margin: 10px !important;
}

.govuk-\!-margin-top-static-2, .govuk-\!-static-margin-top-2 {
  margin-top: 10px !important;
}

.govuk-\!-margin-right-static-2, .govuk-\!-static-margin-right-2 {
  margin-right: 10px !important;
}

.govuk-\!-margin-bottom-static-2, .govuk-\!-static-margin-bottom-2 {
  margin-bottom: 10px !important;
}

.govuk-\!-margin-left-static-2, .govuk-\!-static-margin-left-2 {
  margin-left: 10px !important;
}

.govuk-\!-margin-static-3, .govuk-\!-static-margin-3 {
  margin: 15px !important;
}

.govuk-\!-margin-top-static-3, .govuk-\!-static-margin-top-3 {
  margin-top: 15px !important;
}

.govuk-\!-margin-right-static-3, .govuk-\!-static-margin-right-3 {
  margin-right: 15px !important;
}

.govuk-\!-margin-bottom-static-3, .govuk-\!-static-margin-bottom-3 {
  margin-bottom: 15px !important;
}

.govuk-\!-margin-left-static-3, .govuk-\!-static-margin-left-3 {
  margin-left: 15px !important;
}

.govuk-\!-margin-static-4, .govuk-\!-static-margin-4 {
  margin: 20px !important;
}

.govuk-\!-margin-top-static-4, .govuk-\!-static-margin-top-4 {
  margin-top: 20px !important;
}

.govuk-\!-margin-right-static-4, .govuk-\!-static-margin-right-4 {
  margin-right: 20px !important;
}

.govuk-\!-margin-bottom-static-4, .govuk-\!-static-margin-bottom-4 {
  margin-bottom: 20px !important;
}

.govuk-\!-margin-left-static-4, .govuk-\!-static-margin-left-4 {
  margin-left: 20px !important;
}

.govuk-\!-margin-static-5, .govuk-\!-static-margin-5 {
  margin: 25px !important;
}

.govuk-\!-margin-top-static-5, .govuk-\!-static-margin-top-5 {
  margin-top: 25px !important;
}

.govuk-\!-margin-right-static-5, .govuk-\!-static-margin-right-5 {
  margin-right: 25px !important;
}

.govuk-\!-margin-bottom-static-5, .govuk-\!-static-margin-bottom-5 {
  margin-bottom: 25px !important;
}

.govuk-\!-margin-left-static-5, .govuk-\!-static-margin-left-5 {
  margin-left: 25px !important;
}

.govuk-\!-margin-static-6, .govuk-\!-static-margin-6 {
  margin: 30px !important;
}

.govuk-\!-margin-top-static-6, .govuk-\!-static-margin-top-6 {
  margin-top: 30px !important;
}

.govuk-\!-margin-right-static-6, .govuk-\!-static-margin-right-6 {
  margin-right: 30px !important;
}

.govuk-\!-margin-bottom-static-6, .govuk-\!-static-margin-bottom-6 {
  margin-bottom: 30px !important;
}

.govuk-\!-margin-left-static-6, .govuk-\!-static-margin-left-6 {
  margin-left: 30px !important;
}

.govuk-\!-margin-static-7, .govuk-\!-static-margin-7 {
  margin: 40px !important;
}

.govuk-\!-margin-top-static-7, .govuk-\!-static-margin-top-7 {
  margin-top: 40px !important;
}

.govuk-\!-margin-right-static-7, .govuk-\!-static-margin-right-7 {
  margin-right: 40px !important;
}

.govuk-\!-margin-bottom-static-7, .govuk-\!-static-margin-bottom-7 {
  margin-bottom: 40px !important;
}

.govuk-\!-margin-left-static-7, .govuk-\!-static-margin-left-7 {
  margin-left: 40px !important;
}

.govuk-\!-margin-static-8, .govuk-\!-static-margin-8 {
  margin: 50px !important;
}

.govuk-\!-margin-top-static-8, .govuk-\!-static-margin-top-8 {
  margin-top: 50px !important;
}

.govuk-\!-margin-right-static-8, .govuk-\!-static-margin-right-8 {
  margin-right: 50px !important;
}

.govuk-\!-margin-bottom-static-8, .govuk-\!-static-margin-bottom-8 {
  margin-bottom: 50px !important;
}

.govuk-\!-margin-left-static-8, .govuk-\!-static-margin-left-8 {
  margin-left: 50px !important;
}

.govuk-\!-margin-static-9, .govuk-\!-static-margin-9 {
  margin: 60px !important;
}

.govuk-\!-margin-top-static-9, .govuk-\!-static-margin-top-9 {
  margin-top: 60px !important;
}

.govuk-\!-margin-right-static-9, .govuk-\!-static-margin-right-9 {
  margin-right: 60px !important;
}

.govuk-\!-margin-bottom-static-9, .govuk-\!-static-margin-bottom-9 {
  margin-bottom: 60px !important;
}

.govuk-\!-margin-left-static-9, .govuk-\!-static-margin-left-9 {
  margin-left: 60px !important;
}

.govuk-\!-padding-static-0, .govuk-\!-static-padding-0 {
  padding: 0 !important;
}

.govuk-\!-padding-top-static-0, .govuk-\!-static-padding-top-0 {
  padding-top: 0 !important;
}

.govuk-\!-padding-right-static-0, .govuk-\!-static-padding-right-0 {
  padding-right: 0 !important;
}

.govuk-\!-padding-bottom-static-0, .govuk-\!-static-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.govuk-\!-padding-left-static-0, .govuk-\!-static-padding-left-0 {
  padding-left: 0 !important;
}

.govuk-\!-padding-static-1, .govuk-\!-static-padding-1 {
  padding: 5px !important;
}

.govuk-\!-padding-top-static-1, .govuk-\!-static-padding-top-1 {
  padding-top: 5px !important;
}

.govuk-\!-padding-right-static-1, .govuk-\!-static-padding-right-1 {
  padding-right: 5px !important;
}

.govuk-\!-padding-bottom-static-1, .govuk-\!-static-padding-bottom-1 {
  padding-bottom: 5px !important;
}

.govuk-\!-padding-left-static-1, .govuk-\!-static-padding-left-1 {
  padding-left: 5px !important;
}

.govuk-\!-padding-static-2, .govuk-\!-static-padding-2 {
  padding: 10px !important;
}

.govuk-\!-padding-top-static-2, .govuk-\!-static-padding-top-2 {
  padding-top: 10px !important;
}

.govuk-\!-padding-right-static-2, .govuk-\!-static-padding-right-2 {
  padding-right: 10px !important;
}

.govuk-\!-padding-bottom-static-2, .govuk-\!-static-padding-bottom-2 {
  padding-bottom: 10px !important;
}

.govuk-\!-padding-left-static-2, .govuk-\!-static-padding-left-2 {
  padding-left: 10px !important;
}

.govuk-\!-padding-static-3, .govuk-\!-static-padding-3 {
  padding: 15px !important;
}

.govuk-\!-padding-top-static-3, .govuk-\!-static-padding-top-3 {
  padding-top: 15px !important;
}

.govuk-\!-padding-right-static-3, .govuk-\!-static-padding-right-3 {
  padding-right: 15px !important;
}

.govuk-\!-padding-bottom-static-3, .govuk-\!-static-padding-bottom-3 {
  padding-bottom: 15px !important;
}

.govuk-\!-padding-left-static-3, .govuk-\!-static-padding-left-3 {
  padding-left: 15px !important;
}

.govuk-\!-padding-static-4, .govuk-\!-static-padding-4 {
  padding: 20px !important;
}

.govuk-\!-padding-top-static-4, .govuk-\!-static-padding-top-4 {
  padding-top: 20px !important;
}

.govuk-\!-padding-right-static-4, .govuk-\!-static-padding-right-4 {
  padding-right: 20px !important;
}

.govuk-\!-padding-bottom-static-4, .govuk-\!-static-padding-bottom-4 {
  padding-bottom: 20px !important;
}

.govuk-\!-padding-left-static-4, .govuk-\!-static-padding-left-4 {
  padding-left: 20px !important;
}

.govuk-\!-padding-static-5, .govuk-\!-static-padding-5 {
  padding: 25px !important;
}

.govuk-\!-padding-top-static-5, .govuk-\!-static-padding-top-5 {
  padding-top: 25px !important;
}

.govuk-\!-padding-right-static-5, .govuk-\!-static-padding-right-5 {
  padding-right: 25px !important;
}

.govuk-\!-padding-bottom-static-5, .govuk-\!-static-padding-bottom-5 {
  padding-bottom: 25px !important;
}

.govuk-\!-padding-left-static-5, .govuk-\!-static-padding-left-5 {
  padding-left: 25px !important;
}

.govuk-\!-padding-static-6, .govuk-\!-static-padding-6 {
  padding: 30px !important;
}

.govuk-\!-padding-top-static-6, .govuk-\!-static-padding-top-6 {
  padding-top: 30px !important;
}

.govuk-\!-padding-right-static-6, .govuk-\!-static-padding-right-6 {
  padding-right: 30px !important;
}

.govuk-\!-padding-bottom-static-6, .govuk-\!-static-padding-bottom-6 {
  padding-bottom: 30px !important;
}

.govuk-\!-padding-left-static-6, .govuk-\!-static-padding-left-6 {
  padding-left: 30px !important;
}

.govuk-\!-padding-static-7, .govuk-\!-static-padding-7 {
  padding: 40px !important;
}

.govuk-\!-padding-top-static-7, .govuk-\!-static-padding-top-7 {
  padding-top: 40px !important;
}

.govuk-\!-padding-right-static-7, .govuk-\!-static-padding-right-7 {
  padding-right: 40px !important;
}

.govuk-\!-padding-bottom-static-7, .govuk-\!-static-padding-bottom-7 {
  padding-bottom: 40px !important;
}

.govuk-\!-padding-left-static-7, .govuk-\!-static-padding-left-7 {
  padding-left: 40px !important;
}

.govuk-\!-padding-static-8, .govuk-\!-static-padding-8 {
  padding: 50px !important;
}

.govuk-\!-padding-top-static-8, .govuk-\!-static-padding-top-8 {
  padding-top: 50px !important;
}

.govuk-\!-padding-right-static-8, .govuk-\!-static-padding-right-8 {
  padding-right: 50px !important;
}

.govuk-\!-padding-bottom-static-8, .govuk-\!-static-padding-bottom-8 {
  padding-bottom: 50px !important;
}

.govuk-\!-padding-left-static-8, .govuk-\!-static-padding-left-8 {
  padding-left: 50px !important;
}

.govuk-\!-padding-static-9, .govuk-\!-static-padding-9 {
  padding: 60px !important;
}

.govuk-\!-padding-top-static-9, .govuk-\!-static-padding-top-9 {
  padding-top: 60px !important;
}

.govuk-\!-padding-right-static-9, .govuk-\!-static-padding-right-9 {
  padding-right: 60px !important;
}

.govuk-\!-padding-bottom-static-9, .govuk-\!-static-padding-bottom-9 {
  padding-bottom: 60px !important;
}

.govuk-\!-padding-left-static-9, .govuk-\!-static-padding-left-9 {
  padding-left: 60px !important;
}

.govuk-\!-text-align-left {
  text-align: left !important;
}

.govuk-\!-text-align-centre {
  text-align: center !important;
}

.govuk-\!-text-align-right {
  text-align: right !important;
}

.govuk-\!-font-size-80 {
  font-size: 3.3125rem !important;
  line-height: 1.03774 !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-font-size-80 {
    font-size: 5rem !important;
    line-height: 1 !important;
  }
}

@media print {
  .govuk-\!-font-size-80 {
    font-size: 53pt !important;
    line-height: 1.1 !important;
  }
}

.govuk-\!-font-size-48 {
  font-size: 2rem !important;
  line-height: 1.09375 !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-font-size-48 {
    font-size: 3rem !important;
    line-height: 1.04167 !important;
  }
}

@media print {
  .govuk-\!-font-size-48 {
    font-size: 32pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-36 {
  font-size: 1.5rem !important;
  line-height: 1.04167 !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-font-size-36 {
    font-size: 2.25rem !important;
    line-height: 1.11111 !important;
  }
}

@media print {
  .govuk-\!-font-size-36 {
    font-size: 24pt !important;
    line-height: 1.05 !important;
  }
}

.govuk-\!-font-size-27 {
  font-size: 1.125rem !important;
  line-height: 1.11111 !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-font-size-27 {
    font-size: 1.6875rem !important;
    line-height: 1.11111 !important;
  }
}

@media print {
  .govuk-\!-font-size-27 {
    font-size: 18pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-24 {
  font-size: 1.125rem !important;
  line-height: 1.11111 !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-font-size-24 {
    font-size: 1.5rem !important;
    line-height: 1.25 !important;
  }
}

@media print {
  .govuk-\!-font-size-24 {
    font-size: 18pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-19 {
  font-size: 1rem !important;
  line-height: 1.25 !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-font-size-19 {
    font-size: 1.1875rem !important;
    line-height: 1.31579 !important;
  }
}

@media print {
  .govuk-\!-font-size-19 {
    font-size: 14pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-16 {
  font-size: .875rem !important;
  line-height: 1.14286 !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-font-size-16 {
    font-size: 1rem !important;
    line-height: 1.25 !important;
  }
}

@media print {
  .govuk-\!-font-size-16 {
    font-size: 14pt !important;
    line-height: 1.2 !important;
  }
}

.govuk-\!-font-size-14 {
  font-size: .75rem !important;
  line-height: 1.25 !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-font-size-14 {
    font-size: .875rem !important;
    line-height: 1.42857 !important;
  }
}

@media print {
  .govuk-\!-font-size-14 {
    font-size: 12pt !important;
    line-height: 1.2 !important;
  }
}

.govuk-\!-font-weight-regular {
  font-weight: 400 !important;
}

.govuk-\!-font-weight-bold {
  font-weight: 700 !important;
}

.govuk-\!-width-full, .govuk-\!-width-three-quarters {
  width: 100% !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-width-three-quarters {
    width: 75% !important;
  }
}

.govuk-\!-width-two-thirds {
  width: 100% !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-width-two-thirds {
    width: 66.66% !important;
  }
}

.govuk-\!-width-one-half {
  width: 100% !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-width-one-half {
    width: 50% !important;
  }
}

.govuk-\!-width-one-third {
  width: 100% !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-width-one-third {
    width: 33.33% !important;
  }
}

.govuk-\!-width-one-quarter {
  width: 100% !important;
}

@media (min-width: 40.0625em) {
  .govuk-\!-width-one-quarter {
    width: 25% !important;
  }
}

.autocomplete__wrapper {
  position: relative;
}

.autocomplete__hint, .autocomplete__input {
  -webkit-appearance: none;
  box-sizing: border-box;
  border: 2px solid #0b0c0c;
  border-radius: 0;
  width: 100%;
  margin-bottom: 0;
}

.autocomplete__input {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
}

.autocomplete__hint {
  color: #b1b4b6;
  position: absolute;
}

.autocomplete__input--default {
  padding: 5px;
}

.autocomplete__input--focused {
  outline-offset: 0;
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 2px;
}

.autocomplete__input--show-all-values {
  cursor: pointer;
  padding: 5px 34px 5px 5px;
}

.autocomplete__dropdown-arrow-down {
  z-index: -1;
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 8px;
}

.autocomplete__menu {
  color: #0b0c0c;
  width: 100%;
  max-height: 342px;
  background-color: #fff;
  border: 2px solid #0b0c0c;
  border-top: 0;
  width: calc(100% - 4px);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.autocomplete__menu--visible {
  display: block;
}

.autocomplete__menu--hidden {
  display: none;
}

.autocomplete__menu--overlay {
  z-index: 100;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .26);
}

.autocomplete__menu--inline {
  position: relative;
}

.autocomplete__option {
  cursor: pointer;
  border-width: 1px 0;
  border-bottom-style: solid;
  border-bottom-color: #b1b4b6;
  display: block;
  position: relative;
}

.autocomplete__option > * {
  pointer-events: none;
}

.autocomplete__option:first-of-type {
  border-top-width: 0;
}

.autocomplete__option:last-of-type {
  border-bottom-width: 0;
}

.autocomplete__option--odd {
  background-color: #fafafa;
}

.autocomplete__option--focused, .autocomplete__option:hover {
  color: #fff;
  background-color: #1d70b8;
  border-color: #1d70b8;
  outline: none;
}

.autocomplete__option--no-results {
  color: #646b6f;
  cursor: not-allowed;
  background-color: #fafafa;
}

.autocomplete__hint, .autocomplete__input, .autocomplete__option {
  font-size: 16px;
  line-height: 1.25;
}

.autocomplete__hint, .autocomplete__option {
  padding: 5px;
}

@media (min-width: 641px) {
  .autocomplete__hint, .autocomplete__input, .autocomplete__option {
    font-size: 19px;
    line-height: 1.31579;
  }
}

.lite-vertical-align {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.lite-horizontal-align {
  justify-content: center;
  align-items: center;
  display: flex;
}

.lite-horizontal-align-right {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.lite-js-only {
  display: none;
}

.js-enabled .lite-js-only {
  display: inline-block;
}

.lite-horizontal-separator {
  background: #f3f2f1;
  border: none;
  height: 2px;
  margin-bottom: 20px;
  display: block;
}

@media (min-width: 40.0625em) {
  .lite-horizontal-separator {
    margin-bottom: 30px;
  }
}

.lite-buttons-row {
  margin-bottom: 30px;
}

.lite-buttons-row > * {
  margin-bottom: 0;
  margin-left: 15px;
}

.lite-buttons-row > :first-child {
  margin-left: 0 !important;
}

@media (max-width: 40.0525em) {
  .lite-buttons-row {
    width: 100%;
    margin-bottom: 15px;
  }

  .lite-buttons-row * {
    margin-bottom: 15px;
    margin-left: 0 !important;
  }

  .lite-buttons-row :last-child {
    margin-bottom: 0;
  }
}

.lite-mobile-only {
  display: none;
}

@media (max-width: 40.0525em) {
  .lite-mobile-only {
    display: block;
  }

  .lite-mobile-hide {
    display: none !important;
  }
}

@media (max-width: 1100px) {
  .lite-tablet-hide {
    display: none;
  }
}

.lite-\!-text-decoration-none {
  text-decoration: none;
}

.lite-\!-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
}

.lite-\!-text-align-right {
  text-align: right;
}

.lite-\!-position-relative {
  position: relative;
}

.lite-\!-display-inline {
  display: inline;
}

.lite-\!-no-border {
  border-bottom: 2px solid #fff !important;
}

.lite-\!-margin-top--1 {
  margin-top: -5px;
}

.lite-\!-margin-top--2 {
  margin-top: -10px;
}

.lite-\!-margin-top--3 {
  margin-top: -15px;
}

.lite-\!-margin-top--4 {
  margin-top: -20px;
}

.lite-\!-margin-top--5 {
  margin-top: -25px;
}

.lite-\!-margin-top--6 {
  margin-top: -30px;
}

.lite-\!-margin-top--7 {
  margin-top: -40px;
}

.lite-\!-margin-top--8 {
  margin-top: -50px;
}

.lite-\!-margin-top--9 {
  margin-top: -60px;
}

.lite-\!-word-break {
  word-break: break-all;
}

.lite-button--link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1d70b8;
  cursor: pointer;
  background: 0;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: underline;
}

@media print {
  .lite-button--link {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-button--link {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-button--link {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-link--definition {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-bottom: 1px dashed;
  margin: 0;
  padding: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
}

@media print {
  .lite-link--definition {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-link--definition {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-link--definition {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-link--disabled {
  pointer-events: none;
  text-decoration: none;
  color: #8eb8dc !important;
}

.lite-inline-list {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.lite-inline-list > * {
  display: inline-block;
}

.lite-heading-s {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.25;
}

@media print {
  .lite-heading-s {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-heading-s {
    font-size: .875rem;
    line-height: 1.42857;
  }
}

@media print {
  .lite-heading-s {
    font-size: 12pt;
    line-height: 1.2;
  }
}

.govuk-button--secondary-white {
  background-color: #fff;
}

.govuk-button--secondary-white:hover {
  background-color: #e7e5e3;
}

.govuk-button--secondary-white:active {
  background-color: #dbd9d6;
}

button.govuk-back-link, button.lite-back-link-button {
  color: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  text-decoration: underline;
}

.govuk-checkboxes__input:indeterminate + .govuk-checkboxes__label:after {
  opacity: 1;
  background-color: currentColor;
  border: none;
  width: 12px;
  top: 18px;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  height: 3px !important;
}

.govuk-checkboxes--inline {
  display: flex;
}

.govuk-checkboxes--inline .govuk-checkboxes__label {
  text-align: center;
  padding-left: 0;
  padding-right: 20px;
}

.govuk-checkboxes__label--error {
  color: #d4351c;
}

.govuk-checkboxes__label--error:after {
  color: #0b0c0c;
}

fieldset .govuk-fieldset__legend--xl {
  margin-bottom: 30px;
}

@media (min-width: 40.0625em) {
  fieldset .govuk-fieldset__legend--xl {
    margin-bottom: 50px;
  }
}

.lite-file-upload {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  cursor: pointer;
  color: #0b0c0c;
  border: 2px dashed #b1b4b6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 220px;
  padding: 30px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: flex;
}

@media print {
  .lite-file-upload {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-file-upload {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-file-upload {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-file-upload:hover {
  border-color: #505a5f;
}

.lite-file-upload:hover .lite-file-upload__link {
  color: #003078 !important;
}

.lite-file-upload:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  border-width: 0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.lite-file-upload:focus .lite-file-upload__link {
  color: #0b0c0c !important;
}

.lite-file-upload--drag-over {
  border-color: #fd0;
}

.lite-file-upload input {
  display: none;
}

.lite-file-upload span {
  text-align: center;
}

.lite-file-upload__link {
  color: #1d70b8;
  text-decoration: underline;
}

.lite-file-upload__or-label {
  margin: 15px 0 0;
}

.lite-file-upload__uploaded-file {
  box-sizing: border-box;
  background-color: #f3f2f1;
  width: 100%;
  margin-bottom: 30px;
  padding: 20px;
  display: block;
}

.lite-file-upload__uploaded-file .app-documents__item-preview {
  background-color: #fff;
  margin-right: 20px;
  box-shadow: 0 2px rgba(0, 0, 0, .1);
}

.lite-file-upload__uploaded-file .app-documents__item-preview svg {
  border-width: 0;
}

.lite-file-upload__uploaded-file .app-documents__item-preview:after {
  top: 13px;
}

.app-documents__item a {
  display: block;
  margin-bottom: 10px !important;
}

.app-documents__item .govuk-hint, .app-documents__item .lite-filters__hint-text, .app-documents__item .lite-file-upload__or-label {
  margin: 0;
}

.app-documents__item-preview span {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.25;
  position: absolute;
  top: 17px;
  left: 17px;
  right: 17px;
  line-height: 22px !important;
}

@media print {
  .app-documents__item-preview span {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-documents__item-preview span {
    font-size: .875rem;
    line-height: 1.42857;
  }
}

@media print {
  .app-documents__item-preview span {
    font-size: 12pt;
    line-height: 1.2;
  }
}

.govuk-form-group--error .lite-autocomplete__input {
  border-color: #d4351c;
}

.govuk-form-group--error .lite-autocomplete__input:focus {
  box-shadow: none;
  border-color: #0b0c0c;
}

.govuk-inset-text--slim {
  border-left-width: 5px;
}

.lite-panel .govuk-panel__title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.04167;
}

@media print {
  .lite-panel .govuk-panel__title {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-panel .govuk-panel__title {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .lite-panel .govuk-panel__title {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.lite-panel .govuk-panel__body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.66;
}

@media print {
  .lite-panel .govuk-panel__body {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-panel .govuk-panel__body {
    font-size: 1.5rem;
    line-height: 1.66;
  }
}

@media print {
  .lite-panel .govuk-panel__body {
    font-size: 18pt;
    line-height: 1.66;
  }
}

.lite-panel--confirmation {
  text-align: left;
  background: #1d70b8;
  padding: 20px;
}

@media (min-width: 40.0625em) {
  .lite-panel--confirmation {
    padding: 30px;
  }
}

.lite-panel__status {
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

.lite-panel__status__item p {
  margin-top: 30px;
  margin-bottom: 5px;
  font-weight: bold;
}

.lite-panel__status__item__progress {
  background-color: rgba(255, 255, 255, .4);
  width: 100%;
  height: 5px;
  position: relative;
}

.lite-panel__status__item__progress--in-progress {
  opacity: 1;
}

.lite-panel__status__item__progress--in-progress:after {
  content: "";
  background-color: #fff;
  -webkit-animation-name: lite-in-progress-pulse;
  animation-name: lite-in-progress-pulse;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.lite-panel__status__item__progress--complete {
  background-color: #fff;
}

@-webkit-keyframes lite-in-progress-pulse {
  0% {
    opacity: 1;
    width: 0;
  }

  10% {
    opacity: 1;
    width: 0;
  }

  70% {
    opacity: 1;
    width: 100%;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}

@keyframes lite-in-progress-pulse {
  0% {
    opacity: 1;
    width: 0;
  }

  10% {
    opacity: 1;
    width: 0;
  }

  70% {
    opacity: 1;
    width: 100%;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}

.govuk-radios__label--error {
  color: #d4351c;
}

.govuk-radios__label--error:after {
  color: #0b0c0c;
}

.govuk-table__header--checkbox {
  border-bottom-width: 0;
  display: flex;
}

.govuk-table__cell--checkbox {
  margin: 0 0 15px;
  position: relative;
  width: 24px !important;
}

@media (max-width: 40.0525em) {
  .govuk-table__cell--checkbox {
    margin-top: -5px;
    margin-right: -10px;
    display: inline-block;
  }

  .govuk-table__cell--checkbox + .govuk-table__cell {
    display: inline-block;
  }
}

.govuk-table__cell--checkbox div:hover .govuk-checkboxes__label:before {
  background-color: #fff;
  box-shadow: 0 0 0 8px #b1b4b6;
}

.govuk-table__cell--checkbox .govuk-checkboxes__label {
  color: rgba(0, 0, 0, 0);
  width: 24px;
  height: 24px;
  display: block;
  padding: 0 !important;
}

.govuk-table__cell--checkbox .govuk-checkboxes__label:before {
  background: #fff;
  border-color: #0b0c0c;
  top: 9px;
}

.govuk-table__cell--checkbox .govuk-checkboxes__label:after {
  border-color: #0b0c0c;
  top: 16px;
}

.govuk-table__cell--nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.govuk-table__cell--border {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
  position: relative;
  overflow: hidden;
}

.govuk-table__cell--border:after {
  content: "";
  background: #b1b4b6;
  width: 1px;
  height: 25px;
  position: absolute;
  top: 8px;
  right: 0;
}

.govuk-table__cell--border-side {
  padding-left: 15px;
}

.govuk-table__cell--tight {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 10px;
  overflow: hidden;
}

@media (max-width: 40.0525em) {
  .govuk-table__cell--tight {
    width: auto;
  }
}

.govuk-table__header--tight {
  width: 10px;
}

.govuk-table__cell--line-number {
  color: #505a5f;
  white-space: nowrap;
  width: 1%;
  min-width: 15px;
  padding-right: 10px;
}

.govuk-table__cell__actions-list {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.govuk-table__cell__actions-list-item {
  display: inline;
}

.govuk-table__cell__actions-list-item:not(:last-child) {
  border-right: 1px solid #b1b4b6;
  margin-right: 10px;
  padding-right: 10px;
}

td .govuk-table__header {
  display: none;
}

.lite-table__cell--middle-align {
  vertical-align: middle;
}

@media (max-width: 40.0525em) {
  thead {
    display: none;
  }

  tbody tr {
    background-color: #f3f2f1;
    margin-bottom: 17px;
    padding: 15px;
    display: block;
    box-shadow: 0 2px #b1b4b6;
  }

  tbody tr:last-child {
    margin-bottom: 0;
  }

  tbody tr th, tbody tr td {
    padding-right: 0;
    display: block;
    border-bottom: none !important;
    padding-top: 0 !important;
  }

  tbody tr th .govuk-table__header, tbody tr td .govuk-table__header {
    margin-bottom: 10px;
    padding: 0;
    display: block;
    border-bottom: none !important;
  }

  tbody tr th:last-of-type, tbody tr td:last-of-type {
    padding-bottom: 0;
  }

  tbody tr th .govuk-tag--grey, tbody tr td .govuk-tag--grey {
    background: #fff;
  }

  tbody tr th .govuk-button--secondary, tbody tr td .govuk-button--secondary {
    background-color: #fff;
  }
}

.govuk-tag, .lite-mobile-tag {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.25;
  line-height: 1.25 !important;
}

@media print {
  .govuk-tag, .lite-mobile-tag {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-tag, .lite-mobile-tag {
    font-size: .875rem;
    line-height: 1.42857;
  }
}

@media print {
  .govuk-tag, .lite-mobile-tag {
    font-size: 12pt;
    line-height: 1.2;
  }
}

.govuk-tag--grey {
  color: #0b0c0c;
}

.lite-accordian-table {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .lite-accordian-table {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-accordian-table {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-accordian-table {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .lite-accordian-table {
    margin-bottom: 30px;
  }
}

.lite-accordian-table__head {
  text-align: left;
  font-weight: 700;
}

.lite-accordian-table__row td:last-child a {
  cursor: pointer;
  display: inline-block;
}

.lite-accordian-table__row td:last-child a svg {
  width: 20px;
  height: 12px;
  display: inline-block;
}

.lite-accordian-table__row td:last-child a svg * {
  fill: currentColor;
}

.lite-accordian-table__row td:last-child a:hover, .lite-accordian-table__row td:last-child a:active {
  opacity: .66;
}

.lite-accordian-table__row.open td:last-child a {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.lite-accordian-table__row .app-expanded-row__item {
  display: none;
}

.lite-accordian-table__row .app-expanded-row__item--invert {
  display: block;
}

.lite-accordian-table__cell, .lite-accordian-table__header {
  border: 0 solid rgba(0, 0, 0, 0);
  border-bottom: 2px solid #f3f2f1;
  padding: 10px 20px 10px 0 !important;
}

.lite-accordian-table__fold-wrapper {
  box-sizing: border-box;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 0;
}

.lite-accordian-table__fold {
  display: none;
}

.lite-accordian-table__fold.open {
  display: table-row;
}

.lite-accordian-table__fold.open .lite-accordian-table__fold-content {
  box-shadow: inset 0 -2px #f1efee;
}

.lite-accordian-table__fold.open .lite-accordian-table__fold-content > :last-child {
  margin-bottom: 0;
}

.lite-accordian-table__fold-content {
  box-sizing: border-box;
  background: #f3f2f1;
  padding: 20px;
}

.lite-accordian-table__chevron {
  -webkit-user-select: none;
  user-select: none;
  color: #0b0c0c !important;
}

.lite-app-bar {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.lite-app-bar > * {
  white-space: nowrap;
  margin-bottom: 0;
  display: inline-block;
}

@media (max-width: 40.0525em) {
  .lite-app-bar {
    display: block;
  }
}

.lite-app-bar__controls-buttons {
  align-items: center;
  display: flex;
}

.lite-app-bar__controls-buttons:last-child {
  margin-left: 0;
}

.lite-app-bar__controls-buttons > * {
  margin-left: 20px;
  display: block;
}

@media (max-width: 40.0525em) {
  .lite-app-bar__controls-buttons > * {
    margin-bottom: 15px;
    margin-left: 0;
  }

  .lite-app-bar__controls-buttons {
    display: block;
  }
}

.lite-app-bar__content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lite-app-bar__content > * {
  white-space: normal;
  display: block;
}

.lite-app-bar__content .govuk-caption-m {
  max-width: 650px;
  line-height: 1.66;
}

.lite-app-bar__controls {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.lite-app-bar__controls:last-child {
  margin-left: 0;
}

.lite-app-bar__controls > * {
  margin-left: 20px;
  display: block;
}

@media (max-width: 40.0525em) {
  .lite-app-bar__controls > * {
    margin-bottom: 15px;
    margin-left: 0;
  }

  .lite-app-bar__controls {
    display: block;
  }
}

.lite-input-with-label {
  grid-template-columns: .1fr auto;
  align-items: center;
  display: grid;
}

.lite-input-with-label > * {
  white-space: nowrap;
  margin-bottom: 0;
  display: inline-block;
}

@media (max-width: 40.0525em) {
  .lite-input-with-label {
    display: block;
  }
}

.lite-input-with-label .govuk-body {
  margin-top: 10px;
  margin-bottom: 10px;
}

.lite-case-note__container {
  grid-template-rows: 1fr auto;
  margin-bottom: 30px;
  display: grid;
}

.lite-case-note__container--error .lite-case-note__controls {
  border-color: #d4351c;
  border-top-width: 0;
  padding: 15px 13px 13px;
}

.lite-case-note__container--error .govuk-character-count__message {
  color: #d4351c;
  font-weight: bold;
}

.lite-case-note__textarea {
  min-height: 140px;
  margin: 0;
  padding: 15px;
}

.lite-case-note__controls {
  box-sizing: border-box;
  background: #f3f2f1;
  border: 2px solid #0b0c0c;
  border-top-width: 0;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  display: flex;
}

.lite-case-note__controls-buttons {
  align-items: center;
  display: flex;
}

.lite-case-note__controls-buttons a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 0;
  margin-right: 30px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .lite-case-note__controls-buttons a {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-case-note__controls-buttons a {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-case-note__controls-buttons a {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-case-note__controls-buttons button {
  margin: 0;
}

.cookie-settings__no-js {
  display: block;
}

.app-cookie-banner, .cookie-settings__form-wrapper {
  display: none;
}

.cookie-banner__confirmation .hide-button {
  text-align: right;
  display: block;
}

@media (min-width: 40.0625em) {
  .cookie-banner__confirmation .hide-button {
    float: right;
  }
}

.js-enabled .app-cookie-banner, .js-enabled .app-cookie-banner .cookie-banner__confirmation {
  display: none;
}

.js-enabled .app-cookie-banner--show, .js-enabled .app-cookie-banner--show__accepted-all .cookie-banner__confirmation {
  display: block;
}

.js-enabled .app-cookie-banner--show__accepted-all .cookie-banner__buttons, .js-enabled .cookie-settings__no-js {
  display: none;
}

.js-enabled .cookie-settings__form-wrapper {
  display: block;
}

@media (min-width: 40.0625em) {
  .js-enabled .app-cookie-banner__button-fixed-desktop {
    width: 280px;
    margin-right: 30px;
  }
}

.cookie-preferences .govuk-hint, .cookie-preferences .lite-filters__hint-text, .cookie-preferences .lite-file-upload__or-label {
  color: #505a5f;
}

.cookie-preferences .cookie-settings__confirmation {
  border: 5px solid #1d70b8;
  display: none;
}

.lite-expand__base-controls {
  float: right;
  justify-content: flex-end;
  display: inline-flex;
}

.lite-expand__base-controls .lite-expand__base-control {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-bottom: 1px solid;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
  display: inline-block;
}

@media print {
  .lite-expand__base-controls .lite-expand__base-control {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-expand__base-controls .lite-expand__base-control {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-expand__base-controls .lite-expand__base-control {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .lite-expand__base-controls .lite-expand__base-control {
    margin-bottom: 30px;
  }
}

.lite-expand__base-controls .lite-expand__base-control span:first-of-type {
  display: inline-block;
}

.lite-expand__base-controls .lite-expand__base-control span:last-of-type {
  display: none;
}

.lite-expand__base-controls .lite-expand__base-control svg {
  color: inherit;
  max-width: 13px;
  max-height: 13px;
  margin-left: 3px;
}

.lite-expand__base-controls .lite-expand__base-control svg * {
  fill: currentColor;
}

.lite-expand__base-controls .lite-expand__base-control--open svg {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.lite-expand__base-controls .lite-expand__base-control--open span:first-of-type {
  display: none;
}

.lite-expand__base-controls .lite-expand__base-control--open span:last-of-type {
  display: inline-block;
}

.lite-expand__control {
  color: #0b0c0c;
  cursor: pointer;
  display: inline-block;
}

.lite-expand__control:focus {
  color: #0b0c0c;
  background: #fd0;
  outline: 3px solid #fd0;
}

.lite-expand__control svg {
  width: 20px;
  height: 12px;
  display: inline-block;
}

.lite-expand__control svg * {
  fill: currentColor;
}

.lite-expand__control--open {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.lite-filters__hint-text {
  float: right;
  margin-top: 0;
  position: relative;
}

@media (max-width: 48.0525em) {
  .lite-filters__hint-text {
    float: none;
    margin-bottom: 30px;
  }
}

.lite-filters__hint-text--warning {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
}

@media print {
  .lite-filters__hint-text--warning {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-filters__hint-text--warning {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-filters__hint-text--warning {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (max-width: 48.0525em) {
  .lite-filters__hint-text--warning {
    margin-left: 40px;
  }
}

.lite-filters__hint-text--warning:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "!";
  text-align: center;
  color: #fff;
  background: #0b0c0c;
  border-radius: 100%;
  width: 30px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  position: absolute;
  top: -3px;
  left: -40px;
  line-height: 30px !important;
}

@media print {
  .lite-filters__hint-text--warning:after {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-filters__hint-text--warning:after {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-filters__hint-text--warning:after {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-filter-bar, .lite-search-bar {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  z-index: 0;
  margin-bottom: 20px;
  padding: 20px 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  position: relative;
}

@media (min-width: 40.0625em) {
  .lite-filter-bar, .lite-search-bar {
    margin-bottom: 30px;
  }
}

@media print {
  .lite-filter-bar, .lite-search-bar {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-filter-bar, .lite-search-bar {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-filter-bar, .lite-search-bar {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .lite-filter-bar, .lite-search-bar {
    margin-top: -15px;
  }
}

.lite-filter-bar__buttons, .lite-search-bar__buttons {
  margin: 15px 0;
  display: flex;
}

.lite-filter-bar__buttons > *, .lite-search-bar__buttons > * {
  margin: 0;
}

.lite-filter-bar__buttons > :first-child, .lite-search-bar__buttons > :first-child {
  margin-right: 15px;
}

.lite-filter-bar__label, .lite-search-bar__label {
  margin: 0 0 10px;
  padding: 0;
  display: block;
}

.lite-filter-bar__components, .lite-search-bar__components {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  margin-top: 15px;
  margin-bottom: 15px;
  display: grid;
}

.lite-filter-bar__components .govuk-checkboxes__label:before, .lite-search-bar__components .govuk-checkboxes__label:before {
  background: #fff;
}

.lite-filter-bar__components > div, .lite-search-bar__components > div {
  flex-direction: column;
  display: flex;
}

.lite-filter-bar:before, .lite-search-bar:before {
  content: "";
  z-index: -1;
  background-color: #f3f2f1;
  border: 2px solid #eeedec;
  border-width: 2px 0;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
}

.lite-filter-bar .lite-autocomplete__input, .lite-search-bar .lite-autocomplete__input {
  max-width: 100% !important;
}

.lite-filter-bar .govuk-select, .lite-search-bar .govuk-select {
  width: 100%;
  margin-right: 0;
  display: block;
  max-width: 100% !important;
}

.lite-filter-bar input, .lite-search-bar input {
  background-color: #fff;
}

.lite-filter-bar .govuk-date-input, .lite-search-bar .govuk-date-input {
  width: 100%;
}

.lite-filter-bar .govuk-form-group, .lite-search-bar .govuk-form-group {
  margin-bottom: 0;
}

.lite-filter-bar .govuk-details, .lite-search-bar .govuk-details {
  margin-bottom: 10px;
}

.lite-filter-bar .govuk-date-input, .lite-search-bar .govuk-date-input {
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

.lite-filter-bar .govuk-date-input .govuk-date-input__item, .lite-search-bar .govuk-date-input .govuk-date-input__item {
  margin-right: 0;
}

.lite-filter-bar .govuk-date-input .govuk-input--width-2, .lite-filter-bar .govuk-date-input .govuk-input--width-3, .lite-search-bar .govuk-date-input .govuk-input--width-2, .lite-search-bar .govuk-date-input .govuk-input--width-3 {
  max-width: 100%;
}

.lite-filter-bar .tokenfield, .lite-search-bar .tokenfield {
  min-height: 40px;
  padding: 2px 5px 0;
}

.lite-filter-bar .tokenfield-set-item, .lite-search-bar .tokenfield-set-item {
  margin-bottom: 2px;
}

.lite-filter-bar .govuk-input--width-20, .lite-search-bar .govuk-input--width-20 {
  max-width: 100%;
}

.lite-filter-bar .govuk-input[type="text"]:not(.govuk-date-input__input):not(.input-force-default-width) {
  max-width: 100%;
}

.lite-search-bar .govuk-input[type="text"]:not(.govuk-date-input__input):not(.input-force-default-width) {
  max-width: 100%;
}

@media (max-width: 40.0525em) {
  .lite-filter-bar .govuk-grid-column-one-quarter:not(:first-child):not(:last-child), .lite-search-bar .govuk-grid-column-one-quarter:not(:first-child):not(:last-child) {
    margin-top: 20px;
  }
}

@media (max-width: 40.0525em) and (min-width: 40.0625em) {
  .lite-filter-bar .govuk-grid-column-one-quarter:not(:first-child):not(:last-child), .lite-search-bar .govuk-grid-column-one-quarter:not(:first-child):not(:last-child) {
    margin-top: 30px;
  }
}

.lite-filter-toggle-link, .lite-toggle-link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-bottom: 1px solid;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
  display: inline-block;
}

@media print {
  .lite-filter-toggle-link, .lite-toggle-link {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-filter-toggle-link, .lite-toggle-link {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-filter-toggle-link, .lite-toggle-link {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .lite-filter-toggle-link, .lite-toggle-link {
    margin-bottom: 30px;
  }
}

.lite-filter-toggle-link svg, .lite-toggle-link svg {
  color: inherit;
  max-width: 13px;
  max-height: 13px;
  margin-right: 3px;
}

.lite-filter-toggle-link svg *, .lite-toggle-link svg * {
  fill: currentColor;
}

.lite-filter-toggle-link:hover, .lite-toggle-link:hover {
  border-color: currentColor;
}

.lite-checkbox-filter-link {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 5px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.lite-checkbox-filter-link:hover {
  border-bottom: 1px solid;
}

.lite-highlight {
  background-color: #fff2d3;
  box-shadow: 0 2px #ffbf47;
}

.lite-highlight--purple {
  background-color: #e8e9f2 !important;
  box-shadow: 0 2px #b1b2d3 !important;
}

.lite-highlight--red {
  background-color: #fadfdb !important;
  box-shadow: 0 2px #ed8878 !important;
}

.lite-highlight--blue {
  background-color: #dae8f3 !important;
  box-shadow: 0 2px #b3cfe7 !important;
}

.lite-info-bar {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #fff;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  background: #1d70b8;
  margin-top: -20px;
  margin-bottom: 30px;
  padding: 20px 20px 20px 70px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  -webkit-animation-name: lite-info-bar-animation;
  animation-name: lite-info-bar-animation;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  line-height: 30px !important;
}

@media print {
  .lite-info-bar {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-info-bar {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-info-bar {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-info-bar:before {
  content: "i";
  color: #1d70b8;
  text-align: center;
  background: #fff;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  padding-top: 1px;
  padding-left: 1px;
  font-size: 23px;
  position: absolute;
  left: 20px;
}

.lite-info-bar--success {
  color: #fff;
  background: #00703c;
}

.lite-info-bar--success:before {
  content: "";
}

.lite-info-bar--success:after {
  content: "";
  border: 0 solid #00703c;
  border-width: 0 0 4px 4px;
  width: 13px;
  height: 4px;
  position: absolute;
  top: 30px;
  left: 27px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.lite-info-bar--warning {
  color: #fff;
  background: #f47738;
}

.lite-info-bar--warning:before {
  content: "!";
  color: #f47738;
}

.lite-info-bar--error {
  color: #fff;
  background: #d4351c;
}

.lite-info-bar--error:before {
  content: "!";
  color: #d4351c;
}

.lite-info-bar--no-animation {
  opacity: 1;
  margin-bottom: 40px;
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

@-webkit-keyframes lite-info-bar-animation {
  0% {
    opacity: 0;
    margin-bottom: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    margin-bottom: 40px;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes lite-info-bar-animation {
  0% {
    opacity: 0;
    margin-bottom: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    margin-bottom: 40px;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.lite-info-bar__close_link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  color: currentColor;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  font-family: GDS Transport, arial, sans-serif;
  font-size: 32px;
  text-decoration: none;
}

@media print {
  .lite-info-bar__close_link {
    font-family: sans-serif;
  }
}

.lite-info-bar__close_link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.lite-info-bar__close_link:hover {
  border-bottom: 2px solid;
}

.lite-info-bar__close_link:active {
  opacity: .5;
}

.lite-info-bar--link {
  background-color: #1d70b8;
  display: block;
}

.lite-info-bar--link:before {
  color: #1d70b8;
}

.lite-info-bar--link:hover {
  background-color: #16548a;
}

.lite-info-bar--link:active {
  background-color: #0f385c;
}

.lite-information-board {
  z-index: 0;
  margin-bottom: 30px;
  padding: 30px 0;
  display: block;
  position: relative;
}

.lite-information-board:before {
  content: "";
  z-index: -1;
  background-color: #f3f2f1;
  border: 2px solid #eeedec;
  border-width: 2px 0;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
}

.lite-information-board > div > div h4 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  margin-bottom: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.14286;
}

@media print {
  .lite-information-board > div > div h4 {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-information-board > div > div h4 {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .lite-information-board > div > div h4 {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.lite-information-board > div > div p:last-of-type {
  margin: 0;
}

.lite-information-board .govuk-grid-row {
  margin-bottom: 30px;
}

.lite-information-board .govuk-grid-row:last-of-type {
  margin-bottom: 0;
}

.lite-information-text {
  display: flex;
}

.lite-information-text__icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  background: #0b0c0c;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-right: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.11111;
  display: inline-flex;
}

@media print {
  .lite-information-text__icon {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-information-text__icon {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .lite-information-text__icon {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.lite-information-text__text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: inline-block;
  line-height: 36px !important;
}

@media print {
  .lite-information-text__text {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-information-text__text {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-information-text__text {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-information-text__help {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  max-width: 85%;
  margin-top: 15px;
  margin-left: 51px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.66;
}

@media print {
  .lite-information-text__help {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-information-text__help {
    font-size: 1.1875rem;
    line-height: 1.66;
  }
}

@media print {
  .lite-information-text__help {
    font-size: 14pt;
    line-height: 1.66;
  }
}

.lite-information-text--small .lite-information-text__icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .lite-information-text--small .lite-information-text__icon {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-information-text--small .lite-information-text__icon {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .lite-information-text--small .lite-information-text__icon {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.lite-information-text--small .lite-information-text__text {
  line-height: 22px !important;
}

.lite-has-modal {
  position: relative;
  overflow: hidden;
}

.lite-modal-background {
  z-index: 999;
  -webkit-overflow-scrolling: touch;
  background: rgba(15, 32, 46, .8);
  padding: 30px;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

@media (max-width: 40.0525em) {
  .lite-modal-background {
    padding: 0;
  }
}

.lite-modal {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  width: 600px;
  max-width: 100%;
  margin: 6vh auto;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .07), 0 2px 2px rgba(0, 0, 0, .07), 0 4px 4px rgba(0, 0, 0, .07), 0 8px 8px rgba(0, 0, 0, .07), 0 16px 16px rgba(0, 0, 0, .07), 0 32px 32px rgba(0, 0, 0, .07), 0 0 2px rgba(0, 0, 0, .2);
}

@media print {
  .lite-modal {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-modal {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-modal {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-modal .govuk-body-m, .lite-modal .govuk-heading-m {
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
}

@media (max-width: 40.0525em) {
  .lite-modal {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.lite-modal-header {
  margin-bottom: 10px;
  padding: 30px;
}

.govuk-link--arrow {
  border-bottom: 1px solid;
  padding-right: 14px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.govuk-link--arrow:after {
  -webkit-clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  border: 5.5px solid rgba(0, 0, 0, 0);
  border-width: 5.5px 0 5.5px 6px;
  border-left-color: inherit;
  content: "";
  width: 0;
  height: 0;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  bottom: 1px;
  right: 0;
}

.govuk-link--arrow:visited {
  color: #4c2c92;
  border-bottom: 1px solid #4c2c92;
}

.govuk-link--arrow:visited:after {
  border-left-color: #4c2c92;
}

.lite-modal-back-link {
  float: left;
  border-bottom: 1px solid;
  padding-left: 14px;
  text-decoration: none;
  display: none;
  position: relative;
}

.lite-modal-back-link:before {
  -webkit-clip-path: polygon(0% 50%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 50%, 100% 100%, 100% 0%);
  border: 5px solid rgba(0, 0, 0, 0);
  border-width: 5px 6px 5px 0;
  border-right-color: inherit;
  content: "";
  width: 0;
  height: 0;
  margin: auto;
  display: block;
  position: absolute;
  top: -1px;
  bottom: 1px;
  left: 0;
}

.lite-modal-close-link {
  float: right;
  border-bottom: 1px solid;
  text-decoration: none;
}

.lite-modal__contents {
  padding: 0 30px 30px;
  position: relative;
}

.lite-modal-content .govuk-button {
  margin-bottom: 0 !important;
}

.lite-more-actions__container--float {
  z-index: 2;
  background: #edeceb;
  border-bottom: 2px solid #929191;
  position: absolute;
  top: 38px;
  right: 0;
}

.lite-more-actions__container--float a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  border-bottom: 2px solid #dbdad9;
  justify-content: space-between;
  align-items: center;
  width: 260px;
  min-height: 54px;
  padding: 0 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
  display: flex;
}

@media print {
  .lite-more-actions__container--float a {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-more-actions__container--float a {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-more-actions__container--float a {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-more-actions__container--float a svg {
  width: 21px;
  height: 21px;
}

.lite-more-actions__container--float a svg * {
  fill: currentColor;
}

.lite-more-actions__container--float a:hover {
  background-color: #dbdad9;
}

.lite-more-actions__container--float a:active {
  background-color: #c2c2c1;
}

.lite-more-actions__container--float a:last-of-type {
  border-bottom-width: 0;
}

.lite-more-actions__container--hidden {
  display: none;
}

.lite-more-actions__button {
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
}

.lite-more-actions__button:after {
  -webkit-clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  border: 5px solid rgba(0, 0, 0, 0);
  border-width: 6px 5px 0;
  border-top-color: inherit;
  content: "";
  background: currentColor;
  width: 0;
  height: 0;
  margin-left: 8px;
  display: inline-block;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.lite-more-actions__parent-disabled-buttons > .govuk-button:not(.lite-more-actions__button) {
  opacity: .4;
}

.lite-notification-bubble {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  text-align: center;
  background-color: #d4351c;
  border-radius: 1000px;
  justify-content: center;
  align-items: center;
  width: 22px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.25;
  display: inline-flex;
  line-height: 22px !important;
}

@media print {
  .lite-notification-bubble {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-notification-bubble {
    font-size: .875rem;
    line-height: 1.42857;
  }
}

@media print {
  .lite-notification-bubble {
    font-size: 12pt;
    line-height: 1.2;
  }
}

.lite-pagination__container {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.lite-pagination__navigation-link {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  line-height: 40px !important;
}

.lite-pagination__navigation-link > :first-child {
  margin-right: 10px;
}

.lite-pagination__navigation-link svg {
  color: inherit;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
}

.lite-pagination__navigation-link svg * {
  color: inherit;
  stroke: currentColor !important;
}

.lite-pagination__navigation-link:hover:after {
  content: "";
  background: currentColor;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.lite-pagination__navigation-link--disabled {
  pointer-events: none;
  color: #505a5f !important;
}

.lite-pagination__list {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

@media (max-width: 40.0525em) {
  .lite-pagination__list {
    display: none;
  }
}

.lite-pagination__list-item {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  justify-content: center;
  width: 40px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: flex;
  position: relative;
  line-height: 40px !important;
}

@media print {
  .lite-pagination__list-item {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-pagination__list-item {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-pagination__list-item {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-pagination__list-item--selected {
  font-weight: bold;
}

.lite-pagination__list-item--selected:after {
  content: "";
  background: #1d70b8;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
}

.lite-pagination__list-item .lite-pagination__link {
  width: 100%;
}

.lite-pagination__link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: inherit;
  color: #1d70b8;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
  justify-content: center;
  align-items: center;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
  display: flex;
  position: relative;
  line-height: 40px !important;
}

@media print {
  .lite-pagination__link {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-pagination__link {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-pagination__link {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-pagination__link:hover:after {
  content: "";
  background: currentColor;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.lite-pagination__list-ellipsis {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  letter-spacing: 3px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin: 0 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: flex;
  position: relative;
  line-height: 40px !important;
}

@media print {
  .lite-pagination__list-ellipsis {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-pagination__list-ellipsis {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-pagination__list-ellipsis {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-related-items {
  border-top: 10px solid #f3f2f1;
  padding-top: 20px;
}

.lite-related-items:first-of-type {
  border-top: 10px solid #1d70b8;
}

.lite-related-items--sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
}

.lite-search__container {
  width: 100%;
  max-width: 420px;
  height: 40px;
  display: flex;
  position: relative;
}

.lite-search__container input[type="search"] {
  padding: 0 5px 0 10px;
}

.lite-search__container input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.lite-search__container .lite-search__button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  cursor: pointer;
  background-color: #0b0c0c;
  border: 0;
  outline: 0;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
}

@media print {
  .lite-search__container .lite-search__button {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-search__container .lite-search__button {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-search__container .lite-search__button {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-search__container .lite-search__button svg {
  display: none;
}

.lite-search__container .lite-search__button svg * {
  fill: #fff;
}

.lite-search__container .lite-search__button span {
  margin: 0 5px;
  display: block;
}

.js-enabled .lite-search__container input[type="search"] {
  padding: 0 40px 0 10px;
}

.js-enabled .lite-search__container button {
  width: 40px;
  position: absolute;
}

.js-enabled .lite-search__container button svg {
  display: block;
}

.js-enabled .lite-search__container button span {
  display: none;
}

.lite-search__button--instant-search {
  cursor: default !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.lite-search__button--instant-search svg * {
  fill: #0b0c0c !important;
}

.lite-filter-search__container {
  max-width: 400px;
  display: none;
}

.lite-\!-fade-in > :nth-child(0), .lite-spinner > :nth-child(0) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :first-child, .lite-spinner > :first-child {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(2), .lite-spinner > :nth-child(2) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(3), .lite-spinner > :nth-child(3) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(4), .lite-spinner > :nth-child(4) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(5), .lite-spinner > :nth-child(5) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(6), .lite-spinner > :nth-child(6) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(7), .lite-spinner > :nth-child(7) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(8), .lite-spinner > :nth-child(8) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(9), .lite-spinner > :nth-child(9) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(10), .lite-spinner > :nth-child(10) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(11), .lite-spinner > :nth-child(11) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(12), .lite-spinner > :nth-child(12) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(13), .lite-spinner > :nth-child(13) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.2s;
  animation-delay: 2.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(14), .lite-spinner > :nth-child(14) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.3s;
  animation-delay: 2.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(15), .lite-spinner > :nth-child(15) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(16), .lite-spinner > :nth-child(16) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(17), .lite-spinner > :nth-child(17) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.6s;
  animation-delay: 2.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(18), .lite-spinner > :nth-child(18) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.7s;
  animation-delay: 2.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(19), .lite-spinner > :nth-child(19) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(20), .lite-spinner > :nth-child(20) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.9s;
  animation-delay: 2.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(21), .lite-spinner > :nth-child(21) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(22), .lite-spinner > :nth-child(22) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.1s;
  animation-delay: 3.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(23), .lite-spinner > :nth-child(23) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.2s;
  animation-delay: 3.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(24), .lite-spinner > :nth-child(24) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.3s;
  animation-delay: 3.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(25), .lite-spinner > :nth-child(25) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.4s;
  animation-delay: 3.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(26), .lite-spinner > :nth-child(26) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(27), .lite-spinner > :nth-child(27) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.6s;
  animation-delay: 3.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(28), .lite-spinner > :nth-child(28) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.7s;
  animation-delay: 3.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(29), .lite-spinner > :nth-child(29) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.8s;
  animation-delay: 3.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(30), .lite-spinner > :nth-child(30) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.9s;
  animation-delay: 3.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(31), .lite-spinner > :nth-child(31) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(32), .lite-spinner > :nth-child(32) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.1s;
  animation-delay: 4.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(33), .lite-spinner > :nth-child(33) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.2s;
  animation-delay: 4.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(34), .lite-spinner > :nth-child(34) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.3s;
  animation-delay: 4.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(35), .lite-spinner > :nth-child(35) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.4s;
  animation-delay: 4.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(36), .lite-spinner > :nth-child(36) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(37), .lite-spinner > :nth-child(37) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.6s;
  animation-delay: 4.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(38), .lite-spinner > :nth-child(38) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.7s;
  animation-delay: 4.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(39), .lite-spinner > :nth-child(39) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.8s;
  animation-delay: 4.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(40), .lite-spinner > :nth-child(40) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.9s;
  animation-delay: 4.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(41), .lite-spinner > :nth-child(41) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(42), .lite-spinner > :nth-child(42) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.1s;
  animation-delay: 5.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(43), .lite-spinner > :nth-child(43) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.2s;
  animation-delay: 5.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(44), .lite-spinner > :nth-child(44) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.3s;
  animation-delay: 5.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(45), .lite-spinner > :nth-child(45) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.4s;
  animation-delay: 5.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(46), .lite-spinner > :nth-child(46) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.5s;
  animation-delay: 5.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(47), .lite-spinner > :nth-child(47) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.6s;
  animation-delay: 5.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(48), .lite-spinner > :nth-child(48) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.7s;
  animation-delay: 5.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(49), .lite-spinner > :nth-child(49) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.8s;
  animation-delay: 5.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-\!-fade-in > :nth-child(50), .lite-spinner > :nth-child(50) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.9s;
  animation-delay: 5.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-spinner {
  position: relative;
}

.lite-spinner:after {
  content: "";
  border: 5px solid rgba(255, 255, 255, .4);
  border-top-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: 1s linear both spin;
  animation: 1s linear both spin;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.lite-confirmation-details-delay > :nth-child(0) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :first-child {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(2) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.35s;
  animation-delay: 1.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(3) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.45s;
  animation-delay: 1.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(4) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.55s;
  animation-delay: 1.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(5) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.65s;
  animation-delay: 1.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(6) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.75s;
  animation-delay: 1.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(7) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.85s;
  animation-delay: 1.85s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(8) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 1.95s;
  animation-delay: 1.95s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(9) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.05s;
  animation-delay: 2.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(10) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.15s;
  animation-delay: 2.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(11) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.25s;
  animation-delay: 2.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(12) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.35s;
  animation-delay: 2.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(13) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.45s;
  animation-delay: 2.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(14) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.55s;
  animation-delay: 2.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(15) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.65s;
  animation-delay: 2.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(16) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.75s;
  animation-delay: 2.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(17) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.85s;
  animation-delay: 2.85s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(18) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 2.95s;
  animation-delay: 2.95s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(19) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.05s;
  animation-delay: 3.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(20) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.15s;
  animation-delay: 3.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(21) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.25s;
  animation-delay: 3.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(22) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.35s;
  animation-delay: 3.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(23) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.45s;
  animation-delay: 3.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(24) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.55s;
  animation-delay: 3.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(25) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.65s;
  animation-delay: 3.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(26) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.75s;
  animation-delay: 3.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(27) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.85s;
  animation-delay: 3.85s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(28) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 3.95s;
  animation-delay: 3.95s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(29) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.05s;
  animation-delay: 4.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(30) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.15s;
  animation-delay: 4.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(31) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.25s;
  animation-delay: 4.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(32) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.35s;
  animation-delay: 4.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(33) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.45s;
  animation-delay: 4.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(34) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.55s;
  animation-delay: 4.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(35) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.65s;
  animation-delay: 4.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(36) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.75s;
  animation-delay: 4.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(37) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.85s;
  animation-delay: 4.85s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(38) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 4.95s;
  animation-delay: 4.95s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(39) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.05s;
  animation-delay: 5.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(40) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.15s;
  animation-delay: 5.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(41) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.25s;
  animation-delay: 5.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(42) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.35s;
  animation-delay: 5.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(43) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.45s;
  animation-delay: 5.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(44) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.55s;
  animation-delay: 5.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(45) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.65s;
  animation-delay: 5.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(46) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.75s;
  animation-delay: 5.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(47) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.85s;
  animation-delay: 5.85s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(48) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 5.95s;
  animation-delay: 5.95s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(49) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 6.05s;
  animation-delay: 6.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(50) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 6.15s;
  animation-delay: 6.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(51) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 6.25s;
  animation-delay: 6.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(52) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 6.35s;
  animation-delay: 6.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(53) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 6.45s;
  animation-delay: 6.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(54) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 6.55s;
  animation-delay: 6.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(55) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 6.65s;
  animation-delay: 6.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(56) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 6.75s;
  animation-delay: 6.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(57) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 6.85s;
  animation-delay: 6.85s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(58) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 6.95s;
  animation-delay: 6.95s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(59) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 7.05s;
  animation-delay: 7.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(60) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 7.15s;
  animation-delay: 7.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(61) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 7.25s;
  animation-delay: 7.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(62) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 7.35s;
  animation-delay: 7.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(63) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 7.45s;
  animation-delay: 7.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(64) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 7.55s;
  animation-delay: 7.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(65) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 7.65s;
  animation-delay: 7.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(66) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 7.75s;
  animation-delay: 7.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(67) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 7.85s;
  animation-delay: 7.85s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(68) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 7.95s;
  animation-delay: 7.95s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(69) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 8.05s;
  animation-delay: 8.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(70) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 8.15s;
  animation-delay: 8.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(71) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 8.25s;
  animation-delay: 8.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(72) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 8.35s;
  animation-delay: 8.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(73) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 8.45s;
  animation-delay: 8.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(74) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 8.55s;
  animation-delay: 8.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(75) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 8.65s;
  animation-delay: 8.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(76) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 8.75s;
  animation-delay: 8.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(77) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 8.85s;
  animation-delay: 8.85s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(78) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 8.95s;
  animation-delay: 8.95s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(79) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 9.05s;
  animation-delay: 9.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(80) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 9.15s;
  animation-delay: 9.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(81) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 9.25s;
  animation-delay: 9.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(82) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 9.35s;
  animation-delay: 9.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(83) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 9.45s;
  animation-delay: 9.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(84) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 9.55s;
  animation-delay: 9.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(85) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 9.65s;
  animation-delay: 9.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(86) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 9.75s;
  animation-delay: 9.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(87) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 9.85s;
  animation-delay: 9.85s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(88) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 9.95s;
  animation-delay: 9.95s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(89) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 10.05s;
  animation-delay: 10.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(90) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 10.15s;
  animation-delay: 10.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(91) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 10.25s;
  animation-delay: 10.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(92) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 10.35s;
  animation-delay: 10.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(93) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 10.45s;
  animation-delay: 10.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(94) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 10.55s;
  animation-delay: 10.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(95) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 10.65s;
  animation-delay: 10.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(96) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 10.75s;
  animation-delay: 10.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(97) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 10.85s;
  animation-delay: 10.85s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(98) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 10.95s;
  animation-delay: 10.95s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(99) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 11.05s;
  animation-delay: 11.05s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-confirmation-details-delay > :nth-child(100) {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-delay: 11.15s;
  animation-delay: 11.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lite-subnav {
  margin: 0;
  padding: 0 15px 0 0;
  list-style-type: none;
}

.lite-subnav__link {
  display: block;
  position: relative;
}

.lite-subnav__link > a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #505a5f;
  justify-content: flex-start;
  align-items: center;
  min-height: 44px;
  padding: 10px 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.5;
  text-decoration: none;
  display: inline-flex;
}

@media print {
  .lite-subnav__link > a {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-subnav__link > a {
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media print {
  .lite-subnav__link > a {
    font-size: 14pt;
    line-height: 1.5;
  }
}

.lite-subnav__link > a:hover:after {
  background-color: #505a5f;
}

.lite-subnav__link > a:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.lite-subnav__link > a:after {
  content: "";
  width: 2px;
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: -15px;
}

.lite-subnav__link--selected a {
  pointer-events: none;
  color: #0b0c0c !important;
}

.lite-subnav__link--selected a:after {
  background-color: #1d70b8 !important;
}

.lite-tabs__container {
  grid-template-columns: 1fr auto;
  margin-bottom: 30px;
  display: grid;
  position: relative;
}

@media (max-width: 40.0525em) {
  .lite-tabs__container {
    margin-bottom: 10px;
    grid-template-columns: 1fr !important;
  }
}

.lite-tabs__controls {
  z-index: 1;
  position: relative;
}

@media (max-width: 40.0525em) {
  .lite-tabs__controls {
    margin-left: 0 !important;
  }
}

@media (min-width: 40.0625em) {
  .lite-tabs__controls > * {
    margin: 0 0 0 15px;
  }
}

.lite-tabs__controls:after {
  content: "";
  pointer-events: none;
  z-index: 1;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;
}

.lite-tabs {
  flex-wrap: wrap;
  gap: 25px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

@media (max-width: 40.0525em) {
  .lite-tabs {
    flex-direction: column;
    gap: 10px;
  }
}

.lite-tabs__tab {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  padding: 15px 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.14286;
  display: inline-block;
  text-decoration: none !important;
}

@media print {
  .lite-tabs__tab {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-tabs__tab {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .lite-tabs__tab {
    font-size: 14pt;
    line-height: 1.2;
  }
}

@media (max-width: 40.0525em) {
  .lite-tabs__tab {
    padding: 5px 0;
  }
}

.lite-tabs__tab:hover {
  border-bottom: 2px solid #505a5f;
}

.lite-tabs__tab:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  border-bottom-color: currentColor;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.lite-tabs__tab .lite-notification-bubble {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-bottom-width: 0;
  margin-left: 2.5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.25;
  width: 18px !important;
  line-height: 18px !important;
}

@media print {
  .lite-tabs__tab .lite-notification-bubble {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-tabs__tab .lite-notification-bubble {
    font-size: .875rem;
    line-height: 1.42857;
  }
}

@media print {
  .lite-tabs__tab .lite-notification-bubble {
    font-size: 12pt;
    line-height: 1.2;
  }
}

.lite-tabs__tab-notification {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  z-index: 0;
  justify-content: center;
  align-items: center;
  min-width: 10px;
  margin-left: 5px;
  padding: 0 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.14286;
  display: inline-flex;
  position: relative;
  -webkit-text-decoration: underline rgba(0, 0, 0, 0) !important;
  text-decoration: underline rgba(0, 0, 0, 0) !important;
}

@media print {
  .lite-tabs__tab-notification {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-tabs__tab-notification {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .lite-tabs__tab-notification {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.lite-tabs__tab-notification:after {
  content: "";
  z-index: -1;
  background-color: #d4351c;
  border-radius: 10px;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0;
  right: 0;
}

.lite-tabs__tab--selected {
  color: #0b0c0c;
  cursor: default !important;
  border-bottom: 2px solid #1d70b8 !important;
}

.lite-mobile-tag {
  color: #0b0c0c;
  background-color: #fff;
}

@media (min-width: 40.0625em) {
  .lite-mobile-tag {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: none;
    letter-spacing: normal;
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    padding: 0;
    font-family: GDS Transport, arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
  }
}

@media print and (min-width: 40.0625em) {
  .lite-mobile-tag {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) and (min-width: 40.0625em) {
  .lite-mobile-tag {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print and (min-width: 40.0625em) {
  .lite-mobile-tag {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-tag {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  background-color: #b1b4b6;
  border: 2px solid rgba(0, 0, 0, 0);
  width: 150px;
  margin: 0 0 30px;
  padding: 10px 10px 7px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 700;
  line-height: 35px;
  right: 0;
}

@media print {
  .lite-tag {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-tag {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .lite-tag {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.lite-tag--small {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 130px;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  line-height: 28px;
}

@media print {
  .lite-tag--small {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-tag--small {
    font-size: .875rem;
    line-height: 1.42857;
  }
}

@media print {
  .lite-tag--small {
    font-size: 12pt;
    line-height: 1.2;
  }
}

.lite-tag--hollow {
  color: #0b0c0c;
  background-color: #fff;
  border: 2px solid #b1b4b6;
}

.lite-tag--green {
  background-color: #00703c;
}

.lite-tag--orange {
  background-color: #f47738;
}

.lite-tag--red {
  background-color: #d4351c;
}

.lite-tag--blue {
  background-color: #1d70b8;
}

.lite-task-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

@media (min-width: 40.0625em) {
  .lite-task-list {
    min-width: 550px;
  }
}

.lite-task-list__section {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11111;
  display: table;
}

@media print {
  .lite-task-list__section {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-task-list__section {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .lite-task-list__section {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.lite-task-list__section-number {
  min-width: 30px;
  padding-right: 0;
  display: table-cell;
}

.lite-task-list__items {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 30px;
  padding-left: 30px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  list-style: none;
}

@media print {
  .lite-task-list__items {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-task-list__items {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-task-list__items {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .lite-task-list__items {
    margin-bottom: 50px;
    padding-left: 30px;
  }
}

.lite-task-list__item {
  border-bottom: 1px solid #b1b4b6;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0 !important;
}

.lite-task-list__item:after {
  content: "";
  clear: both;
  display: block;
}

.lite-task-list__item-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
  line-height: 34px !important;
}

.lite-task-list__item-header a {
  margin-right: 10px;
}

.lite-task-list__item-header .lite-tag {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 150px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.14286;
  line-height: 34px !important;
}

@media print {
  .lite-task-list__item-header .lite-tag {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-task-list__item-header .lite-tag {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .lite-task-list__item-header .lite-tag {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.lite-task-list__item-details .govuk-caption-m {
  margin: 5px 0;
}

.lite-task-list__item-details .govuk-body-m {
  margin: 5px 0;
  padding: 0;
}

.lite-task-list__item-details * {
  border: none;
}

.lite-task-list__item:first-child {
  border-top: 1px solid #b1b4b6;
}

.lite-task-list__task-name {
  display: block;
}

@media (min-width: 28.125em) {
  .lite-task-list__task-name {
    float: left;
  }
}

.lite-task-list__task-completed {
  margin-top: 10px;
  margin-bottom: 5px;
}

@media (min-width: 28.125em) {
  .lite-task-list__task-completed {
    float: right;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.lite-tick-cross-list {
  margin: 0;
  margin-top: 0;
  padding: 0;
  list-style: none;
}

.lite-tick-cross-list li {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .lite-tick-cross-list li {
    color: #000;
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-tick-cross-list li {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-tick-cross-list li {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-tick-cross-list li:last-of-type .lite-tick-cross {
  margin-bottom: 0;
}

.lite-tick-cross {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.lite-tick-cross svg {
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
  margin-right: 10px;
}

.lite-tick-cross--tick svg * {
  fill: #00703c;
}

.lite-tick-cross--cross {
  color: #505a5f;
}

.lite-tick-cross--cross svg * {
  fill: #505a5f;
}

.tokenfield {
  box-sizing: border-box;
  color: #505a5f;
  background-color: #fff;
  border: 2px solid #0b0c0c;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 59ex;
  min-height: 45px;
  padding: 5px 5px 0;
  position: relative;
  display: flex !important;
}

.tokenfield:focus-within {
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 2px #0b0c0c;
}

.tokenfield.tokenfield-mode-tokens .focused {
  background-color: #fd0;
  border-color: #0b0c0c;
  box-shadow: 0 1px #0b0c0c;
}

.tokenfield.tokenfield-mode-tokens .selected {
  background: #f3f2f1;
}

.tokenfield .tokenfield-set > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.tokenfield-container .tokenfield .tokenfield-set > ul > li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  color: #0b0c0c;
  background-color: #f3f2f1;
  border-bottom: 2px solid #b1b4b6;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 30px 5px 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  position: relative;
}

@media print {
  .tokenfield-container .tokenfield .tokenfield-set > ul > li {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .tokenfield-container .tokenfield .tokenfield-set > ul > li {
    font-size: 1.1875rem;
    line-height: 1;
  }
}

@media print {
  .tokenfield-container .tokenfield .tokenfield-set > ul > li {
    font-size: 14pt;
    line-height: 1;
  }
}

.tokenfield .tokenfield-set > ul > li .item-remove {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  color: #0b0c0c;
  justify-content: center;
  align-items: center;
  width: 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.11111;
  text-decoration: none;
  display: inline-flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
}

@media print {
  .tokenfield .tokenfield-set > ul > li .item-remove {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .tokenfield .tokenfield-set > ul > li .item-remove {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .tokenfield .tokenfield-set > ul > li .item-remove {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.tokenfield .tokenfield-set > ul > li .item-remove:hover {
  color: #d4351c;
}

.tokenfield .tokenfield-input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  border: 0;
  outline: 0;
  margin: 0 0 5px 5px;
  padding: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .tokenfield .tokenfield-input {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .tokenfield .tokenfield-input {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .tokenfield .tokenfield-input {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.tokenfield .tokenfield-suggest {
  z-index: 10;
  box-sizing: content-box;
  background-color: #fff;
  border: 2px solid #0b0c0c;
  width: 100%;
  position: absolute;
  top: 100%;
  left: -2px;
  overflow: auto;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .07), 0 2px 2px rgba(0, 0, 0, .07), 0 4px 4px rgba(0, 0, 0, .07), 0 8px 8px rgba(0, 0, 0, .07), 0 16px 16px rgba(0, 0, 0, .07), 0 32px 32px rgba(0, 0, 0, .07), 0 0 2px rgba(0, 0, 0, .2);
}

.tokenfield .tokenfield-suggest > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tokenfield .tokenfield-suggest > ul > li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  color: #0b0c0c;
  padding: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
}

@media print {
  .tokenfield .tokenfield-suggest > ul > li {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .tokenfield .tokenfield-suggest > ul > li {
    font-size: 1.1875rem;
    line-height: 1;
  }
}

@media print {
  .tokenfield .tokenfield-suggest > ul > li {
    font-size: 14pt;
    line-height: 1;
  }
}

.tokenfield .tokenfield-suggest > ul > li:active {
  color: #fff;
  background-color: #1d70b8;
}

.lite-tree-view {
  position: relative;
}

.lite-tree-view__root {
  padding: 0;
}

.lite-tree-view__root[open] > .lite-tree-view__summary:after {
  top: calc(50% - 2.5px);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.lite-tree-view__summary {
  -webkit-appearance: none;
  appearance: none;
  color: #1d70b8;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  display: inline-flex;
  position: relative;
}

.lite-tree-view__summary .govuk-checkboxes__item {
  margin-bottom: 0;
  margin-right: 20px;
  display: block;
  position: relative;
}

.lite-tree-view__summary .govuk-checkboxes__item .govuk-checkboxes__label:before {
  background-color: #fff;
}

.lite-tree-view__summary:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.lite-tree-view__summary:active {
  color: #0b0c0c !important;
}

.lite-tree-view__summary:after {
  content: "";
  -webkit-clip-path: none;
  clip-path: none;
  border: 2px solid #0b0c0c;
  border-width: 2px 2px 0 0;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  top: calc(50% - 7.5px);
  right: 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.lite-tree-view__summary::-webkit-details-marker {
  display: none;
}

.lite-tree-view__children {
  box-sizing: border-box;
  border-left: 4px solid #b1b4b6;
  margin-top: 10px;
  margin-left: 10px;
  padding-left: 25px;
}

.lite-tree-view__children .govuk-checkboxes__item {
  min-height: 44px;
  margin-bottom: 0;
  display: block;
  position: relative;
}

.selected-options {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .selected-options {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .selected-options {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .selected-options {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.selected-options--empty {
  padding: 0;
}

.selected-options__options {
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.selected-options__option {
  background-color: #f3f2f1;
  border-bottom: 2px solid #b1b4b6;
  align-items: center;
  padding: 0;
  display: flex;
}

.selected-options__option-text {
  white-space: nowrap;
  margin: 5px 5px 5px 10px;
}

.selected-options__option-remove {
  background-color: inherit;
  cursor: pointer;
  border: 0;
  width: 25px;
  height: 35px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.selected-options__option-remove:focus {
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  box-shadow: 0 2px #0b0c0c;
}

.selected-options__option-remove:hover {
  background-color: #b1b4b6;
  outline: 3px solid rgba(0, 0, 0, 0);
  box-shadow: 0 2px #0b0c0c;
}

.selected-options__option-remove-icon {
  margin-bottom: 50px;
  font-size: 18px;
  position: relative;
  top: 8px;
}

.lite-autocomplete__wrapper {
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  display: block;
  position: relative;
}

.lite-autocomplete__hint, .lite-autocomplete__input {
  -webkit-appearance: none;
  box-sizing: border-box;
  border: 2px solid #0b0c0c;
  border-radius: 0;
  width: 100%;
  max-width: 41ex;
  margin-bottom: 0;
  height: 40px !important;
  display: block !important;
}

.lite-autocomplete__input {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
}

.lite-autocomplete__hint {
  color: #b1b4b6;
  margin-left: 2px;
  position: absolute;
}

.lite-autocomplete__input--default {
  padding: 5px;
}

.lite-autocomplete__input--focused {
  outline-offset: 0;
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 2px;
}

.lite-autocomplete__input--show-all-values {
  cursor: pointer;
  padding: 4px 34px 4px 4px;
}

.lite-autocomplete__dropdown-arrow-down {
  z-index: -1;
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 8px;
}

.lite-autocomplete__menu {
  color: #0b0c0c;
  background-color: #fff;
  border: 2px solid #0b0c0c;
  border-top: 0;
  width: calc(100% - 4px);
  max-width: 41ex;
  max-height: 342px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.lite-autocomplete__menu--visible {
  display: block;
}

.lite-autocomplete__menu--hidden {
  display: none;
}

.lite-autocomplete__menu--overlay {
  z-index: 100;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .07), 0 2px 2px rgba(0, 0, 0, .07), 0 4px 4px rgba(0, 0, 0, .07), 0 8px 8px rgba(0, 0, 0, .07), 0 16px 16px rgba(0, 0, 0, .07), 0 32px 32px rgba(0, 0, 0, .07), 0 0 2px rgba(0, 0, 0, .2);
}

.lite-autocomplete__menu--inline {
  position: relative;
}

.lite-autocomplete__option {
  cursor: pointer;
  border-width: 1px 0;
  border-bottom-style: solid;
  border-bottom-color: #b1b4b6;
  display: block;
  position: relative;
}

.lite-autocomplete__option > * {
  pointer-events: none;
}

.lite-autocomplete__option:first-of-type {
  border-top-width: 0;
}

.lite-autocomplete__option:last-of-type {
  border-bottom-width: 0;
}

.lite-autocomplete__option--odd {
  background-color: #fafafa;
}

.lite-autocomplete__option--focused, .lite-autocomplete__option:hover {
  color: #fff;
  background-color: #1d70b8;
  border-color: #1d70b8;
  outline: none;
}

.lite-autocomplete__option--no-results {
  color: #646b6f;
  cursor: not-allowed;
  background-color: #fafafa;
}

.lite-autocomplete__hint, .lite-autocomplete__input, .lite-autocomplete__option {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .lite-autocomplete__hint, .lite-autocomplete__input, .lite-autocomplete__option {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-autocomplete__hint, .lite-autocomplete__input, .lite-autocomplete__option {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .lite-autocomplete__hint, .lite-autocomplete__input, .lite-autocomplete__option {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.lite-autocomplete__hint, .lite-autocomplete__option {
  padding: 5px;
}

@media (min-width: 641px) {
  .lite-autocomplete__hint, .lite-autocomplete__input, .lite-autocomplete__option {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: GDS Transport, arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
  }
}

@media print and (min-width: 641px) {
  .lite-autocomplete__hint, .lite-autocomplete__input, .lite-autocomplete__option {
    font-family: sans-serif;
  }
}

@media (min-width: 641px) and (min-width: 40.0625em) {
  .lite-autocomplete__hint, .lite-autocomplete__input, .lite-autocomplete__option {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print and (min-width: 641px) {
  .lite-autocomplete__hint, .lite-autocomplete__input, .lite-autocomplete__option {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.tokenfield-copy-helper {
  display: none;
}

.lite-back-link-button {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin-left: 0;
  padding: 0 0 0 14px;
  text-decoration: underline;
}

.lite-cancel-link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  border-bottom: 1px solid;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  line-height: 12px;
  text-decoration: none;
  position: relative;
  color: #d4351c !important;
}

@media (min-width: 40.0625em) {
  .lite-cancel-link {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .lite-cancel-link {
    font-family: sans-serif;
    font-size: 14pt;
    line-height: 1.2;
  }
}

.lite-cancel-link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.lite-cancel-link:link, .lite-cancel-link:visited {
  color: #0b0c0c;
}

@media print {
  .lite-cancel-link:link, .lite-cancel-link:visited {
    color: #000;
  }
}

.lite-cancel-link:active, .lite-cancel-link:focus {
  color: #0b0c0c;
}

@media print {
  .lite-cancel-link:active, .lite-cancel-link:focus {
    color: #000;
  }
}

.lite-cancel-link svg {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.lite-cancel-link svg * {
  fill: currentColor;
}

.lite-cancel-link:hover {
  color: #a72a16 !important;
}

.lite-cancel-link:active {
  color: #7a1e10 !important;
}

.lite-currency-input {
  position: relative;
}

.lite-currency-input:focus-within .lite-currency-input__symbol {
  border-width: 4px;
}

.lite-currency-input:focus-within .lite-currency--error {
  background-color: #0b0c0c;
  border-color: #0b0c0c;
}

.lite-currency-input__symbol {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-align: center;
  background-color: #b1b4b6;
  border: 2px solid #0b0c0c;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media print {
  .lite-currency-input__symbol {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .lite-currency-input__symbol {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .lite-currency-input__symbol {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.lite-currency--error {
  color: #fff;
  background-color: #d4351c;
  border-color: #d4351c;
  font-weight: bold;
}

.lite-currency-input input {
  padding-left: 46px;
}

.lite-currency-input__input--right-aligned {
  text-align: right;
}

.lite-email-panel {
  box-sizing: border-box;
  white-space: nowrap;
  max-width: 50ex;
  padding: 10px 15px;
  display: none;
  position: relative;
  overflow: hidden;
}

.lite-email-panel:after {
  content: "";
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
  width: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.lite-form-optional {
  color: #505a5f;
  padding-left: 5px;
}

div.canned-snippets__snippet {
  align-items: center;
  display: flex;
}

div.canned-snippets__snippet span {
  flex: 0 0 50%;
}

div.canned-snippets__snippet button {
  margin: 0;
  display: flex;
}

.app-activity__item {
  padding: 10px 0 15px 40px;
  position: relative;
}

.app-activity__item:before {
  content: "";
  background-color: #b1b4b6;
  width: 2px;
  position: absolute;
  top: 0;
  bottom: -10px;
  left: 9px;
}

.app-activity__item:after {
  content: "";
  background-color: #fff;
  border: 2px solid #b1b4b6;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  left: 0;
}

.app-activity__item:first-of-type:before {
  top: 15px;
}

.app-activity__item:last-of-type:before {
  height: 15px;
  bottom: auto;
}

.app-activity__item .highlight {
  color: #28a197;
}

.app-activity__item .user {
  font-weight: 600;
}

.app-activity__item .govuk-body {
  word-wrap: break-word;
  margin-bottom: 0;
  line-height: 1.5;
}

.app-activity__additional-text {
  background-color: #f3f2f1;
  border: 2px solid #eceae9;
  margin: 15px 0 20px;
  padding: 20px 20px 15px;
  line-height: 1.5;
  position: relative;
}

.app-activity__additional-text--exporter {
  background-color: rgba(29, 112, 184, .1);
  border: 2px solid rgba(29, 112, 184, .15);
}

.app-activity-preview__container {
  width: 804px;
  margin: -20px auto auto;
}

.lite-application-note {
  background-color: #f3f2f1;
  border: 2px solid #eceae9;
  margin: 15px 0 20px;
  padding: 20px;
  line-height: 1.5;
}

.lite-application-note__tag--internal {
  color: #28a197;
}

.lite-application-note__tag--exporter {
  color: #1d70b8;
}

.lite-application-note--internal {
  background-color: rgba(40, 161, 151, .1) !important;
  border: 2px solid rgba(40, 161, 151, .075) !important;
}

.app-documents__list {
  grid-gap: 30px;
  margin: 0;
  padding: 0;
  display: grid;
}

.app-documents__item {
  display: flex;
}

.app-documents__item-preview {
  margin-right: 15px;
  position: relative;
}

.app-documents__item-preview svg {
  border: 5px solid #f3f2f1;
}

.app-documents__item-preview svg * {
  fill: #b1b4b6;
}

.app-documents__item-preview span {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 4px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
}

@media print {
  .app-documents__item-preview span {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-documents__item-preview span {
    font-size: 1.1875rem;
    line-height: 1;
  }
}

@media print {
  .app-documents__item-preview span {
    font-size: 14pt;
    line-height: 1;
  }
}

.app-documents__item-details a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.11111;
  display: block;
}

@media print {
  .app-documents__item-details a {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-documents__item-details a {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .app-documents__item-details a {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.app-documents__item-details .govuk-hint, .app-documents__item-details .lite-file-upload__or-label, .app-documents__item-details .lite-filters__hint-text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin: 0 0 10px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .app-documents__item-details .govuk-hint, .app-documents__item-details .lite-file-upload__or-label, .app-documents__item-details .lite-filters__hint-text {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-documents__item-details .govuk-hint, .app-documents__item-details .lite-file-upload__or-label, .app-documents__item-details .lite-filters__hint-text {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .app-documents__item-details .govuk-hint, .app-documents__item-details .lite-file-upload__or-label, .app-documents__item-details .lite-filters__hint-text {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.app-documents__item-details .govuk-body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  margin: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
}

@media print {
  .app-documents__item-details .govuk-body {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-documents__item-details .govuk-body {
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media print {
  .app-documents__item-details .govuk-body {
    font-size: 14pt;
    line-height: 1.5;
  }
}

.certificate-action {
  text-align: left;
  width: 200px;
}

.app-ecju-query {
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.app-ecju-query__item {
  margin-bottom: 30px;
}

.app-ecju-query__item--right {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.app-ecju-query__item--new {
  border-left: 4px solid #1d70b8;
  padding-left: 30px;
}

.app-ecju-query__item--new .app-ecju-query__heading:before {
  content: "";
  background-color: #d4351c;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  margin-bottom: 2px;
  margin-right: 10px;
  display: inline-block;
}

.app-ecju-query__heading {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 500px;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
}

@media print {
  .app-ecju-query__heading {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-ecju-query__heading {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .app-ecju-query__heading {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.app-ecju-query__date {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  margin-top: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .app-ecju-query__date {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-ecju-query__date {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .app-ecju-query__date {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.app-ecju-query__text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f2f1;
  border: 2px solid #eeedec;
  max-width: 500px;
  padding: 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: inline-block;
}

@media print {
  .app-ecju-query__text {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-ecju-query__text {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .app-ecju-query__text {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.app-hub-notification-banner__heading {
  max-width: 100%;
}

#compliance-open-licence-app-tiles {
  display: none;
}

.app-ogl-warning {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background: #d4351c;
  width: 100%;
  margin-bottom: 30px;
  padding: 15px 15px 15px 70px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  display: block;
  position: relative;
  color: #fff !important;
}

@media print {
  .app-ogl-warning {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-ogl-warning {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .app-ogl-warning {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.app-ogl-warning:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "!";
  color: #d4351c;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11111;
  position: absolute;
  top: 15px;
  left: 15px;
  line-height: 40px !important;
}

@media print {
  .app-ogl-warning:after {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-ogl-warning:after {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .app-ogl-warning:after {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.app-ogl-warning .govuk-list {
  color: #fff;
  margin-top: 10px;
}

.app-ogl-warning .govuk-list:last-child, .app-ogl-warning .list:last-child {
  margin-bottom: 0;
}

@media (min-width: 48.0625em) {
  .app-pv-grading-inputs {
    grid-gap: 30px;
    margin-bottom: -30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}

.app-spreadsheet {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .app-spreadsheet {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-spreadsheet {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .app-spreadsheet {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (min-width: 40.0625em) {
  .app-spreadsheet {
    margin-bottom: 30px;
  }
}

.app-spreadsheet__head .app-spreadsheet__header {
  border-bottom-width: 0;
}

.app-spreadsheet__cell, .app-spreadsheet__header {
  text-align: left;
  vertical-align: center;
  border: 1px solid #b1b4b6;
  padding: 10px;
}

.app-spreadsheet__header {
  text-align: center;
  background-color: #f3f2f1;
  font-weight: 700;
}

.app-start-page__register-container {
  align-items: center;
  display: flex;
}

@media (max-width: 40.0525em) {
  .app-start-page__register-container {
    grid-template-rows: 1fr 1fr 1fr;
    display: grid;
  }

  .app-start-page__register-container p {
    text-align: center;
    margin-top: 15px;
    margin: 0 !important;
    line-height: 45px !important;
  }

  .app-start-page__register-container p a {
    margin-left: 5px;
  }
}

.app-start-page__great-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  background-color: #4b35c0;
  justify-content: center;
  align-items: center;
  height: 46px;
  padding-left: 26px;
  padding-right: 15px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  color: #fff !important;
}

@media print {
  .app-start-page__great-button {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-start-page__great-button {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .app-start-page__great-button {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.app-start-page__great-button svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.app-start-page__great-button span {
  margin-left: 5px;
  font-weight: bold;
}

.app-start-page__great-button:after {
  content: "";
  opacity: .5;
  background: #0b0c0c;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.app-start-page__great-button:hover {
  background-color: #3b2a98;
}

.app-start-page__great-button:active {
  background-color: #2c1f70;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.app-start-page__great-button:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
  color: #0b0c0c !important;
}

.app-start-page__great-button:focus:after {
  opacity: 0;
}

.app-step-list {
  counter-reset: circle-step-list-counter;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

.app-step-list > li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-bottom: 20px;
  padding-top: 8px;
  padding-left: 55px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  position: relative;
}

@media print {
  .app-step-list > li {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-step-list > li {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .app-step-list > li {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.app-step-list > li > p {
  margin-top: 0;
  font-weight: bold;
}

.app-step-list > li:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: counter(circle-step-list-counter);
  counter-increment: circle-step-list-counter;
  color: #fff;
  text-align: center;
  background: #0b0c0c;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 40px !important;
}

@media print {
  .app-step-list > li:before {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-step-list > li:before {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .app-step-list > li:before {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.app-submit-bar {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.app-submit-bar * {
  margin: 0;
}

.app-tiles {
  grid-gap: 15px 15px;
  margin-bottom: 15px;
  display: grid;
}

@media (min-width: 20em) {
  .app-tiles {
    grid-template-columns: 1fr !important;
  }
}

@media (min-width: 40.0625em) {
  .app-tiles {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media (min-width: 48.0625em) {
  .app-tiles {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}

.app-tile {
  box-sizing: border-box;
  background-color: #f3f2f1;
  border-top: 5px solid #1d70b8;
  padding: 15px 15px 10px;
}

@media (min-width: 40.0625em) {
  .app-tile {
    padding: 25px;
  }
}

.app-tile > * {
  margin-bottom: 15px !important;
}

@media (max-width: 40.0525em) {
  .app-tile > * {
    margin-bottom: 5px !important;
  }
}

.app-tile .lite-notification-bubble {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  width: auto;
  min-width: 22px;
  margin-left: 5px;
  padding: 0 5px;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.25;
  display: inline-block;
  color: #fff !important;
  text-decoration: none !important;
}

@media print {
  .app-tile .lite-notification-bubble {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-tile .lite-notification-bubble {
    font-size: .875rem;
    line-height: 1.42857;
  }
}

@media print {
  .app-tile .lite-notification-bubble {
    font-size: 12pt;
    line-height: 1.2;
  }
}

.app-tile__disabled {
  opacity: .5;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.app-tile__disabled:focus, .app-tile__disabled:hover {
  border-bottom: none;
}

.app-tile__disabled .app-tile__heading, .app-tile__disabled .govuk-link--no-visited-state, .app-tile__disabled .app-tile .app-tile__link, .app-tile .app-tile__disabled .app-tile__link, .app-tile__disabled .lite-link--definition, .app-tile__disabled .lite-expand__base-controls .lite-expand__base-control, .lite-expand__base-controls .app-tile__disabled .lite-expand__base-control, .app-tile__disabled .lite-modal-back-link, .app-tile__disabled .lite-pagination__navigation-link, .app-tile__disabled .lite-pagination__link {
  color: #1d70b8;
}

.app-tile .app-tile__heading {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  text-decoration: underline;
  display: inline-block;
}

@media print {
  .app-tile .app-tile__heading {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-tile .app-tile__heading {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .app-tile .app-tile__heading {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.app-tile .app-tile__heading p {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  margin: 0;
  display: inline-block;
}

.app-tile .app-tile__body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: GDS Transport, arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
}

@media print {
  .app-tile .app-tile__body {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .app-tile .app-tile__body {
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media print {
  .app-tile .app-tile__body {
    font-size: 14pt;
    line-height: 1.5;
  }
}

.govuk-header__link--service-name {
  margin-bottom: 0;
}

.app-header__link--sign-out {
  text-align: right;
  white-space: nowrap;
  margin-top: 2px;
  font-weight: bold;
}

.govuk-header__content {
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  display: flex;
}

@media (max-width: 48.0525em) {
  .govuk-header__content {
    margin-bottom: 10px;
  }
}

.app-appeal-details-files-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.app-appeal-details-files-list__item {
  border-bottom: 1px solid #b1b4b6;
  padding: 10px 0;
}

#star_rating svg {
  fill: currentColor;
  stroke: currentColor;
  width: 2em;
  height: 2em;
}

#star_rating label, #star_rating output {
  float: left;
  color: #00703c;
  cursor: pointer;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  font-size: 2em;
  display: block;
}

#star_rating output {
  padding: 0 1em;
  font-size: 1.5em;
}

#star_rating input:checked ~ label {
  color: #999;
}

#star_rating input:checked + label {
  color: #00703c;
  border-bottom-color: #00703c;
}

#star_rating input:focus + label {
  border-bottom-style: dotted;
}

#star_rating input:hover ~ label, #star_rating input:focus ~ label, #star_rating input[id="star0"] + label {
  color: #999;
}

#star_rating input:hover + label, #star_rating input:focus + label {
  color: #00703c;
}

#star_rating input[id="star0"]:checked + label {
  color: #ff2d21;
}

#star_rating [type="submit"] {
  float: none;
}

.govuk-accordion__section-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GDS Transport, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: underline;
}

@media print {
  .govuk-accordion__section-button {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .govuk-accordion__section-button {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-accordion__section-button {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-accordion__section-content .govuk-table__body .govuk-table__row:last-of-type > * {
  border-bottom: none;
}

.lite-autocomplete__hint {
  margin-left: 0 !important;
}

.lite-autocomplete__menu {
  max-width: initial;
  width: calc(54ex - 3px);
}

.hidden .js-enabled {
  display: none;
}

.govuk-summary-list__key--highlight {
  border-left: 5px solid #f47738;
  padding-left: 15px;
}

.lite-warning-summary {
  border: 5px solid #f47738;
  margin-bottom: 40px;
  padding: 20px;
}

@media (min-width: 40.0625em) {
  .lite-warning-summary {
    margin-bottom: 60px;
  }
}

.lite-error-summary__list, .lite-error-summary__list li:last-of-type {
  margin: 0;
}

.lite-error-summary__list a {
  color: #f47738;
  font-weight: bold;
}

.app-check-your-answers-separator {
  background: none;
  border: none;
  height: 1px;
  margin: 50px 0;
  display: block;
}

.app-icon-label {
  display: inline-block;
}

.app-icon-label p {
  margin: 0;
  padding: 0;
}

.app-verified__symbol {
  margin-top: 1px;
  margin-right: 5px;
  display: inline-block;
}

.app-verified__symbol svg {
  width: 16px;
  height: 16px;
}

.app-verified__symbol * {
  color: inherit;
  fill: currentColor;
}

.app-info-bar--verified svg {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.app-info-bar--verified svg * {
  fill: #fff;
}

.app-info-bar--verified:before {
  display: none;
}

.strike {
  text-decoration: line-through;
}

/*# sourceMappingURL=main.css.map */
