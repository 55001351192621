$lite-drop-shadow: 0 1px 1px rgba(0,0,0,0.07),
				   0 2px 2px rgba(0,0,0,0.07),
				   0 4px 4px rgba(0,0,0,0.07),
				   0 8px 8px rgba(0,0,0,0.07),
				   0 16px 16px rgba(0,0,0,0.07),
				   0 32px 32px rgba(0,0,0,0.07),
				   0 0 2px rgba(0,0,0,0.2);
$lite-border: 1px solid $govuk-border-colour;

@import "core/all";
@import "overrides/all";
@import "components/all";
@import "colours";
