.app-appeal-details-files-list {
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        border-bottom: 1px solid govuk-colour("mid-grey");
        padding: govuk-spacing(2) 0;
    }
}
