$item-size: govuk-spacing(7);

.lite-pagination {
    &__container {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
    }

    &__navigation-link {
        @extend .govuk-link;
        @extend .govuk-link--no-visited-state;
        align-items: center;
        display: inline-flex;
        justify-content: center;
        line-height: $item-size !important;
        margin: 0;
        padding: 0;
        position: relative;
        text-decoration: none;

        & > *:first-child {
            margin-right: govuk-spacing(2);
        }

        svg {
            color: inherit;
            max-height: 20px;
            max-width: 20px;
            min-height: 20px;
            min-width: 20px;

            * {
                color: inherit;
                stroke: currentColor !important;
            }
        }

        &:hover {
            &::after {
                background: currentColor;
                bottom: 0;
                content: "";
                height: 2px;
                left: 0;
                position: absolute;
                right: 0;
            }
        }

        &--disabled {
            color: $govuk-secondary-text-colour !important;
            pointer-events: none;
        }
    }

    &__list {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style-type: none;
        margin: 0;
        padding: 0;

        @include govuk-media-query($until: tablet) {
            display: none;
        }
    }

    &__list-item {
        @include govuk-font($size: 19);
        display: flex;
        justify-content: center;
        line-height: $item-size !important;
        position: relative;
        text-align: center;
        width: $item-size;

        &--selected {
            font-weight: bold;

            &::after {
                background: $govuk-link-colour;
                bottom: 0;
                content: "";
                height: 2px;
                left: govuk-spacing(1);
                position: absolute;
                right: govuk-spacing(1);
            }
        }

        .lite-pagination__link {
            width: 100%;
        }
    }

    &__link {
        @extend .govuk-link;
        @extend .govuk-link--no-visited-state;
        @include govuk-font($size: 19);
        align-items: center;
        background-color: inherit;
        border: 0;
        color: $govuk-link-colour;
        cursor: pointer;
        display: flex;
        justify-content: center;
        line-height: $item-size !important;
        position: relative;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
            &::after {
                background: currentColor;
                bottom: 0;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                height: 2px;
            }
        }
    }

    &__list-ellipsis {
        @include govuk-font($size: 19);
        align-items: center;
        color: $govuk-secondary-text-colour;
        display: flex;
        justify-content: center;
        letter-spacing: 3px;
        line-height: $item-size !important;
        margin: 0 govuk-spacing(2);
        position: relative;
        text-align: center;
        width: $item-size;
    }
}
