.lite-filters__hint-text {
	@extend .govuk-hint;
	position: relative;
	float: right;
	margin-top: 0px;

	@include govuk-media-query($until: desktop) {
		float: none;
		margin-bottom: govuk-spacing(6);
	}

	&--warning {
		@include govuk-font($size: 19, $weight: bold);
		color: $govuk-text-colour;

		@include govuk-media-query($until: desktop) {
			margin-left: 40px;
		}

		&::after {
			@include govuk-font($size: 19, $weight: bold);
			content: "!";
			position: absolute;
			top: -3px;
			left: -40px;
			width: 30px;
			line-height: 30px!important;
			text-align: center;
			background: $govuk-text-colour;
			color: $govuk-body-background-colour;
			border-radius: 100%;
		}
	}
}

.lite-filter-bar, .lite-search-bar {
	@include govuk-responsive-margin(6, "bottom");
	@include govuk-font($size: 19);
	position: relative;
	padding: govuk-spacing(4) 0;
	box-sizing: border-box;
	z-index: 0;

	@include govuk-media-query($from: tablet) {
		margin-top: - govuk-spacing(3);
	}

	&__buttons {
		display: flex;
		margin: govuk-spacing(3) 0;

		& > * {
			margin: 0;
		}

		& > *:first-child {
			margin-right: govuk-spacing(3);
		}
	}

	&__label {
		display: block;
		margin: 0;
		padding: 0;
		margin-bottom: govuk-spacing(2);
	}

	&__components {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
		grid-gap: govuk-spacing(3);
		margin-bottom: govuk-spacing(3);
		margin-top: govuk-spacing(3);

		.govuk-checkboxes__label {
			&::before {
				background: govuk-colour("white");
			}
		}

		& > div {
			display: flex;
			flex-direction: column;
		}
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		bottom: 0;
		width: 100vw;
		transform: translateX(-50vw);
		background-color: govuk-colour("light-grey");
		border-width: 2px 0;
		border-style: solid;
		border-color: darken(govuk-colour("light-grey"), 2%);
		z-index: -1;
	}

	.lite-autocomplete__input {
		max-width: 100%!important;
	}

	.govuk-select {
		width: 100%;
		display: block;
		margin-right: 0;
		max-width: 100%!important;
	}

	input {
		background-color: govuk-colour("white");
	}

	.govuk-date-input {
		width: 100%;
	}

	.govuk-form-group {
		margin-bottom: 0;
    }

	.govuk-details {
		margin-bottom: govuk-spacing(2);
	}

	.govuk-date-input {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: govuk-spacing(3);

		.govuk-date-input__item {
			margin-right: 0;
		}

		.govuk-input--width-2, .govuk-input--width-3 {
			max-width: 100%;
		}
	}

	.tokenfield {
		min-height: 40px;
		padding: 2px 5px 0 5px;
	}

	.tokenfield-set-item {
		margin-bottom: 2px;
	}

	.govuk-input--width-20 {
		max-width: 100%;
	}

	.govuk-grid-column-one-quarter:not(:first-child):not(:last-child) {
		@include govuk-media-query($until: tablet) {
			@include govuk-responsive-margin(6, "top");
		}
	}
}

.lite-filter-toggle-link, .lite-toggle-link  {
	@include govuk-font($size: 19);
	@include govuk-responsive-margin(6, "bottom");
	display: inline-block;
	text-decoration: none;
	border-bottom: 1px solid currentColor;
	padding-bottom: govuk-spacing(1);

	svg {
		color: inherit;
		max-width: 13px;
		max-height: 13px;
		margin-right: 3px;

		* {
			fill: currentColor;
		}
	}

	&:hover {
		border-color: currentColor;
	}
}

.lite-checkbox-filter-link {
	position: relative;
	display: inline-block;
	margin-bottom: govuk-spacing(1);
	text-decoration: none;
	border-bottom: 1px solid transparent;

	&:hover {
		border-bottom: 1px solid currentColor;
	}
}
