.govuk-form-group--error {
	.lite-autocomplete__input {
		border-color: $govuk-error-colour;

		&:focus {
			border-color: $govuk-input-border-colour;
			box-shadow: none;
		}
	}
}
