.lite-cancel-link {
	@include govuk-typography-responsive($size: 16);
	@include govuk-link-common;
	@include govuk-link-style-text;

	position: relative;
	margin-top: govuk-spacing(3);
	margin-bottom: govuk-spacing(3);
	text-decoration: none;
	float: right;
	color: govuk-colour("red")!important;
	border-bottom: 1px solid currentColor;
	line-height: 12px;

	svg {
		width: 12px;
		height: 12px;
		margin-right: 2px;
		* {
			fill: currentColor;
		}
	}

	&:hover {
		color: darken(govuk-colour("red"), 10%)!important;
	}

	&:active {
		color: darken(govuk-colour("red"), 20%)!important;
	}
}
