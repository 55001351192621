.app-submit-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: govuk-spacing(2);

	* {
		margin: 0;
	}
}
