// stylelint-disable declaration-no-important
@include govuk-exports("govuk/overrides/text-align") {
  .govuk-\!-text-align-left {
    text-align: left !important;
  }

  .govuk-\!-text-align-centre {
    text-align: center !important;
  }

  .govuk-\!-text-align-right {
    text-align: right !important;
  }
}

/*# sourceMappingURL=_text-align.scss.map */