.lite-autocomplete {
  &__hint {
    margin-left: 0!important;
  }

  &__menu {
    max-width: initial;
    width: calc(54ex - 3px);
  }
}
