.lite-mobile-tag {
	@extend .govuk-tag;
	color: $govuk-text-colour;
	background-color: $govuk-body-background-colour;

	@include govuk-media-query($from: tablet) {
		@include govuk-font($size: 19);
		background-color: transparent;
		text-transform: none;
		margin: 0;
		padding: 0;
		letter-spacing: normal;
	}
}

.lite-tag {
	@include govuk-font($size: 16, $weight: bold);
	background-color: govuk-colour("mid-grey");
	color: govuk-colour("white");
	box-sizing: border-box;
	padding: govuk-spacing(2) govuk-spacing(2) 7px;
	text-transform: uppercase;
	letter-spacing: 1px;
	right: 0;
	width: 150px;
	line-height: 35px;
	margin: 0;
	margin-bottom: govuk-spacing(6);
	text-align: center;
	border: 2px solid transparent;

	&--small {
		@include govuk-font($size: 14, $weight: bold);
		width: 130px;
		line-height: 28px;
		margin-bottom: govuk-spacing(3);
	}

	&--hollow {
		color: govuk-colour("black");
		background-color: govuk-colour("white");
		border: 2px solid govuk-colour("mid-grey");
	}

	&--green {
		background-color: govuk-colour("green");
	}

	&--orange {
		background-color: govuk-colour("orange");
	}

	&--red {
		background-color: govuk-colour("red");
	}

	&--blue {
		background-color: govuk-colour("blue");
	}
}
