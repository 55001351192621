.tokenfield {
	position: relative;
	display: flex!important;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	min-height: 45px;
	box-sizing: border-box;
	max-width: 56ex + 3ex;
	padding: govuk-spacing(1) govuk-spacing(1) 0 govuk-spacing(1);
	color: $govuk-secondary-text-colour;
	background-color: govuk-colour("white");
	border: 2px solid $govuk-input-border-colour;

	&:focus-within {
		outline: 3px solid $govuk-focus-colour;
		box-shadow: inset 0 0 0 2px $govuk-input-border-colour;
	}
}

.tokenfield.tokenfield-mode-tokens .focused {
	background-color: $govuk-focus-colour;
	box-shadow: 0 1px 0 $govuk-focus-text-colour;
	border-color: $govuk-focus-text-colour;
}

.tokenfield.tokenfield-mode-tokens .selected {
	// This is the selected list item
	background: govuk-colour("light-grey");
}

.tokenfield .tokenfield-set > ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.tokenfield-container {
	.tokenfield .tokenfield-set > ul > li {
		@include govuk-font($size: 19, $line-height: 1);
		position: relative;
		display: inline-block;
		padding: govuk-spacing(1) govuk-spacing(6) govuk-spacing(1) govuk-spacing(2);
		cursor: pointer;
		background-color: govuk-colour("light-grey");
		color: $govuk-text-colour;
		border-bottom: 2px solid govuk-colour("mid-grey");
		margin-bottom: govuk-spacing(1);
		margin-right: govuk-spacing(1);
	}
}

.tokenfield .tokenfield-set > ul > li .item-remove {
	@include govuk-font($size: 24);
	position: absolute;
	top: 0;
	right: govuk-spacing(1);
	bottom: 0;
	width: govuk-spacing(4);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: $govuk-text-colour;
	text-decoration: none;

	&:hover {
		color: govuk-colour("red");
	}
}

.tokenfield .tokenfield-input {
	@include govuk-font($size: 19);
	color: $govuk-text-colour;
	outline: 0;
	border: 0;
	padding: 0;
	margin: 0;
	margin-left: govuk-spacing(1);
	margin-bottom: govuk-spacing(1);
}

.tokenfield .tokenfield-suggest {
	position: absolute;
	left: -2px;
	top: 100%;
	width: 100%;
	z-index: 10;
	overflow: auto;
	background-color: govuk-colour("white");
	border: 2px solid $govuk-input-border-colour;
	box-shadow: $lite-drop-shadow;
	box-sizing: content-box;
}

.tokenfield .tokenfield-suggest > ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.tokenfield .tokenfield-suggest > ul > li {
	@include govuk-font($size: 19, $line-height: 1);
	padding: govuk-spacing(2);
	cursor: pointer;
	color: $govuk-text-colour;

	&:active {
		background-color: $govuk-link-colour;
		color: govuk-colour("white");
	}
}
