.autocomplete__wrapper {
  position: relative;
}

.autocomplete__hint,
.autocomplete__input {
  -webkit-appearance: none;
  border: 2px solid #0b0c0c;
  border-radius: 0; /* Safari 10 on iOS adds implicit border rounding. */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-bottom: 0; /* BUG: Safari 10 on macOS seems to add an implicit margin. */
  width: 100%;
}

.autocomplete__input {
  background-color: transparent;
  position: relative;
}

.autocomplete__hint {
  color: #b1b4b6;
  position: absolute;
}

.autocomplete__input--default {
  padding: 5px;
}
.autocomplete__input--focused {
  outline: 3px solid #fd0;
  outline-offset: 0;
  box-shadow: inset 0 0 0 2px;
}

.autocomplete__input--show-all-values {
  padding: 5px 34px 5px 5px; /* Space for arrow. Other padding should match .autocomplete__input--default. */
  cursor: pointer;
}

.autocomplete__dropdown-arrow-down{
  z-index: -1;
  display: inline-block;
  position: absolute;
  right: 8px;
  width: 24px;
  height: 24px;
  top: 10px;
}

.autocomplete__menu {
  background-color: #fff;
  border: 2px solid #0B0C0C;
  border-top: 0;
  color: #0B0C0C;
  margin: 0;
  max-height: 342px;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  width: calc(100% - 4px);
}

.autocomplete__menu--visible {
  display: block;
}

.autocomplete__menu--hidden {
  display: none;
}

.autocomplete__menu--overlay {
  box-shadow: rgba(0, 0, 0, 0.256863) 0px 2px 6px;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 100;
}

.autocomplete__menu--inline {
  position: relative;
}

.autocomplete__option {
  border-bottom: solid #b1b4b6;
  border-width: 1px 0;
  cursor: pointer;
  display: block;
  position: relative;
}

.autocomplete__option > * {
  pointer-events: none;
}

.autocomplete__option:first-of-type {
  border-top-width: 0;
}

.autocomplete__option:last-of-type {
  border-bottom-width: 0;
}

.autocomplete__option--odd {
  background-color: #FAFAFA;
}

.autocomplete__option--focused,
.autocomplete__option:hover {
  background-color: #1d70b8;
  border-color: #1d70b8;
  color: white;
  outline: none;
}

.autocomplete__option--no-results {
  background-color: #FAFAFA;
  color: #646b6f;
  cursor: not-allowed;
}

.autocomplete__hint,
.autocomplete__input,
.autocomplete__option {
  font-size: 16px;
  line-height: 1.25;
}

.autocomplete__hint,
.autocomplete__option {
  padding: 5px;
}

@media (min-width: 641px) {
  .autocomplete__hint,
  .autocomplete__input,
  .autocomplete__option {
    font-size: 19px;
    line-height: 1.31579;
  }
}
